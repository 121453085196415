import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Context } from '../shared/services/context.service';
import { Contact } from '../model/Contact.model';
import { ContactMail } from '../model/ContactMail.model';
import { contactlibelle } from '../model/contactlibelle.model';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http: HttpClient) { }

    getContactByIdPersonne(idPersonne) {
        return this.http.get<Contact[]>(Context.apiUrl + 'get_contact_of/' + idPersonne);
    }

    getAllContactMail() {
        return this.http.get<ContactMail[]>(Context.apiUrl + 'ContactMail/');
    }

    sendNewsLetter(subject, body, to) {
        return this.http.put<any>(Context.apiUrl + 'newsLetters/' + '?subject=' + subject + '&body=' + body, to);
    }

    getContact(idPatient) {
        return this.http.get<[]>(Context.apiUrl + 'Contact/' + idPatient);
    }

    updateContact(contact) {
         return this.http.put(Context.apiUrl + 'updateContact', contact);
    }
    getContactLib(){
        return this.http.get<[contactlibelle]>(Context.apiUrl + 'ContactLibs');

    }
}
