import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
@Injectable({
  providedIn: 'root'
})
export class CustomToastService {
    constructor(private toastController: ToastController,) { }
    async presentToast(msg,color,duration) {
        const toast = await this.toastController.create({
          message: msg,
          duration: duration,
          color: color,
          cssClass: 'toast-custom'
        });
        toast.present();
      }
}
