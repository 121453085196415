import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { StaffStatsService } from 'src/app/service/staff-stats.service';
import { HoraireTravailService } from 'src/app/service/horaire-travail.service';
import {IonicSelectableComponent} from 'ionic-selectable';
import internal from 'assert';
import { FauteuilDefaultService } from 'src/app/service/fauteilDefault.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-affect-fauteuil-modal',
  templateUrl: './affect-fauteuil-modal.page.html',
  styleUrls: ['./affect-fauteuil-modal.page.scss'],
})
export class AffectFauteuilModalPage implements OnInit {

  modalTitle: string;
  modelId: number;
  SelectedDate: any;
  Date:any;
  MapOfFauteuil :any;
  public today: Date = new Date();
  public currentYear: number = this.today.getFullYear();
  public currentMonth: number = this.today.getMonth()+1;
  public currentDay: number = this.today.getDate();
  public dateValue: Object = new Date(new Date().setDate(14));
  lengthUsers: any;
  users: any;
  userstoday:any;
  userFiltred:any;
  affectation = {
    id_USER: '',
    id_FAUTEUIL: '',
    affecte_FROM: '',
    affecte_TO: '',
    remarques: ''
  }
  horaireaffectation:any;
  dayNum :any;
  weekNum: any;
  year:any;
  username: any;
  horrairetravail: any;

  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    private staffStatsService: StaffStatsService,
    private HoraireTravailService: HoraireTravailService,
    private fauteuil: FauteuilDefaultService,
    private datePipe: DatePipe
  ) {
    
   }
   @ViewChild('users', {static: false}) Dentistetraitanth: IonicSelectableComponent;
  ngOnInit() {
    this.getAllUsers();
    console.table(this.navParams);
    this.SelectedDate = this.navParams.data.SelectedDate;
    this.MapOfFauteuil = this.navParams.data.MapOfFauteuil;

    // this.affectation.affecte_FROM =this.SelectedDate;
    // console.log("this.SelectedDate",this.SelectedDate);
    console.log(this.SelectedDate.getDay());
    console.log(this.SelectedDate.getMonth()+1);
  
    console.log(this.currentDay);

    

    let year  = this.SelectedDate.getFullYear();
    let month  = this.SelectedDate.getMonth()+1;
    let day =  this.SelectedDate.getDate();
    this.weekNum = this.week(year,month,day);
    // console.log("this.SelectedDate",this.SelectedDate);
    this.year =this.currentYear;
    // var nameDay  = this.SelectedDate.toString().substr(0,3);
    this.dayNum= daySchedule[this.SelectedDate.toString().substr(0,3)];
    console.log("dayNum",this.dayNum);
    console.log("Year",this.year);
    console.log("weekNum",this.weekNum);
    this.getUtilisateurs(this.year,this.weekNum,this.dayNum);
    // setTimeout(()=>{
      
    // }, 1000)
    
  }



  getUtilisateurs(year,week,day) {
     this.HoraireTravailService.getUsersByListeHorraireReel(year,week,day).subscribe(async res => {
      console.log(year,week,day);
      console.log(res);
      
      // this.userstoday = res;
      this.userstoday = res.filter(p => p.idUtilisateur);
      // this.lengthUsers = this.userstoday.length;
      console.log(this.userstoday);
      // console.log(this.userstoday.length);
      this.filterUser();
    }, error => console.error(error));
    
  }




  week(year,month,day) {
    console.log("year,month,day",year,month,day);
    
    function serial(days) { return 86400000*days; }
    function dateserial(year,month,day) { return (new Date(year,month-1,day).valueOf()); }
    function weekday(date) { return (new Date(date)).getDay()+1; }
    function yearserial(date) { return (new Date(date)).getFullYear(); }
    var date = year instanceof Date ? year.valueOf() : typeof year === "string" ? new Date(year).valueOf() : dateserial(year,month,day), 
        date2 = dateserial(yearserial(date - serial(weekday(date-serial(1))) + serial(4)),1,3);
    return ~~((date - date2 + serial(weekday(date2) + 5))/ serial(7));
}

  async SubmiAffectFauteuil(form) {
    // console.log("form",form);
    // console.log("this.horaireaffectation.startTime",this.horaireaffectation.startTime);

    this.affectation.affecte_FROM=this.horaireaffectation.startTime;
    this.affectation.affecte_TO=this.horaireaffectation.endTime;

    // console.log("affectation",this.affectation);
    await this.fauteuil.AddAffectations(this.affectation).toPromise().then(async res => {
      this.closeModal();
    });
    // console.log("horaireaffectation",this.horaireaffectation);
  }



  private async getAllUsers() {
    await this.staffStatsService.getUtilisateurs().subscribe(async res => {
      this.users = res.filter(p => p.nom && p.prenom);
      this.lengthUsers = this.users.length;
      console.log(this.users);
    }, error => console.error(error));
  }

  onSelectChange(selectedValue: any) {
    this.horrairetravail = [ ];
    this.horaireaffectation=[];

    console.log("date",this.Date);
      if ( this.currentMonth <= 9 ) {
        var month = "0"+this.currentMonth;
      }

    var date =this.datePipe.transform(this.SelectedDate,"yyyy-MM-dd");
    // console.log(date)
    for (let index = 0; index < this.userstoday.length; index++) {
      // console.log("this.userFiltred[index].idUtilisateur",this.userstoday[index]);
      // console.log('Selected', selectedValue.detail.value);
      if (this.userstoday[index].idUtilisateur === selectedValue.detail.value) {
        var starthoraire =this.userstoday[index].startTime.substr(0,2)
        // console.log('starthoraire',starthoraire)
        if (starthoraire < 14 ) {
          var horaire = {"nom": "Matin",
                          "value": {"startTime" : date+" "+ this.userstoday[index].startTime,
                                    "endTime" : date+" "+ this.userstoday[index].endTime,
                                    }
                           };
        this.horrairetravail.push(horaire)
        }
        else{
          var horaire = {"nom": "Apres Midi",
                          "value": {"startTime" : date+" "+ this.userstoday[index].startTime,
                                    "endTime" : date+" "+ this.userstoday[index].endTime,
                                    }
                           };
        this.horrairetravail.push(horaire) 
        }
        // if (this.userFiltred[index].startTime == selectedValue.detail.value) {
        //   this.horrairetravail.push(this.userFiltred[index]);
        // }
      }
    }
    if (this.horrairetravail.length == 2) {
      var horaire = {"nom": "Tout",
      "value": {"startTime" : "" +this.horrairetravail[0].value.startTime,
                "endTime" : "" +this.horrairetravail[1].value.endTime,
                }
       };
        this.horrairetravail.push(horaire) 
    }
    console.log("this.horrairetravail",this.horrairetravail)
    

  }

  filterUser() {
    // console.log("this.userstoday",this.userstoday);
    // console.log("length",this.userstoday.length);
    // console.log("users",this.users);
    
    this.userFiltred = [ ];
    let per_prenom: {};
    let per_nom: {};


    for (let index = 0; index < this.userstoday.length; index++) {
      this.staffStatsService.getUtilisateurById(this.userstoday[index].idUtilisateur).subscribe(async (res:any) => {
        console.log(res);
        var username = { "nom": res.per_prenom + " " + res.per_nom };
        console.log(username);
        Object.assign(this.userstoday[index], username)
        this.userFiltred.push(res);
        
      }, error => console.error(error));
  }
  // this.userFiltred = this.userFiltred.filter(p => p.per_prenom );
 
  const uniqueIds = [];

this.username = this.userstoday.filter(element => {
  const isDuplicate = uniqueIds.includes(element.idUtilisateur);

  if (!isDuplicate) {
    uniqueIds.push(element.idUtilisateur);

    return true;
  }
});
console.log("uniqueIds",this.username );


  // for (let index = 0; index < this.userstoday.length; index++) {
  //   var user = {
  //     "id" : this.userstoday[index].idUtilisateur,
  //     "nom" : this.userstoday[index].nom,
  //     "annee" : this.userstoday[index].annee,
  //     "weekNum" : this.userstoday[index].weekNum,
  //     "dayNum" : this.userstoday[index].dayNum,


  //   }
    
  // }
 
 
  // console.log("this.userFiltred",this.userstoday);
}



  async closeModal() {
    const onClosedData: string = "Wrapped Up!";
    await this.modalController.dismiss(onClosedData);
  }
}
export enum daySchedule{
  Mon = 1 ,
  Tue = 2 , 
  Wed =3 , 
  Thu = 4 , 
  Fri = 5 , 
  Sat = 6 , 
  Sun = 0
}
