import { Component, OnInit, ViewChild } from '@angular/core';
import { NavParams, ModalController, IonSlides } from '@ionic/angular';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';


@Component({
  selector: 'app-Image-ViewerNew',
  templateUrl: './Image-ViewerNew.component.html',
  styleUrls: ['./Image-ViewerNew.component.scss'],
})
export class ImageViewerNewComponent implements OnInit {
  fromUrl ;
  picture ;
  path ;
  sliderConfig = {
    // spaceBetween : 10,
    // centeredSlides: true,
    // slidesPerView: 1.6
  }
  constructor(
    private navParam : NavParams,
    private modalCtrl : ModalController,
    private camera :Camera
  ) { 
    this.picture = navParam.data.picture;
    this.path = navParam.data.path;
  }
  ngOnInit() {}
  closeModal() {
    this.modalCtrl.dismiss(
      {
        newPicture: this.picture,
      }
    );
  }
  valider(){
    this.modalCtrl.dismiss(
      {
        newPicture: this.picture,
      }
    );
  }
  async reprondre()
  {
    
    const options: CameraOptions = {
      quality: 100,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      sourceType: this.camera.PictureSourceType.CAMERA,
     // saveToPhotoAlbum : true
    }
    this.camera.getPicture(options).then((imageData) => {
     let base64Image = 'data:image/jpeg;base64,' + imageData;
     this.picture = base64Image;
     this.valider();
    }, (err) => {
     // Handle error
    });
  }
  isNew : boolean = false;
}
