import { Injectable } from '@angular/core';
import { Context } from 'src/app/shared/services/context.service';
import { ObjetService } from './objet.service';

import * as sha1 from 'sha1';
import { objet } from '../model/objet.model';
@Injectable({
  providedIn: 'root'
})
export class ImageServiceService {

  constructor(private context: Context,
    private objetService: ObjetService,
  ) { }


  getUserSelected(idPersonne) {
    return Context.root_folder + Context.folder + idPersonne;

  }
  imgUrl: string;
  base64textString;
  // async imageToSaveOrUpdate(image64: string, imagesVisionate, idUser, typeTransaction): Promise<any> {

  //   return new Promise((resolve, rejects) => {

  //     this.imgUrl = image64.substring(image64.indexOf(';') + 8);
  //     this.base64textString = this.imgUrl;
  //     var raw = window.atob(this.imgUrl)
  //     var n = raw.length;
  //     var a = new Uint8Array(new ArrayBuffer(n));
  //     for (var i = 0; i < n; i++) {

  //       a[i] = raw.charCodeAt(i);

  //     }
  //     const contentType = 'image/jpg';
  //     const blob = new Blob([a], { type: contentType });
  //     const destName = sha1(this.base64textString);
  //     var form = new FormData();
  //     form.append("file", blob);
  //     if (typeTransaction == "save") {
  //       this.saveImage(destName, idUser, form).then(res => {
  //         return resolve(res)
  //       }).catch(err => {

  //       })
  //     } else if (typeTransaction == "update") {
  //       this.updateImage(this.imgUrl, destName, idUser, form, imagesVisionate).then(res => {
  //         return resolve(res)
  //       }).catch(err => {
  //       })
        
  //     }

  //   })



  // }

  async imageToSaveOrUpdate(image64: string, imagesVisionate, idUser, typeTransaction): Promise<any> {
    try {
      this.imgUrl = image64.substring(image64.indexOf(';') + 8);
      this.base64textString = this.imgUrl;
      const raw = window.atob(this.imgUrl);
      const n = raw.length;
      const a = new Uint8Array(new ArrayBuffer(n));
      
      for (let i = 0; i < n; i++) {
        a[i] = raw.charCodeAt(i);
      }
      
      const contentType = 'image/jpg';
      const blob = new Blob([a], { type: contentType });
      const destName = sha1(this.base64textString);
      const form = new FormData();
      form.append("file", blob);
  
      if (typeTransaction === "save") {
        return this.saveImage(destName, idUser, form);
      } else if (typeTransaction === "update") {
        return this.updateImage(this.imgUrl, destName, idUser, form, imagesVisionate);
      } else {
        throw new Error("Invalid typeTransaction");
      }
    } catch (error) {
      throw error; // Re-throw any errors for handling at a higher level
    }
  }
  

  async saveImage(destName, idUser, form) {
    return new Promise(async resolve => {
      await this.objetService.saveImage(destName + ".JPG", Context.folder + idUser, form).toPromise()
        .then(async res => {

          this.insertObjet(destName + ".JPG", "JPG", +idUser, this.base64textString, 0, 0, 0, 0, null, 0
            , destName + ".JPG", "", Context.folder + idUser, "", "", "", 0, idUser)
            .toPromise().then(res => {
              resolve(res);
            })
        })
        .catch(err => {
        })
    })

  }
  objets = {
    pkObjet: 0, nom: "", extension: "", id_patient: 0, vignette: "", width: 0,
    height: 0, taille: 0, estidentite: 0, dateCreation: "", echelle: 0, fichier: "", lastModif: "",
    repStockage: "", syncPath: "", dateInsertion: "", auteur: "", idGabarit: "", id_patient_orthalis: ""
  };
  insertObjet(nom, extension, id_patient1, vignette, width,
    height, taille, estidentite, dateCreation, echelle, fichier, lastModif,
    repStockage, syncPath, dateInsertion, auteur, idGabarit, id_patient_orthalis) {
    this.objets["nom"] = nom;
    this.objets["extension"] = extension;
    this.objets["id_patient"] = id_patient1;
    this.objets["vignette"] = vignette;
    this.objets["width"] = width;
    this.objets["height"] = height;
    this.objets["taille"] = taille;
    this.objets["estidentite"] = estidentite;
    this.objets["dateCreation"] = dateCreation;
    this.objets["echelle"] = echelle;
    this.objets["fichier"] = fichier;
    this.objets["lastModif"] = lastModif;
    this.objets["repStockage"] = repStockage;
    this.objets["syncPath"] = syncPath;
    this.objets["dateInsertion"] = dateInsertion;
    this.objets["auteur"] = auteur;
    this.objets["idGabarit"] = idGabarit;
    this.objets["id_patient_orthalis"] = id_patient_orthalis;
    return this.objetService.addObjet(this.objets)

  }


  ancienNom;
  fichier;
  imageToVisionate;
  async updateDataToImage(vignette: string, fichier: string, destName: string, imagesVisionate) {
    // obj : objet={}

    var obj: objet = imagesVisionate;
    this.ancienNom = obj.fichier;
    obj.nom = destName ;
    obj.extension = ".jpg";
    obj.vignette = vignette;
    this.fichier = fichier + ".JPG";
    obj.fichier = this.fichier;
    this.imageToVisionate = obj;

    return await this.objetService.updateIdGabarit(obj).toPromise().then()

  }

  /*
  
      (await this.updateImage(this.imgUrl.substring( this.base64textString.indexOf(';')+8),destName,destName)).toPromise().then(async ()=>{
           /// saveImage
          await this.objetService
           .updateImage(destName + ".JPG", Context.folder + this.idPersonne, form ,this.ancienNom).toPromise()
                    .then(res=>{
  
  */
  async updateImage(imgUrl, destName, idPersonne, form, imagesVisionate) {
// console.log("imgUrl",imgUrl);
// console.log("destName",destName);
// console.log("idPersonne",idPersonne);
// console.log("form",form);
// console.log("imagesVisionate",imagesVisionate);

    await this.updateDataToImage(imgUrl, destName, destName, imagesVisionate)
      .then(async (res1) => {



        /// saveImage
        await this.objetService
          .updateImage(destName + ".JPG", Context.folder + idPersonne, form, this.ancienNom)
          .toPromise()
          .then(res => {

            //  this.loadData(this.Context , this.idPersonne , res1['fichier']).then(res=>{this.showCrop=false})   
            //this.closeModal()


          })
          .catch(err => {

          })


        /////
      })
  }


}

