import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Platform } from '@ionic/angular'
import { Context } from '../shared/services/context.service';
import { HorairesDeTravail } from '../model/HorairesDeTravail';

@Injectable({
  providedIn: 'root'
})
export class HoraireTravailService {

  constructor( private http: HttpClient,
    public platform: Platform,
    private context: Context,) { }

    private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
   
    GetHorairesDeTravail(idUser : number) {
      return this.http.get<HorairesDeTravail[]>(Context.apiUrl + 'GetHorairesDeTravail/' + idUser);
  }
  saveHoraireUser(idUser : number , weekDay : number , startTime : string , endTime : string){
    return this.http.get<HorairesDeTravail[]>(Context.apiUrl + 'SaveHorairesUser/'+idUser+'/'+weekDay+'/'+startTime+'/'+endTime );
  }
  deleteHoraire(idUser : number , weekDay : number , startTime : string , endTime : string){
    ///deleteHoraireByUser (1,2, Time.valueOf("00:00:00"),Time.valueOf("20:00:00") )
    return this.http.get<any>(Context.apiUrl + 'deleteHoraireByUser/'+idUser+'/'+weekDay+'/'+startTime+'/'+endTime  );

  }
  updateHoraire(list : any [] , idUser : number ){
          return this.http.post<any>(Context.apiUrl+'updateUserHoraire/idUser?idUser='+idUser , list  ).toPromise().then().catch(err=>{
          });

  }
  getListeHorraireReelByIdUser(idUser : number , annee : number , week : number){
    return this.http.get<any>(Context.apiUrl + 'getHoraireReelByIdUserWeekNumDay/'+idUser+"/"+annee+"/"+week );
  }

  getUsersByListeHorraireReel(annee : number , weeknum : number , daynum : number){
    console.log(annee,weeknum,daynum);
    
    return this.http.get<any>(Context.apiUrl + 'GetUsersByHorairesDeTravail/'+annee+'&'+weeknum+'&'+daynum );
  }
  addHoraireReel (data : any){
    //addHoraireReel
    return this.http.post<any>(Context.apiUrl+'addHoraireReel/' , data  );

  }
  deleteHoraireById(id : number){
    //deleteHoraireReelById
    return this.http.delete<any>(Context.apiUrl + 'deleteHoraireReelById/'+id ).toPromise().catch(err=>{});
  }
  updateHoraireReel(data : any , id : number) {
    return this.http.put<any>(Context.apiUrl+'updateHoraireReel/'+id , data  );
  }
  getNombreHeuresParPraticien(annee : number){
    return this.http.get<any>(Context.apiUrl + 'getNombreHeuresParPraticien/'+annee );
  }
}
