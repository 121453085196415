export class objet {

	public pkObjet: number;
	public nom: string;
	public extension: string;
	public id_patient: number;
	public vignette: string;
	public width: number;
	public height: number;
	public taille: number;
	public estidentite: number;
	public dateCreation: Date;
	public echelle: number;
	public fichier: string;
	public lastModif: Date;
	public repStockage: string;
	public syncPath: string;
	public dateInsertion: Date;
	public auteur: string;
	public idGabarit: number;
	public id_patient_orthalis: number
}
