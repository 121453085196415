import { Component, OnInit, AfterViewInit, AfterViewChecked } from '@angular/core';
import { NavParams, ModalController, LoadingController } from '@ionic/angular';
import { UserService } from 'src/app/service/user.service';
import { Context, InjectedContext } from 'src/app/shared/services/context.service';
import { AttributService } from 'src/app/service/attribut.Service';
import { LnkAttributsObjetsService } from 'src/app/service/lnkAttributsObjets.service';


@Component({
  selector: 'app-gabarit-property',
  templateUrl: './gabarit-property.component.html',
  styleUrls: ['./gabarit-property.component.scss'],
})
export class GabaritPropertyComponent implements OnInit {


  loading;
  objetSelected = {};
  oldIdAttributSelected = [];
  idAttribut = [];
  NewIdAttributSelected = [];
  allAttributs = [];
  attributsObjet = [];
  attributSelected: string;
  block: number;
  lnkAttributesObjets = { id_attribut: 0, id_objet: 0, valeur: true, valeur_bool: 0 };
  constructor(
    private userService: UserService,
    private navParams: NavParams,
    private context: Context,
    private attributService: AttributService,
    private lnkAttributObjetService: LnkAttributsObjetsService,
    private modalCtrl: ModalController,
    private loadingController: LoadingController) {

    this.objetSelected = navParams.data.objetSelected;
  }

  ngOnInit() {
    this.allAttributs = InjectedContext.Attributs;
    this.getAttributByObjet(this.objetSelected);
    this.block = 0;
  }

  getAttributByObjet(objetSelected) {
    this.attributService.AttributsByObjet(objetSelected.pkObjet).subscribe(res => {
      this.attributsObjet = res;
      for (let attr of this.attributsObjet) {
        this.oldIdAttributSelected.push(attr.pk_attribut);
        this.NewIdAttributSelected.push(attr.pk_attribut);
      }
    });
  }

  AddToObjet(Attribut) {
    if (this.block > this.NewIdAttributSelected.length - 1) {
      if (this.NewIdAttributSelected.indexOf(Attribut.pk_attribut) === -1) {
        this.NewIdAttributSelected.push(Attribut.pk_attribut);
      } else {

        this.NewIdAttributSelected.splice(this.NewIdAttributSelected.indexOf(Attribut.pk_attribut), 1);
      }
    }
    this.block = this.block + 1;
  }

  affectAttributToObjet() {
    if (this.oldIdAttributSelected.length === 0) {
      for (let pkAttribut of this.NewIdAttributSelected) {
        this.lnkAttributesObjets["id_attribut"] = pkAttribut;
        this.lnkAttributesObjets["id_objet"] = this.objetSelected["pkObjet"];
        //insert
        this.lnkAttributObjetService.insertInLnkAttributObjet(this.lnkAttributesObjets).subscribe(result => {
        }, error => console.error(error));
      }
    } else {

      for (let pkAttribut of this.oldIdAttributSelected) {
        //delete
        if (this.NewIdAttributSelected.indexOf(pkAttribut) === -1) {
          this.lnkAttributObjetService.deleteByObjetAttribut(this.objetSelected["pkObjet"], pkAttribut).subscribe(result => {
          }, error => console.error(error));
        }
      }
      for (let pkAttribut of this.NewIdAttributSelected) {
        this.lnkAttributesObjets["id_attribut"] = pkAttribut;
        this.lnkAttributesObjets["id_objet"] = this.objetSelected["pkObjet"];
        //insert
        this.lnkAttributObjetService.insertInLnkAttributObjet(this.lnkAttributesObjets).subscribe(result => {
        }, error => console.error(error));
      }
    }
    this.closeModal();
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }
}
