import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { CameraPreview, CameraPreviewOptions, CameraPreviewPictureOptions, CameraPreviewDimensions } from '@ionic-native/camera-preview/ngx';

import { UserService } from './user.service';
import { ObjetService } from './objet.service';


@Injectable({
  providedIn: 'root'
})
export class CameraService {

  options: any;
  listPicture = [];
  constructor(
    private platform: Platform,
    private cameraPreview: CameraPreview,
    private objetService: ObjetService
  ) {
    
  

  }
  setZoom(zoom)
  {
    this.cameraPreview.setZoom(zoom) .catch(err => {
      alert('CameraPreviewService: An error occured while setting the camera zoom: ');
    });
  }
  reverseCamera()
  {
  
    this.cameraPreview.switchCamera();
  }
  focus(event){
    let xPoint = event.x;
    let yPoint = event.y
    this.cameraPreview.tapToFocus(xPoint,yPoint);
  }
  openCamera(callback) {

    this.cameraPreview.setFocusMode(this.cameraPreview.FOCUS_MODE.INFINITY);
   // this.cameraPreview.setExposureMode(this.cameraPreview.EXPOSURE_MODE.AUTO);
  
      const CameraPreviewOpts: CameraPreviewOptions = {
         x: 0,
         y: 50,
         width: window.screen.width,
         height: window.screen.height - 300,
         camera: 'rear',
         tapPhoto: true,
         previewDrag: true,
         tapToFocus: false,
         toBack: true,
         alpha: 0,
        // disableExifHeaderStripping:false
      }
      this.options = CameraPreviewOpts;
   
    this.cameraPreview.startCamera(this.options).then(
      (res) => {
        if (callback) {
          callback();
        }
        this.cameraPreview.setZoom(1);
        this.setpreviewSize();
     
      },
      (err) => {
      });
  }
  close() {
    this.cameraPreview.stopCamera();
  }
  public maxWidth = 0;
  public maxheight = 0;
  async setpreviewSize() {
    var that = this;
    this.cameraPreview.getSupportedPictureSizes().then((dimensions) => {

      dimensions.forEach(function (dimension) {
        if (dimension.width > that.maxWidth) {
          that.maxWidth = dimension.width;
          that.maxheight = dimension.height;
        }
      });
      const cameraPreviewDimensions: CameraPreviewDimensions = {
        width: this.maxWidth,
        height: this.maxheight,
      }
          this.cameraPreview.setPreviewSize(cameraPreviewDimensions)
    },
      (err) => {
        alert(err)
      });
    // const cameraPreviewDimensions: CameraPreviewDimensions = {
    //   width: this.maxWidth,
    //   height: this.maxheight,
    // }
    // this.cameraPreview.setPreviewSize(cameraPreviewDimensions);
  }
  getListePhotos(idPersonne) {
    return this.objetService.listphotoCamera(idPersonne).
      subscribe(listPicture => {
        this.listPicture = listPicture;
      });
  }
}
