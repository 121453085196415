import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class CustomLoadingService {
  public loading;
    constructor(private loadingController: LoadingController,) { }
    async presentLoading() {
      this.loading = await this.loadingController.create({
        cssClass: 'custom-loader',
        spinner: null,
        message:`
        <div class="sk-chase">
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
         </div>`,
        showBackdrop: true,
      });
      await this.loading.present();
  
    }
  
  dismissLoading() {
    this.loading.dismiss();
  }
}
