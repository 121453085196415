import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss'],
})
export class RedirectComponent implements OnInit {
  

  constructor(private router: Router) 
  {  

  }

  ngOnInit() {
   
    window.location.href="/home";
    console.log('haha');
    //this.router.navigate(['/home']);
     //this.router.navigateByUrl('appointments');
    //this.router.navigate(['home']);
    
  }

}
