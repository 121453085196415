import { NgModule } from '@angular/core';
import { AppointmentsPage } from './appointments.page';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { DropDownListAllModule, MultiSelectAllModule, AutoCompleteModule } from '@syncfusion/ej2-angular-dropdowns';
import { MaskedTextBoxModule, UploaderAllModule } from '@syncfusion/ej2-angular-inputs';
import { ToolbarAllModule, ContextMenuAllModule,MenuAllModule, TreeViewModule, ContextMenuModule, TabAllModule } from '@syncfusion/ej2-angular-navigations';
import { ButtonAllModule, CheckBoxAllModule } from '@syncfusion/ej2-angular-buttons';
import { HttpModule } from '@angular/http';
import { DatePickerAllModule, TimePickerAllModule, DateTimePickerAllModule, CalendarComponent, CalendarModule } from '@syncfusion/ej2-angular-calendars';
import { NumericTextBoxAllModule } from '@syncfusion/ej2-angular-inputs';
import { ScheduleAllModule, RecurrenceEditorAllModule, DayService, WeekService, WorkWeekService, MonthService, AgendaService, MonthAgendaService, DragAndDropService } from '@syncfusion/ej2-angular-schedule';
import { FauteuilDefaultService } from 'src/app/service/fauteilDefault.service';
import { ToastModule } from '@syncfusion/ej2-angular-notifications';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { DropDownButtonModule } from '@syncfusion/ej2-angular-splitbuttons';
import { AffectFauteuilModalPage } from '../affect-fauteuil-modal/affect-fauteuil-modal.page';
import { AffectFauteuilDeletePage } from '../affect-fauteuil-delete/affect-fauteuil-delete.page';


const routes: Routes = [
  {
    path: '',
    component: AppointmentsPage
  }
];

@NgModule({
  imports: [
    CommonModule, ContextMenuModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    CommonModule, HttpModule, 
    ScheduleAllModule, RecurrenceEditorAllModule, DateTimePickerAllModule,
    NumericTextBoxAllModule, DatePickerAllModule,TimePickerAllModule,
    CheckBoxAllModule,ToolbarAllModule, DropDownListAllModule,
    ContextMenuAllModule,MaskedTextBoxModule, UploaderAllModule, 
    MultiSelectAllModule,TreeViewModule, ButtonAllModule,
    AutoCompleteModule,MenuAllModule,ReactiveFormsModule, 
    TabAllModule,ToastModule,DialogModule,CalendarModule,
    ScheduleAllModule,DropDownButtonModule,

  ],
  declarations: [AppointmentsPage,AffectFauteuilModalPage,AffectFauteuilDeletePage],
  providers: [
    DayService,
    WeekService,
    WorkWeekService,
    MonthService,
    AgendaService,
    MonthAgendaService, FauteuilDefaultService,
    DragAndDropService,
    DatePipe
  ],
  entryComponents :[AffectFauteuilModalPage,AffectFauteuilDeletePage]
})
export class AppointmentsPageModule {}
