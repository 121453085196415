import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GalleryPhotoComponent } from 'src/app/components/views-photos/gallery-photo/gallery-photo.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { Context } from './services/context.service';
import { HttpClientModule } from '@angular/common/http';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { UserService } from '../service/user.service';
import { ImageViewerComponent } from '../components/views-photos/image-viewer/image-viewer.component';
import { CameraComponent } from '../components/camera/camera.component';
import { LoadingService } from './services/LoadingService.service';
import { ImageCropperModule } from 'ngx-image-cropper';

import { ListViewAllModule } from '@syncfusion/ej2-angular-lists';
import { ImageProcessingComponent } from '../components/edit-photo/image-processing/image-processing.component';
import { PinchZoomModule } from 'ngx-pinch-zoom';
import { ZoomDirective } from '../zoom-directive/zoom.directive';
import { CustomSpinnerComponent } from '../components/custom-spinner/custom-spinner.component';

@NgModule({
  declarations: [
    GalleryPhotoComponent,
    CameraComponent,
    ImageViewerComponent,
    ImageProcessingComponent,
    CustomSpinnerComponent,
    ZoomDirective
  ],
  imports: [
    FormsModule,
    IonicModule,
    HttpClientModule,
    CommonModule,
    VirtualScrollerModule,
    ListViewAllModule, ImageCropperModule,
    PinchZoomModule,
  ],
  exports: [
    GalleryPhotoComponent,
    CameraComponent,
    ImageViewerComponent,
    ImageProcessingComponent,
    CustomSpinnerComponent,
  ],
  providers: [Context, UserService, LoadingService
  ]
})
export class SharedModule { }