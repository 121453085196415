import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { Context } from '../shared/services/context.service';
import { CommClinique } from '../model/CommClinique.model';
import { CommActesSuppTk } from '../model/commActeSuppTk.model';
import { CommActe } from '../model/commActe.model';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};




@Injectable({
  providedIn: 'root'
})
export class CommCliniqueService {
  constructor(
    private http: HttpClient,
    private context: Context,
  ) {

  }

  private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

  updateCommCliniqueIdRDV(comm) {
    return this.http.put(Context.apiUrl + 'CommClinique/updateCommCliniqueIdRDV', comm);
  }

  getCommCliniquesByIdPatient(idPatient) {
    return this.http.get<CommClinique[]>(Context.apiUrl + 'CommClinique/GetCommCliniquesByIdPatient?id=' + idPatient)
  }

  getCommActeSuppByPatient(idPatient) {
    return this.http.get<CommActe[]>(Context.apiUrl + 'CommActeByPatient/' + idPatient)
  }
  saveCommClinique ( comm  , date ){
    return this.http.post(Context.apiUrl + 'CommClinique/saveCommClinique?date='+date, comm)
  }
  saveCommActe (comActe : any []){
    return this.http.post<CommActe>(Context.apiUrl + 'saveCommActe', comActe)
  }

  getMaxIdCommClinique ( ){
    return this.http.get<CommClinique>(Context.apiUrl + 'CommClinique/getMaxId')
  }

} 