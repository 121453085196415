import {Injectable, Injector} from '@angular/core';
import {Platform} from '@ionic/angular';
import {GabaritOutil} from 'src/app/model/model.GabaritOutil';
import {HttpClient, HttpXhrBackend} from '@angular/common/http';
import {Attributs} from 'src/app/model/attributes.model';
import {BrowserXhr} from '@angular/http';
import {_MatMenuDirectivesModule} from '@angular/material';
import {environment} from '../../../environments/environment';
import {User} from 'src/app/model/user.model';
import {Pays} from '../../model/Pays.model';


@Injectable({
    providedIn: 'root'
})
export class Context {

    //public cabinet;
    gabaritOutils: GabaritOutil[];
    attributs = [];

    constructor(
        private http: HttpClient,
        public platform: Platform) {

    }

    nomGabarit: string;

    detectPlatformAndroid() {
        return this.platform.is('android');
    }

    detectPlatformDesktop() {
        return this.platform.is('desktop');
    }

    detectPlatformTablette() {
        return this.platform.is('tablet');
    }

    static get cabinet() {
        return environment.cabinet;
    }

    static get currency() {
        return environment.currency;
    }

    static get apiUrl() {
        return environment.apiUrl;
    }

    static get root_folder() {
        return environment.root_folder;
    }

    static get folder() {
        return environment.folder;
    }
}

export class InjectedContext {
    protected static injectedContext: InjectedContext;
    public static Fromlogout;
    public static dataEmptyMessage = 'aucune donnée à afficher';
    public static GabaritOutils: any = [];
    public static listPraticien: any = [];


    private static connexion(): HttpClient {

        const xhrFactoryInjector = Injector.create([
            {provide: BrowserXhr, useClass: BrowserXhr, deps: []}
        ]);

        const httpHandlerInjector = Injector.create([
            {provide: HttpXhrBackend, useClass: HttpXhrBackend, deps: [BrowserXhr]}
        ], xhrFactoryInjector);


        const httpClientInjector = Injector.create([
            {provide: HttpClient, useClass: HttpClient, deps: [HttpXhrBackend]}
        ], httpHandlerInjector);
        const httpClient: HttpClient = httpClientInjector.get(HttpClient);
        return httpClient;
    }


    private static _Attributs = [];
    static get Attributs() {
        if (this._Attributs.length === 0) {

            const httpClient = this.connexion();
            const result = Promise.all([
                httpClient.get<Attributs[]>(Context.apiUrl + 'Attributs').toPromise()
            ]);
            result.then(result => {
                this._Attributs = result[0];
                return this._Attributs;
            });
        } else {
            return this._Attributs;
        }
    }

    static set Attributs(value) {
        this._Attributs = value;
    }

    static getGabaritNameById(idGabarit) {

        const httpClient = this.connexion();

        return httpClient.get<string>(Context.apiUrl + 'NomGabarit/' + idGabarit);
    }

    private static _pictures = new Map();
    static get pictures() {
        return this._pictures;
    }

    static set pictures(map: Map<any, any>) {
        map.forEach((value: string, key: string) => {
            this._pictures.set(key, value);
        });
    }

    private static _localPictures = new Map();
    static get localPictures() {
        return this._localPictures;
    }

    static set localPictures(map: Map<any, any>) {
        map.forEach((value: string, key: string) => {
            this._localPictures.set(key, value);
        });
    }

    public static clearPictures() {
        this._localPictures.clear();
        this._pictures.clear();
    }

    private static _picturePatient = [];
    static get picturePatient() {
        return this._picturePatient;
    }

    static set picturePatient(value) {
        this._picturePatient = value;
    }

    public static capturedPicture;

    private static _lstpays: Pays[];
    static get lstpays(): Pays[] {
        return this._lstpays;
    }

    static set lstpays(lst: Pays[]) {
        this._lstpays = lst;
    }
}
