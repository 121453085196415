import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Context } from '../shared/services/context.service';
import { pointage } from '../model/TplEcheanceCAMoisPrevisionnel';

@Injectable({
  providedIn: 'root'
})
export class PointageService {

  constructor( private http: HttpClient,
    private context: Context,) { }
    private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
    getPointageByUser(startDate : string , endDate : string){
      //2017-2-19 00:00:00
      return this.http.get<pointage[]>(Context.apiUrl + 'pointageDuJourByIdUserAndDates/'+5+'&'+startDate+'&'+endDate);
    }

    getPointageByUserByID(id,startDate : string , endDate : string){
      return this.http.get<pointage[]>(Context.apiUrl + 'pointageDuJourByIdUserAndDates/'+id+'&'+startDate+'&'+endDate);
    }

    setPointageByUserByID(pointage){
      return this.http.post(Context.apiUrl + 'setpointage/',pointage);
    }
}
