import { Component, OnInit, Output, EventEmitter, SimpleChanges, OnChanges, OnDestroy, ViewChild } from '@angular/core';
import { ModalController, Platform, PopoverController, NavParams, AlertController, MenuController, NavController} from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/service/user.service';
import { ObjetService } from 'src/app/service/objet.service';
import { InjectedContext, Context } from 'src/app/shared/services/context.service';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { ImageViewerComponent } from 'src/app/components/views-photos/image-viewer/image-viewer.component';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import * as sha1 from 'sha1';
import { objet } from 'src/app/model/objet.model';
import { SmilersService } from 'src/app/service/smilers.service';
import { SmilersData, SmilersPicturesId } from 'src/app/model/smilers.model';
import { CustomToastService } from 'src/app/service/custom-toast.service';
import { SmilersCredentialsComponent } from 'src/app/components/smilers-credentials/smilers-credentials.component';
import { CustomLoadingService } from 'src/app/service/custom-loading.service';
import { toastTypeColor, toastTime } from '../../model/types';
import { UploadPhotoComponent } from 'src/app/components/edit-photo/upload-photo/upload-photo.component';
import { GalleryPhotoComponent } from 'src/app/components/views-photos/gallery-photo/gallery-photo.component';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: 'app-photos-list',
  templateUrl: './photos-list.page.html',
  styleUrls: ['./photos-list.page.scss'],
})
export class PhotosListPage implements OnInit {
  @ViewChild(GalleryPhotoComponent, { static: false }) galleryPhotoComponent: GalleryPhotoComponent;
  public radiusmiles = 1;
  public minmaxprice = {
    upper: 500,
    lower: 10
  };
  public isDesktop;

  public idUser;
  public newImage;         
  numPhotos
  user = {};
  public showAllPictures : number = 0;
  constructor(
    public alertController: AlertController,
    private activatedRoute: ActivatedRoute,
    private modalCtrl: ModalController,
    public platform: Platform,
    private userService: UserService,
    private objetService: ObjetService,
    private smilersService:SmilersService,
    // private navParams: NavParams,
    private navCtrl :NavController,
    private menu : MenuController,
    private camera : Camera,
    private route: ActivatedRoute,
    private router:Router,
    private context: Context,
    private customToastService: CustomToastService,
    private customLoading: CustomLoadingService,
    ) {
      this.isDesktop = this.context.detectPlatformDesktop();
     
      
    this.route.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {
        this.idPersonne = this.router.getCurrentNavigation().extras.state.idPersonne;
      }
    });
  }
  imagesToVisionate = [];
  selectedUser = {};
  platf;
  id = null;
  userImage = [];
  ListImageAdded = [];
  selectedImages = [];
  photoNumber: number;
  idPersonne: number;
  userSelected: string;
  ngOnInit() {
    
    this.idUser = localStorage.getItem("idPatient");
    this.idPersonne = this.idPersonne ? this.idPersonne : this.idUser;
    this.objetService.getPhotoNumber(this.idPersonne).subscribe(nb => {
      this.photoNumber = nb;
    });
    if( InjectedContext.picturePatient.length === 0)
    this.objetService.listphoto(this.idPersonne).subscribe(photos => {
      InjectedContext.picturePatient = photos;
    });
  }
  reloadUser;
  async createDossierPatient(path)
   {
     this.objetService.createDossierPatient(path).subscribe(value=>{
   },error=>{
     // alert(error);
    });
  }
  ionRefresh(event){
    
    
  setTimeout(() => {
    this.reloadUser=Math.random().toString(36);
    event.target.complete();
  }, 2000);
}

  closeModal() {
    this.modalCtrl.dismiss();
    this.navCtrl.navigateBack(['home']);
  }
testCamera()
{
  const options: CameraOptions = {
    quality: 100,
    destinationType: this.camera.DestinationType.DATA_URL,
    encodingType: this.camera.EncodingType.JPEG,
    mediaType: this.camera.MediaType.PICTURE,
    sourceType: this.camera.PictureSourceType.CAMERA,
    allowEdit: false,
   // saveToPhotoAlbum : true
  }
  this.camera.getPicture(options).then((imageData) => {
   // imageData is either a base64 encoded string or a file URI
   // If it's base64 (DATA_URL):
   let base64Image = 'data:image/jpeg;base64,' + imageData;
   this.picturesThumbnail.set(imageData,imageData);
   InjectedContext.pictures = this.picturesThumbnail;
   InjectedContext.localPictures = this.picturesThumbnail;
   this.openPicture();
  //  this.createThumbnail(base64Image);
  }, (err) => {
   // Handle error
  });
}
async openPicture() {
  const modal = await this.modalCtrl.create({
    component: ImageViewerComponent,
    componentProps: {
   //   'newPictures': this.capturedImages,
      'idUser': this.idPersonne
    },
    cssClass: 'my-custom-modal-css'
  });
  modal.onDidDismiss()
    .then((data) => {
      //this.capturedImages.clear();
      this.capturedImage = "";
      this.picturesThumbnail.clear();
    });
  modal.present().then(value => {
  });

}
generateFromImage(img, MAX_WIDTH: number = 700, MAX_HEIGHT: number = 700, quality: number = 1, callback) {
  var canvas: any = document.createElement("canvas");
  var image = new Image();
  image.onload = ()=>{
    var width = image.width;
    var height = image.height;;

    if (width > height) {
      if (width > MAX_WIDTH) {
        height *= MAX_WIDTH / width;
        width = MAX_WIDTH;
      }
    } else {
      if (height > MAX_HEIGHT) {
        width *= MAX_HEIGHT / height;
        height = MAX_HEIGHT;
      }
    }
    canvas.width = width;
    canvas.height = height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(image, 0, 0, width, height);

    // IMPORTANT: 'jpeg' NOT 'jpg'
    var dataUrl = canvas.toDataURL('image/jpeg', quality);
    callback(dataUrl)
  }
  image.onerror = ()=>{
  }
    image.src = "data:image/jpeg;base64," + img;

}
createThumbnail(base64) {
  this.generateFromImage(base64, 700, 700, 1, data => {
    this.picturesThumbnail.set(base64,data);
    InjectedContext.localPictures = this.picturesThumbnail;
    this.capturedImage = data;
  }),error=>{
    alert(error);
  };
}
capturedImage = "";
picturesThumbnail =  new Map();
  detectPlatform() {
    this.platf = this.userService.detectPlatform();
    return this.platf;
  }
  selectionChanges(selectedImages) {
    this.selectedImages = selectedImages
  }
 
  setPhotoToVisionate(imagesToVisionate) {
    this.imagesToVisionate = imagesToVisionate
  }


  getUserSelected() {
    this.userService.getUserById(this.idPersonne)
      .subscribe((userObject) => {
        this.selectedUser = userObject;
      });
  }
  public noPhotos: string = 'Aucune photo selectionnée...';
  async visionneuse() {
    if(this.imagesToVisionate.length === 0 && !this.isDesktop) {
      await this.customToastService.presentToast(this.noPhotos, toastTypeColor.red, toastTime.long);
      return;
    }
    const imagesToVisionate = this.imagesToVisionate;
      if ( this.isDesktop){  
        this.router.navigate(['edit-photos-list'], 
      { state: { idPersonne: this.idPersonne ,
         userSelected :  this.userSelected,
         imagesToVisionate : this.imagesForListPhotoDesktop
        } , replaceUrl: true });
    }
     else{  
        this.router.navigate(['viewer'],
     { state: { idPersonne: this.idPersonne,imagesToVisionate: imagesToVisionate } });}
   
  }
  getPhotoNumber(photoNumber) {
    this.showPhotos = false;    
    this.photoNumber = photoNumber;
    this.getUserSelectedDetails(this.idPersonne);
  }

  getUserSelectedFromListePersonne($event) {
    this.userSelected = $event;
  }
  closeCameraOnBackButton() {
    var that = this;
    document.addEventListener("backbutton", function () {
      that.navCtrl.navigateBack(['home']);
    });
  }
  async takePicture() {
    this.customLoading.presentLoading().then(async value => {
    this.showAllPictures ++;
    this.router.navigate(['picturesPatient'],
    { state: { picturesPatient: this.userImage, showAllPictures: this.showAllPictures } }).then(x => {
     this.customLoading.dismissLoading();
   });
    });
  }
  async alertPicturesEmpty() {
    const alert = await this.alertController.create({
      header: 'Alert',
      subHeader: 'Subtitle',
      message: 'Aucun image trouvée.',
      buttons: [{
        text: 'OK',
        cssClass: 'secondary',
        handler: (blah) => {
          this.closeModal();
        }
      }
      ]
    });
    await alert.present();
  }
  ionPull(event)
  {

  }
  objets = {
    pkObjet: 0, nom: "", extension: "", id_patient: 0, vignette: "", width: 0,
    height: 0, taille: 0, estidentite: 0, dateCreation: "", echelle: 0, fichier: "", lastModif: "",
    repStockage: "", syncPath: "", dateInsertion: "", auteur: "", idGabarit: "",id_patient_orthalis:""
  };
  insertObjet(nom, extension, id_patient1, vignette, width,
    height, taille, estidentite, dateCreation, echelle, fichier, lastModif,
    repStockage, syncPath, dateInsertion, auteur, idGabarit , id_patient_orthalis) {

    this.objets["nom"] = nom;
    this.objets["extension"] = extension;
    this.objets["id_patient"] = id_patient1;
    this.objets["vignette"] = vignette;
    this.objets["width"] = width;
    this.objets["height"] = height;
    this.objets["taille"] = taille;
    this.objets["estidentite"] = estidentite;
    this.objets["dateCreation"] = dateCreation;
    this.objets["echelle"] = echelle;
    this.objets["fichier"] = fichier;
    this.objets["lastModif"] = lastModif;
    this.objets["repStockage"] = repStockage;
    this.objets["syncPath"] = syncPath;
    this.objets["dateInsertion"] = dateInsertion;
    this.objets["auteur"] = auteur;
    this.objets["idGabarit"] = idGabarit;
    this.objets["id_patient_orthalis"]= id_patient_orthalis ; 
    return  this.objetService.addObjet(this.objets)
        
  }
  file;
  base64textString;
  binaryString: string;
  name: string;
  imageData;
  async presentAlertConfirm() {
    const alert = await this.alertController.create({
      header: 'alert!',
      message: ' <strong>image existe deja </strong>',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
          }
        }
      ]
    });

    await alert.present();
  }
  imagesForListPhotoDesktop : objet[] =[];
  uploadedPhoto: boolean = false;
  async getuserImages(args : []){
    if (args.length != 0){
      this.imagesForListPhotoDesktop=args ; 
      this.uploadedPhoto = true; // for displaying toolbar
      this.getAllPictures(); // get all images of user
    }
    
   // this.userImage.concat(args)
  }
   nbImageUpLoad : number = 0; 
  async onFileSelected(event) {
      if(event.target.files.lenght==0) return
    const modal = await this.modalCtrl.create({
      component: UploadPhotoComponent,
      cssClass : ' sc-ion-modal-md-h-progressBar ' ,
      componentProps: {
        'fileList': event.target.files,
        'idUser' : this.idUser ,
        'ContextFolder' : Context.folder  
      },
      keyboardClose :false,
    });
    modal.onDidDismiss().then((result)=>{
      let imgs = result.data;
      if(imgs) {
        this.ListImageAdded=[];
        this.ListImageAdded.push(result.data) ; 
        this.ListImageAdded = this.ListImageAdded.slice(); 
        this.galleryPhotoComponent.refreshImage(imgs);
      }
    })
    modal.present();
  }
  public userDetails;
  public showPhotos =false;
  public photoSmilers = [];
  async getUserSelectedDetails(id) {
    this.userService.getUserById(id).subscribe(res => {
      this.userDetails = res;
    });
  }
  async getAllPictures() {
    // loading photos
    if (this.imagesForListPhotoDesktop.length == this.photoNumber) {
      let imgFiltred = this.imagesForListPhotoDesktop.filter(pic => !pic.fichier.includes('PDF'));
      await this.findAllPictures(imgFiltred).then(async res => {
        this.photoSmilers = res;
        let i = 0;
        while (i < this.photoSmilers.length && !this.showPhotos) {
          if (this.photoSmilers[i]) this.showPhotos = true; // when one image valid show btn smilers
          i++;
        }
        if(i == this.photoSmilers.length) this.showPhotos = true; // no photos to display
      })
    }
  }
  async findAllPictures(imgFiltred) {
    let photos = [];
    let frontalViewSmile = await imgFiltred.find( p => p .idGabarit == SmilersPicturesId.frontalViewSmile);
    let profile = await imgFiltred.find( p => p .idGabarit == SmilersPicturesId.profile);
    let occlusionViewFront = await imgFiltred.find( p => p .idGabarit == SmilersPicturesId.occlusionViewLeft);
    let occlusionViewLeft = await imgFiltred.find( p => p .idGabarit == SmilersPicturesId.occlusionViewLeft);
    let occlusionViewRight = await imgFiltred.find( p => p .idGabarit == SmilersPicturesId.occlusionViewRight);
    let occlusionViewMaxilla = await imgFiltred.find( p => p .idGabarit == SmilersPicturesId.occlusionViewMaxilla);
    let occlusionViewMandibula = await imgFiltred.find( p => p .idGabarit == SmilersPicturesId.occlusionViewMandibula);
    let panoramic = await imgFiltred.find( p => p .idGabarit == SmilersPicturesId.panoramic);
    let innocclusion = await imgFiltred.find( p => p .idGabarit == SmilersPicturesId.innocclusion);
    let teleRadio = await imgFiltred.find( p => p .idGabarit == SmilersPicturesId.teleRadio);
    await photos.push(frontalViewSmile,profile,occlusionViewFront,occlusionViewLeft,
      occlusionViewRight,occlusionViewMaxilla,occlusionViewMandibula,panoramic,
      innocclusion,teleRadio);
      return photos;
  }
  makePicturesToSend(photos) {
    let allPics = [];
    for(let i = 0 ; i < photos.length ; i++) {
      let pic;
      if(photos[i]) {
         pic = [Context.root_folder + Context.folder + this.idPersonne + "/" + photos[i].fichier, 
        photos[i].fichier.toLowerCase()];
      } else pic =null
      allPics.push(pic);
    }
    return allPics;
  }
  async smilersCredentials() {
          const modal = await this.modalCtrl.create({
        component: SmilersCredentialsComponent,
        componentProps: {
          'photos': this.photoSmilers,
          'userDetails': this.userDetails
        }
      });
      modal.onDidDismiss()
      .then((data) => {
        if(data['data']) {
          this.smilersData(data['data']);
        }
      });
      return modal.present();
  }
  async smilersData(userFromModal) {  
   let date = userFromModal.naissance.substring(0,10);
   let dateToSend = date.substring(date.length - 2,date.length) + "/" + date.substring(5,7) + "/" 
      + date.substring(0,4);
   let allPics = this.makePicturesToSend(this.photoSmilers);
   let smilersPics:SmilersData = new SmilersData();
      smilersPics.prenom = userFromModal.prenom;
      smilersPics.nom = userFromModal.nom;
      smilersPics.titre = userFromModal.titre;
      smilersPics.dateNaissance = dateToSend;
      smilersPics.PIC1 =  allPics[0];      
      smilersPics.PIC2= allPics[1];
      smilersPics.PIC3= allPics[2];
      smilersPics.PIC4= allPics[3];
      smilersPics.PIC5= allPics[4];
      smilersPics.PIC6= allPics[5];
      smilersPics.PIC7= allPics[6];
      smilersPics.PIC8= allPics[7];
      smilersPics.PIC9= allPics[8];
      smilersPics.PIC10= allPics[9];
    await this.customToastService.presentToast("  La création du patient dans Smilers est en cours  ... ", "primary", 4000);
    await this.smilersService.addPatientToSmilers(this.idPersonne,smilersPics).toPromise().then(t => {
      window.open("https://smilers.biotech-dental.com/fr/treatment/cart");
    })
  }

}
