import { NgModule, Injector } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from 'src/app/shared/shared.module';
import { CameraComponent } from 'src/app/components/camera/camera.component';
import { ImageViewerComponent } from 'src/app/components/views-photos/image-viewer/image-viewer.component';
import { CameraPreview } from '@ionic-native/camera-preview/ngx';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptionService } from 'src/app/shared/services/auth-interception.service';
import { PinchZoomModule } from 'ngx-pinch-zoom';
import { GalleryViewerComponent } from 'src/app/components/views-photos/gallery-viewer/gallery-viewer.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { GestureConfig } from '@angular/material';
import { MenuFlashPage } from 'src/app/popover/menu-flash/menu-flash.page';
import { Camera } from '@ionic-native/camera/ngx';
import { NewPicturesComponent } from 'src/app/components/views-photos/new-pictures/new-pictures.component';
import { ImageViewerNewComponent } from 'src/app/components/views-photos/ImageViewerNew/Image-ViewerNew.component';
import { SmilersCredentialsComponent } from 'src/app/components/smilers-credentials/smilers-credentials.component';
import { GabaritPropertyComponent } from 'src/app/components/views-photos/gabarit-property/gabarit-property.component';
import { GabaritToolsComponent } from 'src/app/components/views-photos/gabarit-tools/gabarit-tools.component';
// import { FileTransfer, FileTransferObject } from '@ionic-native/file-transfer/ngx';
// import { FileOpener } from '@ionic-native/file-opener/ngx';
// import { File } from '@ionic-native/file/ngx';

import { PhotosListPage } from './photos-list.page';
import { UploadPhotoComponent } from 'src/app/components/edit-photo/upload-photo/upload-photo.component';

const routes: Routes = [
  {
    path: '',
    component: PhotosListPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SharedModule,
    RouterModule.forChild(routes),
    PinchZoomModule,
    DragDropModule,
    ReactiveFormsModule,
  ],
  declarations: [UploadPhotoComponent , ImageViewerNewComponent,PhotosListPage,
    GalleryViewerComponent,MenuFlashPage,
    NewPicturesComponent,SmilersCredentialsComponent,GabaritPropertyComponent,GabaritToolsComponent],
  providers :[CameraPreview,Camera,
    {
      provide: HTTP_INTERCEPTORS ,
      useClass: AuthInterceptionService,
      multi : true
      
    },{
      provide: HAMMER_GESTURE_CONFIG ,
      useClass: GestureConfig,
    },
    // FileTransferObject,
    // FileTransfer,
    // FileOpener,
    // File
  ],
  entryComponents : [ 
    UploadPhotoComponent , 
    ImageViewerNewComponent,CameraComponent,
    GalleryViewerComponent,ImageViewerComponent,MenuFlashPage,NewPicturesComponent,
    SmilersCredentialsComponent,GabaritPropertyComponent,GabaritToolsComponent]
})
export class PhotosListPageModule {}
