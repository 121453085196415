
import { Component, OnInit, } from '@angular/core';
import { ModalController,NavParams } from '@ionic/angular';
import * as sha1 from 'sha1';
import { ObjetService } from 'src/app/service/objet.service';
import { objet } from 'src/app/model/objet.model';
import { CustomLoadingService } from 'src/app/service/custom-loading.service';
import { Context } from 'src/app/shared/services/context.service';
import * as pdfjsLib from 'pdfjs-dist';
@Component({
  selector: 'app-upload-photo',
  templateUrl: './upload-photo.component.html',
  styleUrls: ['./upload-photo.component.scss'],
})
export class UploadPhotoComponent implements OnInit {
  fileList : File; 
  idUser; 
  ContextFolder; 
  base64textString;
  binaryString: string;
  destName; 
  form ; 
  tabNewImage : objet[]=[]; 
  public newImage; 
  arrayFile: {file : File , test : boolean , image : string} []=[]; 
  imageUrlPrefix;
  showBtn: boolean = false;
  reloadImages: boolean = false;
  ListImageAdded : any = []
  constructor(private navParam: NavParams, public modalController: ModalController , private objetService: ObjetService,
    private customLoadingService: CustomLoadingService,) 
  {  
    this.idUser = navParam.data.idUser;
    this.ContextFolder = navParam.data.ContextFolder;
    this.fileList = navParam.data.fileList;
    }   
 
 async ngOnInit() {
      this.customLoadingService.presentLoading();
      (await this.getListPhoto()).toPromise().then(res=>{
        this.listPhoto=res
        this.onFileSelected().then(()=>{
        })
      })
      this.ListImageAdded = [];
  }
 
  
async createDossierPatient(path)
{
  this.objetService.createDossierPatient(path).subscribe(value=>{
  },error=>{
   });
}

insertObjet(nom, extension, id_patient1, vignette, width,
  height, taille, estidentite, dateCreation, echelle, fichier, lastModif,
  repStockage, syncPath, dateInsertion, auteur, idGabarit , id_patient_orthalis) {
    let obj : objet = new objet();
  obj.nom = nom;
  obj.extension = extension;
  obj.id_patient = id_patient1;
  obj.vignette = vignette;
  obj.width = width;
  obj.height = height;
  obj.taille = taille;
  obj.estidentite = estidentite;
  obj.dateCreation = dateCreation;
  obj.echelle = echelle;
  obj.fichier = fichier;
  obj.lastModif = lastModif;
  obj.repStockage = repStockage;
  obj.syncPath = syncPath;
  obj.dateInsertion = dateInsertion;
  obj.auteur = auteur;
  obj.idGabarit = idGabarit;
  obj.id_patient_orthalis= id_patient_orthalis; 
  obj.pkObjet = 0;
  return  this.objetService.addObjet(obj)
}
async uploadImage(file: File) {
  const base64Data = await this.readFileAsBase64(file);
  const destName = sha1(base64Data);
  const contentType = 'image/jpeg'; // Assuming JPEG for simplicity

  const binaryString = atob(base64Data);
  const arrayBuffer = new ArrayBuffer(binaryString.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  for (let i = 0; i < binaryString.length; i++) {
      uint8Array[i] = binaryString.charCodeAt(i);
  }

  const blob = new Blob([uint8Array], { type: contentType });

  const form = new FormData();
  form.append("file", blob);

  const result = await Promise.all([
      this.objetService.saveImage(destName + ".jpg", this.ContextFolder + this.idUser, form).toPromise(),
      this.insertObjet(destName + ".jpg", "jpg", +this.idUser, base64Data, 0, 0, 0, 0, null, 0,
          destName + ".jpg", "", this.ContextFolder + this.idUser, "", "", "", 0, this.idUser)
          .toPromise().then(res => {
              if (res !== null) {
                  console.log('res', res);
                  this.ListImageAdded.push(res);
                  this.arrayFile.push({ file: file, test: false, image: res.vignette });
                  this.showBtn = true;
              }
              this.tabNewImage.push(res);
          })
  ]);

  this.reloadImages = true;
  this.newImage = result[1];
}

generateRandomString(length: number): string {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  return result;
}



async saveToBasePhoto(blob: any) {
  // const arrayBuffer = await blob.arrayBuffer();
  const destName = 'upload_' + this.generateRandomString(20);
  pdfjsLib.GlobalWorkerOptions.workerSrc = "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.5.207/build/pdf.worker.min.js";
  const pdfDoc = await pdfjsLib.getDocument({ url: URL.createObjectURL(blob) }).promise.then(async (pdf) => {
    const page = await pdf.getPage(1);
    const canvas = document.createElement('canvas');
    const context = canvas.getContext("2d");
    const viewport = page.getViewport({ scale: 1 });
    canvas.width = viewport.width;
    canvas.height = viewport.height;
    const renderContext = {
      canvasContext: canvas.getContext('2d'),
      viewport: viewport,
    };
    await page.render(renderContext).promise.then( async () => {
      const imageDataURL = canvas.toDataURL('image/jpeg', 25);
      const image = imageDataURL.replace("data:image/jpeg;base64,", "");
      await this.insertObjet(
        destName + ".pdf", ".pdf", +localStorage.getItem('idPatient'), image, 141, 200, 0, 0, null, 1, destName + ".pdf", "", Context.folder + localStorage.getItem('idPatient'), "", "", "", 0, localStorage.getItem('idPatient')
      ).toPromise().then(res=>{
        if (res !== null) {
          console.log('res', res);
          this.ListImageAdded.push(res);
          this.arrayFile.push({ file: blob, test: false, image: res.vignette });
          this.showBtn = true;
      }
      this.tabNewImage.push(res);
      });
    });
  });  

  var form = new FormData();
  form.append("file", blob);
  this.objetService
      .saveImage(destName + ".pdf", Context.folder + localStorage.getItem('idPatient'), form)
      .subscribe();
}

async onFileSelected() {
  if (Object.values(this.fileList).length == 0) {
      return this.dismissModal();
  }

  this.createDossierPatient("/" + this.ContextFolder + this.idUser);

  try {
      console.log('this.fileList', this.fileList);
      
      for (const f of Object.values(this.fileList)) {
          await new Promise<void>((resolve, reject) => {
              if (f.type.startsWith('image')) {
                  this.uploadImage(f).then(() => resolve()).catch(error => reject(error));
              } else if (f.type === 'application/pdf') {
                  this.saveToBasePhoto(f).then(() => resolve()).catch(error => reject(error));
              } else {
                  reject(new Error('Unsupported file type'));
              }
          });
      }

      await this.dismissLoading();
  } catch (e) {
      console.error(e);
      this.dismissLoading();
  }
}

readFileAsBase64(file: File): Promise<string> {
  return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
          const readerResult = event.target as FileReader;
          if (readerResult && readerResult.result) {
              const result = readerResult.result as string;
              const base64Data = result.split(',')[1];
              resolve(base64Data);
          } else {
              reject(new Error("Failed to read the file."));
          }
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
  });
}



// async onFileSelected() {
//   if (Object.values(this.fileList).length == 0) {
//       return this.dismissModal();
//   }

//   this.createDossierPatient("/" + this.ContextFolder + this.idUser);

//   try {
//       console.log('this.fileList',this.fileList);
      
//       for (const f of Object.values(this.fileList)) {
//           await new Promise<void>((resolve, reject) => {
//               const reader = new FileReader();
//               reader.onload = async (readerEvt: any) => {
//                   const base64Data = readerEvt.target.result.split(',')[1]; // Get base64 data excluding the data URL prefix

//                   let contentType: string;
//                   let extension: string;

//                   if (f.type.startsWith('image')) {
//                       contentType = 'image/jpeg'; // Assume JPEG for simplicity
//                       extension = 'jpg'; // Assume JPG for simplicity
//                   } else if (f.type === 'application/pdf') {
//                       contentType = 'application/pdf';
//                       extension = 'pdf';
//                   } else {
//                       reject(new Error('Unsupported file type'));
//                       return;
//                   }

//                   const binaryString = atob(base64Data);
//                   const arrayBuffer = new ArrayBuffer(binaryString.length);
//                   const uint8Array = new Uint8Array(arrayBuffer);

//                   for (let i = 0; i < binaryString.length; i++) {
//                       uint8Array[i] = binaryString.charCodeAt(i);
//                   }

//                   const blob = new Blob([uint8Array], { type: contentType });

//                   const destName = sha1(base64Data);
//                   const form = new FormData();
//                   form.append("file", blob);

//                   let result;

//                   if (contentType.startsWith('image')) {
//                       result = await Promise.all([
//                           this.objetService.saveImage(destName + ".jpg", this.ContextFolder + this.idUser, form).toPromise(),
//                           this.insertObjet(destName + ".jpg", "jpg", +this.idUser, base64Data, 0, 0, 0, 0, null, 0,
//                               destName + ".jpg", "", this.ContextFolder + this.idUser, "", "", "", 0, this.idUser)
//                               .toPromise().then(res => {
//                                   if (res !== null) {
//                                       console.log('res', res);
//                                       this.ListImageAdded.push(res);
//                                       this.arrayFile.push({ file: f, test: false, image: res.vignette });
//                                       this.showBtn = true;
//                                   }
//                                   this.tabNewImage.push(res);
//                               })
//                       ]);
//                   } else if (contentType === 'application/pdf') {
//                     result = await Promise.all([
//                       this.objetService.saveImage(destName + ".pdf", this.ContextFolder + this.idUser, form).toPromise(),
//                       this.insertObjet(destName + ".pdf", "pdf", +this.idUser, base64Data, 0, 0, 0, 0, null, 0,
//                           destName + ".pdf", "", this.ContextFolder + this.idUser, "", "", "", 0, this.idUser)
//                           .toPromise().then(res => {
//                               if (res !== null) {
//                                   console.log('res', res);
//                                   this.ListImageAdded.push(res);
//                                   this.arrayFile.push({ file: f, test: false, image: res.vignette });
//                                   this.showBtn = true;
//                               }
//                               this.tabNewImage.push(res);
//                           })
//                   ]);
//                   }

//                   this.reloadImages = true;
//                   this.newImage = result[1];
//                   resolve(); // Resolve the promise to proceed to the next iteration
//               };

//               reader.onerror = (error) => {
//                   reject(error); // Reject the promise if there is an error
//               };
              
//               if (f.type.startsWith('image')) {
//                   reader.readAsDataURL(f); // Read image files as data URL
//               } else if (f.type === 'application/pdf') {
//                   reader.readAsBinaryString(f); // Read PDF files as binary strings
//               }
//           });
//       }

//       await this.dismissLoading(); // Dismiss loading after all files are processed
//   } catch (e) {
//       console.error(e);
//       this.dismissLoading();
//   }
// }


async dismissLoading() {
  await this.customLoadingService.dismissLoading();
}


listPhoto = []; 
async getListPhoto(){
 return this.objetService.listphoto(this.idUser)
}
dismissModal() {
  this.customLoadingService.dismissLoading();
  if(this.reloadImages) {
    this.modalController.dismiss(this.ListImageAdded);
    this.reloadImages = false;
  } else
  this.modalController.dismiss();
}

}