import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Context } from '../shared/services/context.service';
import {Pays} from '../model/Pays.model';

@Injectable({
  providedIn: 'root'
})
export class PayerService {

  constructor(private http: HttpClient) {

  }

  getPayerCorrespondant(idPayer) {
    return this.http.get<Pays>(Context.apiUrl + 'get_pays_by_id/' + idPayer);
  }



  getAllPays() {
    return this.http.get<Pays>(Context.apiUrl + 'get_all_pays');
  }

  //get code pays getPAYS
 /* getPAYS() {
    return this.http.get<pays>(Context.apiUrl + 'Pays',);
  }*/
}
