import { Component, OnInit, OnChanges, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { UserService } from 'src/app/service/user.service';
import { Context, InjectedContext } from 'src/app/shared/services/context.service';
import { ObjetService } from 'src/app/service/objet.service';
import { objet } from 'src/app/model/objet.model';
import { LnkAttributsObjetsService } from 'src/app/service/lnkAttributsObjets.service';
import { AttributService } from 'src/app/service/attribut.Service';

@Component({
  selector: 'app-gabarit-tools',
  templateUrl: './gabarit-tools.component.html',
  styleUrls: ['./gabarit-tools.component.scss'],
})
export class GabaritToolsComponent implements OnInit {


  gabaritOutils = [];
  gabaritO = [];
  idGabaritSelected = [];
  oldIdGabaritSelected = [];
  attributByGabarit = [];
  gabaritOutilCategorie = [];
  gabaritOutilsSelected = {};
  uniqueArray = [];
  gabaritOutilss;
  gabaritOutilsBycateg = [];
  objetSelected: {};
  lnkAttributesObjets = { id_attribut: 0, id_objet: 0, valeur: false, valeur_bool: 0 };
  objetUpdated: objet;
  constructor(
    private userService: UserService,
    private navParams: NavParams,
    private context: Context,
    private modalCtrl: ModalController,
    private objetService: ObjetService,
    private lnkAttributObjetService: LnkAttributsObjetsService,
    private attributService: AttributService,
    private cdRef: ChangeDetectorRef
  ) {
    this.objetSelected = navParams.data.objetSelected;
  }


  ngAfterViewInit() {
  }
  ngOnInit() {
    console.log("this.objetSelected",this.objetSelected);
    
    this.gabaritOutils = InjectedContext.GabaritOutils;
    this.gabaritOutilsSelected = this.gabaritOutils.find(gabarit => gabarit.id_gabarit_outil === this.objetSelected["idGabarit"]);
    if (this.gabaritOutils.indexOf(this.gabaritOutilsSelected) === -1) {
    } else {
      this.idGabaritSelected.push(this.gabaritOutilsSelected["id_gabarit_outil"]);
    }

    for (let i = 0; i < this.gabaritOutils.length; i++) {
      this.gabaritOutilCategorie.push(this.gabaritOutils[i]["categorie"])

    }
    this.uniqueArray = Array.from(new Set(this.gabaritOutilCategorie));
    const filteredArray = this.uniqueArray.filter(item => item !== 'Autres' );

    this.uniqueArray = filteredArray // ['ISFESO', 'Radios', 'Document']
    console.log("this.uniqueArray", this.uniqueArray);
    
    // this.uniqueArray = ["Basdiag FULL", "Basdiag mini"];

    this.gabaritByCategoryIsfeso = this.gabaritOutils.filter(x => x.categorie === "ISFESO");
    this.gabaritByCategoryDoc = this.gabaritOutils.filter(x => x.categorie === "Document");
    // this.gabaritByCategoryAutre = this.gabaritOutils.filter(x => x.categorie === "Autres");
    this.gabaritByCategoryRadio = this.gabaritOutils.filter(x => x.categorie === "Radios");

    console.log("this.gabaritByCategoryIsfeso",this.gabaritByCategoryIsfeso);
    

  }


  closeModal() {
    this.modalCtrl.dismiss();
  }

  setGabaritInList(gabaritOutils) {
    const listGabaritOutils = gabaritOutils.id_gabarit_outil;
    const imageIndexInArray = this.idGabaritSelected.indexOf(listGabaritOutils)
    if (this.idGabaritSelected.length === 0) {
      this.idGabaritSelected.push(listGabaritOutils)
    }
    else {
      this.oldIdGabaritSelected.splice(imageIndexInArray, 1);
      this.oldIdGabaritSelected.push(this.idGabaritSelected[0]);
      this.idGabaritSelected.splice(imageIndexInArray, 1);
      this.idGabaritSelected.push(listGabaritOutils);
    }
    this.objetSelected["idGabarit"] = this.idGabaritSelected[0];
  }
  affectGabaritToObjet() {
    this.updateIdGabaritObjet();
    if (this.gabaritOutils.indexOf(this.gabaritOutilsSelected) === -1) {
      this.selectAttributByGabaritJson();
      this.selectAttributByGabaritJson().subscribe(result => {
        for (let attr of result) {
          this.deleteByObjetAndAttribut(this.objetSelected, attr);
          this.insertInLnkAttributObjet(attr.pk_attribut, attr.valeur, this.objetSelected["pkObjet"]);
        }
      });
    } else {
      this.affectGabaritToObjet_GabaritFound();
    }
    this.closeModal();
  }
  gabaritByCategoryIsfeso = [];
  gabaritByCategoryDoc = [];
  gabaritByCategoryAutre = [];
  gabaritByCategoryRadio = [];
  loadGabaritByCategory(gabarit) {
    this.gabaritOutilsBycateg = this.gabaritOutils.filter(x => x.categorie === gabarit);
  }

  affectGabaritToObjet_GabaritFound() {
    this.updateIdGabaritObjet();
    if (this.gabaritOutilsSelected["substitue"] === true) {
      this.deleteByObjet(this.objetSelected);
    }
    if (this.gabaritOutilsSelected["substitue"] === null || this.gabaritOutilsSelected["substitue"] === 'F') {
      this.selectAttributByGabaritJson();
      this.selectAttributByGabaritJson().subscribe(result => {
        for (let attr of result) {
          this.deleteByObjetAndAttribut(this.objetSelected, attr);
          this.insertInLnkAttributObjet(attr.pk_attribut, attr.valeur, this.objetSelected["pkObjet"]);
        }
      });
    }
  }

  updateIdGabaritObjet() {
    this.objetService.updateIdGabarit(this.objetSelected).subscribe(result => {
    }, error => console.error(error));
  }

  insertInLnkAttributObjet(pkAttribut, valeur, pkObjet) {

    this.lnkAttributesObjets["id_attribut"] = pkAttribut;
    this.lnkAttributesObjets["id_objet"] = pkObjet;
    this.lnkAttributesObjets["valeur"] = valeur;
    this.lnkAttributObjetService.insertInLnkAttributObjet(this.lnkAttributesObjets).subscribe(result => {
    }, error => console.error(error));
  }



  deleteByObjetAndAttribut(obj, att) {
    this.lnkAttributObjetService.deleteByObjetAttribut(obj.pkObjet, att.pk_attribut).subscribe(result => {
    });
  }

  deleteByObjet(obj) {
    this.lnkAttributObjetService.deleteByObjet(obj.pkObjet).subscribe(result => {
    });
  }

  selectAttributByGabaritJson() {
    return this.attributService.selectAttributByGabaritJson(this.idGabaritSelected)
  }


}
