import { SuivicontactComponent } from 'src/app/components/fiche-patient/suivicontact/suivicontact.component';
import {Component, NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuardService} from './auth/services/auth-guard.service';
import {NewPicturesComponent} from './components/views-photos/new-pictures/new-pictures.component';
import {AdminAuthGuardService} from './auth/services/admin-auth-guard.service';
import {ContradictoryAuthGuardService} from './auth/services/contradictory-auth-guard.service';
import {DirectAccessGuardService} from './auth/services/direct-access-guard.service';
import {RedirectComponent} from './components/redirect/redirect.component';
import { DepensesComponent } from './components/depenses/depenses.component';
import { FacturedepensesComponent } from './components/facturedepenses/facturedepenses.component';
import { ModalajoutcontactComponent } from './pages/bascontact/menu/modalajoutcontact/modalajoutcontact.component';
import { AddgroupementacteComponent } from './components/fiche-patient/addgroupementacte/addgroupementacte.component';
import { AddGrpActComponent } from './components/add-grp-act/add-grp-act.component';
import { GrpActListComponent } from './components/grp-act-list/grp-act-list.component';
import { CalculatriceComponent } from './components/fiche-patient/casclinique/calculatrice/calculatrice.component';

const routes: Routes = [
    // {
    //     path :'grp-act-liste' , component:GrpActListComponent
    // },
    {
        path :'ajouter' , component:ModalajoutcontactComponent
    },
    // {
    //     path :'calcul' , component:CalculatriceComponent
    // },
     // {
    //     path :'calcul' , component:CalculatriceComponent
    // },
    // {
    //     path :'addGroupeActe' , component:AddgroupementacteComponent
    // },


    
    {
        path: '',
        redirectTo: 'home', //home
        pathMatch: 'full',
        canActivate: [AuthGuardService],
        
    }
    , 
    // {
    //     path: 'ActesCliniqque', loadChildren: './pages/tab-asm/tab-asm.module#TabAsmPageModule',
    //     canActivate: [AuthGuardService],
    //     data: {
    //         name: ['ROLE_ADMIN','ROLE_RH','ROLE_PRATICIEN','ROLE_ASSISTANTES','ROLE_FINANCIERS'],
            
    //     }
    // },
    // {
    //     path: 'administration', loadChildren: './pages/administration/administration.module#AdministrationPageModule',
    //     canActivate: [AuthGuardService],
    //     data: {
    //         name: ['ROLE_RH','ROLE_ADMIN']
    //     }
    // },
    // {
    //     path: 'appointments',
    //     loadChildren: './pages/appointments/appointments.module#AppointmentsPageModule',
    //     canActivate: [AuthGuardService],
    //     data: {
    //         name: ['ROLE_ADMIN','ROLE_RH','ROLE_PRATICIEN','ROLE_ASSISTANTES','ROLE_FINANCIERS','ROLE_USER','ROLE_SECRETAIRES'],
    //     }
    // },
    {
        path: 'photos-list',
        loadChildren: './pages/photos-list/photos-list.module#PhotosListPageModule',
        canActivate: [AuthGuardService],
        data: {
            name: ['ROLE_ADMIN','ROLE_RH','ROLE_PRATICIEN','ROLE_ASSISTANTES','ROLE_FINANCIERS','ROLE_USER','ROLE_SECRETAIRES'],

        }
    },
    // {
    //     path: 'statistics', loadChildren: './pages/statistics/statistics.module#StatisticsPageModule',
    //     canActivate: [AuthGuardService],
    //     data: {
    //         name: ['ROLE_ADMIN','ROLE_RH','ROLE_FINANCIERS'],

    //     }
    // },
    // {
    //     path: 'work-schedule',
    //     loadChildren: './pages/work-schedule/work-schedule.module#WorkSchedulePageModule',
    //     canActivate: [AuthGuardService, DirectAccessGuardService]
    // },
    // {
    //     path: 'real-work-schedule',
    //     loadChildren: './pages/real-work-schedule/real-work-schedule.module#RealWorkSchedulePageModule',
    //     canActivate: [AuthGuardService, DirectAccessGuardService]
    // },
    // {
    //     path: 'pointing-work',
    //     loadChildren: './pages/pointing-work/pointing-work.module#PointingWorkPageModule',
    //     canActivate: [AuthGuardService, DirectAccessGuardService]
    // },
    {
        path: 'viewer',
        loadChildren: './pages/viewer/viewer.module#ViewerPageModule',
        canActivate: [AuthGuardService, DirectAccessGuardService]
    },


    {
        path: 'home', loadChildren: './pages/accueil/accueil.module#AccueilPageModule',
        canActivate: [AuthGuardService]
    },


    {
        path: 'edit-photos-list',
        loadChildren: './pages/edit-photos-list/edit-photos-list.module#EditPhotosListPageModule',
        canActivate: [AuthGuardService, DirectAccessGuardService]
    },
    {
        path: 'baspremier', loadChildren: './pages/baspremier/baspremier.module#BaspremierModule',
        canActivate: [AuthGuardService],
        data: {
            name: ['ROLE_ADMIN','ROLE_RH','ROLE_PRATICIEN','ROLE_ASSISTANTES','ROLE_USER','ROLE_SECRETAIRES'],

        }
    },
    {
        path: 'login', loadChildren: './auth/login/login.module#LoginPageModule',
        canActivate: [ContradictoryAuthGuardService]
    },
    {
        path: 'picturesPatient', component: NewPicturesComponent,
        canActivate: [AuthGuardService, DirectAccessGuardService]
    },
    // {
    //     path: 'q1cs',
    //     loadChildren: './pages/q1cs/q1cs.module#Q1csModule',
    //     canActivate: [AuthGuardService, DirectAccessGuardService],
    //     data: {
    //         name: ['ROLE_ADMIN','ROLE_RH','ROLE_PRATICIEN','ROLE_ASSISTANTES','ROLE_FINANCIERS','ROLE_SECRETAIRES'],

    //     }
    // },


    // {
    //     path: 'accueil',
    //     loadChildren: './pages/q1cs-accueil/accueil.module#AccueilPageModule',
    //     canActivate: [AuthGuardService]
    // },
    {
        path: 'redir', component: RedirectComponent,
        canActivate: [AuthGuardService]
    }, 
    // {
    //     path: 'accueil',
    //     loadChildren: './pages/q1cs-accueil/accueil.module#AccueilPageModule',
    //     canActivate: [AuthGuardService]
    // },
    //   { path: 'finance', loadChildren: './finance/finance.module#FinancePageModule' },
    // {
    //     path: 'finance',
    //     loadChildren: './pages/finance/finance.module#FinancePageModule',
    //     canActivate: [AuthGuardService]
    // },
    // { path: 'tab1', loadChildren: './pages/finance/tab1/tab1.module#Tab1PageModule' },
    // { path: 'tab2', loadChildren: './pages/finance/tab2/tab2.module#Tab2PageModule' },
    // { path: 'tab3', loadChildren: './pages/finance/tab3/tab3.module#Tab3PageModule' },
    // { path: 'tab4', loadChildren: './pages/finance/tab4/tab4.module#Tab4PageModule' },
    // { path: 'tabs', loadChildren: './pages/finance/tabs/tabs.module#Tab4PageModule' },
    // {
    //     path: 'list-financieres',
    //     loadChildren: './pages/finance/listfinancieres/listfinancieres.module#ListFinancieresPageModule'
    // },
    // { path: 'listetypecorrespondant', loadChildren: './pages/bascontact/menu/listetypecorrespondant/listetypecorrespondant.module#ListetypecorrespondantPageModule' },
    // { path: 'delatetypecorrespondant', loadChildren: './pages/bascontact/menu/delatetypecorrespondant/delatetypecorrespondant.module#DelatetypecorrespondantPageModule' },
    //   { path: 'ajouttypecorrespondant', loadChildren: './pages/bascontact/menu/ajouttypecorrespondant/ajouttypecorrespondant.module#AjouttypecorrespondantPageModule' },

    // {path: 'ai', loadChildren: './pages/ai/ai.module#AiPageModule'},
    // {path: 'f', loadChildren: './pages/finance/f/f.module#FPageModule'},
    // {path: 'd', loadChildren: './pages/finance/d/d.module#DPageModule'},
    // {path: 'pourcentage', loadChildren: './pages/finance/pourcentage/pourcentage.module#PourcentagePageModule'},
    // {path: 'labo', loadChildren: './pages/finance/labo/labo.module#LaboPageModule'},
    // {path: 'impression', loadChildren: './pages/bascontact/menu/impression/impression.module#ImpressionPageModule'},
    // // {path: 'baselettre', loadChildren: './pages/baselettre/baselettre.module#BaselettrePageModule'},

    // {path: 'addcorr', loadChildren: './pages/bascontact/menu/addcorr/addcorr.module#AddcorrPageModule'},
    // {path: 'searchcorr', loadChildren: './pages/bascontact/menu/searchcorr/searchcorr.module#SearchcorrPageModule'},
    // {
    //     path: 'creeretiquette',
    //     loadChildren: './pages/bascontact/menu/creeretiquette/creeretiquette.module#CreeretiquettePageModule'
    // },
    // {path: 'exportexcel', loadChildren: './pages/bascontact/menu/exportexcel/exportexcel.module#ExportexcelPageModule'},
    // {path: 'emailing', loadChildren: './pages/bascontact/menu/emailing/emailing.module#EmailingPageModule'},

    // {
    //     path: 'addcorr', loadChildren: './pages/bascontact/menu/addcorr/addcorr.module#AddcorrPageModule'
    // },
    // {
    //     path: 'modal-page', loadChildren: './pages/bascontact/menu/modal-page/modal-page.module#ModalPagePageModule'
    // },
    // {
    //     path: 'information-pers',
    //     loadChildren: './pages/bascontact/menu/information-pers/information-pers.module#InformationPersPageModule'
    // },
    // {
    //     path: 'list-patient',
    //     loadChildren: './pages/bascontact/menu/list-patient/list-patient.module#ListPatientPageModule'
    // },
    // {
    //     path: 'statistique',
    //     loadChildren: './pages/bascontact/menu/statistique/statistique.module#StatistiquePageModule'
    // },
    

    // {
    //     path: 'courrier', loadChildren: './pages/baselettre/courrier/courrier.module#CourrierPageModule'
    // },
    // {
    //     path: 'praticien', loadChildren: './pages/baselettre/praticien/praticien.module#PraticienPageModule'
    // },
    // {
    //     path: 'destinataire', loadChildren: './pages/baselettre/destinataire/destinataire.module#DestinatairePageModule'
    // },
    // {
    //     path: 'pagemodalslider',
    //     loadChildren: './pages/baselettre/pagemodalslider/pagemodalslider.module#PagemodalsliderPageModule'

    // },
   
    
    // {
    //     path: 'modalstatistique', loadChildren: './pages/bascontact/menu/modalstatistique/modalstatistique.module#ModalstatistiquePageModule'
    // },
    // { path: 'pagemodal', loadChildren: './pages/baselettre/pagemodal/pagemodal.module#PagemodalPageModule' },
    // { 
    //     path: 'popoverdest', loadChildren: './pages/baselettre/popoverdest/popoverdest.module#PopoverdestPageModule'
    // },
    // { path: 'mailing', loadChildren: './pages/bascontact/mailing/mailing.module#MailingPageModule' },

    // {
    //     path: 'modalstatistique',
    //     loadChildren: './pages/bascontact/menu/modalstatistique/modalstatistique.module#ModalstatistiquePageModule'
    // },
    // {path: 'pagemodal', loadChildren: './pages/baselettre/pagemodal/pagemodal.module#PagemodalPageModule'},
    // {
    //     path: 'popoverdest', loadChildren: './pages/baselettre/popoverdest/popoverdest.module#PopoverdestPageModule'
    // },
//     {
//         path: 'mailing', loadChildren: './pages/bascontact/mailing/mailing.module#MailingPageModule'

//     },
//     {
//         path: 'googleavis', loadChildren: './pages/bascontact/googleavis/googleavis.module#GoogleavisPageModule'

//  },
//  {
//       path: 'patientliee', loadChildren: './pages/bascontact/menu/patientliee/patientliee.module#PatientlieePageModule'
//  },
//  {
//       path: 'mail', loadChildren: './pages/bascontact/mail/mail.module#MailPageModule' 
//     },

//  { 
//      path: 'searchcorrespondant', loadChildren: './pages/bascontact/menu/searchcorrespondant/searchcorrespondant.module#SearchcorrespondantPageModule' 
//     },


// {
//     path: 'ajoutercorrespondant', loadChildren: './pages/bascontact/menu/ajoutercorrespondant/ajoutercorrespondant.module#AjoutercorrespondantPageModule' 
// },
// {
//      path: 'createcorrespondant', loadChildren: './pages/bascontact/menu/createcorrespondant/createcorrespondant.module#CreatecorrespondantPageModule'
//      },

    


//     {
//         path: 'modalsearchcorr',
//         loadChildren: './pages/bascontact/menu/modalsearchcorr/modalsearchcorr.module#ModalsearchcorrPageModule'
//     },
    // {path: 'patient', loadChildren: './pages/bascontact/menu/patient/patient.module#PatientPageModule'},
    // {path: 'equipe', loadChildren: './pages/bascontact/menu/equipe/equipe.module#EquipePageModule'},
    // {
    //     path: 'correspondant',
    //     loadChildren: './pages/bascontact/menu/correspondant/correspondant.module#CorrespondantPageModule'
    // },
    // {
    //     path: 'bascontact',
    //     loadChildren: './pages/bascontact/bascontact.module#BascontactPageModule',
    //     canActivate: [AuthGuardService],
    //     data: {
    //         name: ['ROLE_ADMIN','ROLE_RH','ROLE_PRATICIEN','ROLE_ASSISTANTES','ROLE_FINANCIERS','ROLE_SECRETAIRES'],

    //     }
    // },
    { path: 'preanalyse', loadChildren: './pages/basdiag/preanalyse/preanalyse.module#PreanalysePageModule',
        canActivate: [AuthGuardService],
        
    },
    
    { path: 'masquefacial', loadChildren: './pages/basdiag/masquefacial/masquefacial.module#MasquefacialPageModule',
        canActivate: [AuthGuardService],
        
    },
    { path: 'facesourire', loadChildren: './pages/basdiag/facesourire/facesourire.module#FacesourirePageModule',
        canActivate: [AuthGuardService],
         
    },
    { path: 'occlusal', loadChildren: './pages/basdiag/occlusal/occlusal.module#OcclusalPageModule',
        canActivate: [AuthGuardService],
        
    },
    { path: 'fonctionnel', loadChildren: './pages/basdiag/fonctionnel/fonctionnel.module#FonctionnelPageModule',
        canActivate: [AuthGuardService],
        
    },
    { path: 'arcades', loadChildren: './pages/basdiag/arcades/arcades.module#ArcadesPageModule',
        canActivate: [AuthGuardService],
        
    },
    { path: 'sourire', loadChildren: './pages/basdiag/sourire/sourire.module#SourirePageModule',
        canActivate: [AuthGuardService],
        
    },
    { path: 'profil', loadChildren: './pages/basdiag/profil/profil.module#ProfilPageModule',
        canActivate: [AuthGuardService],
       
    },
    { path: 'radio', loadChildren: './pages/basdiag/radio/radio.module#RadioPageModule',
        canActivate: [AuthGuardService],
        
    },
    { path: 'pano', loadChildren: './pages/basdiag/pano/pano.module#PanoPageModule',
        canActivate: [AuthGuardService],
        
    },
    { path: 'basdiagadultehome', loadChildren: './pages/basdiag/basdiagadultehome/basdiagadultehome.module#BasdiagadultehomePageModule',
        canActivate: [AuthGuardService],
         
    },
    // { path: 'modalajoutcontact', loadChildren: './pages/bascontact/menu/modalajoutcontact/modalajoutcontact.module#ModalajoutcontactPageModule' },

    // { path: 'controle', loadChildren: './pages/basdiag/dents/controle/controle.module#ControlePageModule' },
    // { path: 'agenesie', loadChildren: './pages/basdiag/dents/agenesie/agenesie.module#AgenesiePageModule' },
    // { path: 'dentsincluses', loadChildren: './pages/basdiag/dents/dentsincluses/dentsincluses.module#DentsinclusesPageModule' },
    // { path: 'dentssumum', loadChildren: './pages/basdiag/dents/dentssumum/dentssumum.module#DentssumumPageModule' },
    // { path: 'infosplus', loadChildren: './pages/bascontact/menu/infosplus/infosplus.module#InfosplusPageModule' },
    // { path: 'testformulaire', loadChildren: './pages/bascontact/menu/testformulaire/testformulaire.module#TestformulairePageModule' },
    // { 
    //     path: 'ajoutcontactcorrespondant', loadChildren: './pages/bascontact/menu/ajoutcontactcorrespondant/ajoutcontactcorrespondant.module#AjoutcontactcorrespondantPageModule' 
    // },
    // { path: 'lettre', loadChildren: './pages/lettre/lettre.module#LettrePageModule' },


    {
        path: 'basdiag', loadChildren: './pages/basdiag/basdiag.module#BasdiagPageModule',
        canActivate: [AuthGuardService],
        data: {
            name: ['ROLE_ADMIN','ROLE_PRATICIEN','ROLE_ASSISTANTES','ROLE_FINANCIERS'],

        }
    },
//     { path: 'basesite', 
//     loadChildren: './pages/basesite/basesite.module#BasesitePageModule',
//     canActivate: [AuthGuardService]
//  },
 
   
    // {
    //     path: 'affect-fauteuil-modal',
    //     loadChildren: './pages/affect-fauteuil-modal/affect-fauteuil-modal.module#AffectFauteuilModalPageModule'
    // },
    

    // { path: 'ajoutdepenses', loadChildren: './pages/ajoutdepenses/ajoutdepenses.module#AjoutdepensesPageModule' },
    // { path: 'tour', loadChildren: './components/fiche-patient/casclinique/tour/tour.module#TourPageModule' },
    // { path: 'gout', loadChildren: './components/fiche-patient/casclinique/gout/gout.module#GoutPageModule' },
    // { path: 'vug', loadChildren: './components/fiche-patient/casclinique/vug/vug.module#VugPageModule' },
    // { path: 'donneeg', loadChildren: './components/fiche-patient/casclinique/donneeg/donneeg.module#DonneegPageModule' },
    // { path: 'chgt', loadChildren: './components/fiche-patient/casclinique/chgt/chgt.module#ChgtPageModule' },
    // { path: 'accelerateur', loadChildren: './components/fiche-patient/casclinique/accelerateur/accelerateur.module#AccelerateurPageModule' },
    // { path: 'timcl', loadChildren: './components/fiche-patient/casclinique/timcl/timcl.module#TimclPageModule' },
    // { path: 'timn', loadChildren: './components/fiche-patient/casclinique/timn/timn.module#TimnPageModule' },
    // { path: 'timd', loadChildren: './components/fiche-patient/casclinique/timd/timd.module#TimdPageModule' },
    // { path: 'timg', loadChildren: './components/fiche-patient/casclinique/timg/timg.module#TimgPageModule' },
    // { path: 'blanchiment', loadChildren: './components/fiche-patient/casclinique/blanchiment/blanchiment.module#BlanchimentPageModule' },
    // { path: 'achat', loadChildren: './components/fiche-patient/casclinique/achat/achat.module#AchatPageModule' },
    // { path: 'diapo', loadChildren: './components/fiche-patient/casclinique/diapo/diapo.module#DiapoPageModule' },
    // { path: 'comment', loadChildren: './components/fiche-patient/casclinique/comment/comment.module#CommentPageModule' },
  
    // {path: 'mailing', loadChildren: './pages/bascontact/mailing/mailing.module#MailingPageModule'},
    // {path: 'googleavis', loadChildren: './pages/bascontact/googleavis/googleavis.module#GoogleavisPageModule'},
    // { path: 'affect-fauteuil-delete', loadChildren: './pages/affect-fauteuil-delete/affect-fauteuil-delete.module#AffectFauteuilDeletePageModule' },
    // { path: 'suivi', loadChildren: './pages/suivi/suivi.module#SuiviPageModule',
    // canActivate: [AuthGuardService],
    // data: {
    //     name: ['ROLE_ADMIN'],

    // }
    // },

    // { path: 'fiche-patient', loadChildren: './pages/fiche-patient/fiche-patient.module#FichePatientPageModule',
    // canActivate: [AuthGuardService],
    // data: {
    //     name: ['ROLE_ADMIN','ROLE_RH','ROLE_PRATICIEN','ROLE_ASSISTANTES','ROLE_FINANCIERS','ROLE_USER','ROLE_SECRETAIRES'],

    // }
    // },
    // { path: 'ajouterrdv', loadChildren: './components/fiche-patient/casclinique/Souscasclinique/ajouterrdv/ajouterrdv.module#AjouterrdvPageModule' },


    // { path: 'avisgoogle', loadChildren: './pages/fiche-patient/avisgoogle/avisgoogle.module#AvisgooglePageModule',
    // canActivate: [AuthGuardService],
    // data: {
    //     name: ['ROLE_ADMIN','ROLE_RH','ROLE_PRATICIEN','ROLE_ASSISTANTES','ROLE_FINANCIERS','ROLE_USER','ROLE_SECRETAIRES'],

    // } },
    // { 
    // path: 'suivi-contact', loadChildren: './pages/fiche-patient/Modals/suivi-contact/suivi-contact.module#SuiviContactPageModule',
    // canActivate: [AuthGuardService],
    // data: {
    //     name: ['ROLE_ADMIN','ROLE_RH','ROLE_PRATICIEN','ROLE_ASSISTANTES','ROLE_FINANCIERS','ROLE_USER','ROLE_SECRETAIRES'],

    // }
    // },
    // { path: 'contact', loadChildren: './pages/fiche-patient/Modals/contact/contact.module#ContactPageModule' },
    // { 
    //     path: 'bas3cx', loadChildren: './pages/fiche-patient/Modals/suivi-contact/suivi-contact.module#SuiviContactPageModule',
    //     canActivate: [AuthGuardService],
    //     data: {
    //         name: ['ROLE_ADMIN','ROLE_RH','ROLE_PRATICIEN','ROLE_ASSISTANTES','ROLE_FINANCIERS','ROLE_USER','ROLE_SECRETAIRES'],
    
    //     }
    // },

    // { path: 'add-contact-modal', loadChildren: './pages/add-contact-modal/add-contact-modal.module#AddContactModalPageModule' },
    { path: 'resultat', loadChildren: './pages/basdiag/resultat/resultat.module#ResultatPageModule',
        canActivate: [AuthGuardService] },
    { path: 'basdiag-mini', loadChildren: './pages/basdiag-mini/basdiag-mini.module#BasdiagMiniPageModule',
        canActivate: [AuthGuardService] },
    { path: 'preanalyse-mini', loadChildren: './pages/basdiag-mini/preanalyse-mini/preanalyse-mini.module#PreanalyseMiniPageModule',
        canActivate: [AuthGuardService] },
    { path: 'occlusal-mini', loadChildren: './pages/basdiag-mini/occlusal-mini/occlusal-mini.module#OcclusalMiniPageModule',
        canActivate: [AuthGuardService] },
    { path: 'arcades-mini', loadChildren: './pages/basdiag-mini/arcades-mini/arcades-mini.module#ArcadesMiniPageModule',
        canActivate: [AuthGuardService] },
    { path: 'radio-mini', loadChildren: './pages/basdiag-mini/radio-mini/radio-mini.module#RadioMiniPageModule',
        canActivate: [AuthGuardService] },
    { path: 'resultat-mini', loadChildren: './pages/basdiag-mini/resultat-mini/resultat-mini.module#ResultatMiniPageModule',
        canActivate: [AuthGuardService] },
    { path: 'parametrage', loadChildren: './pages/basdiag-mini/parametrage/parametrage.module#ParametragePageModule',
        canActivate: [AuthGuardService] },

    { path: 'basdiag-enfant-mini', loadChildren: './pages/basdiagEnfant-mini/basdiag-enfant-mini/basdiag-enfant-mini.module#BasdiagEnfantMiniPageModule',
        canActivate: [AuthGuardService] },
    { path: 'arcades-enfant-mini', loadChildren: './pages/basdiagEnfant-mini/arcades-mini/arcades-enfant-mini/arcades-enfant-mini.module#ArcadesEnfantMiniPageModule',
        canActivate: [AuthGuardService] },
    { path: 'occlusal-enfant-mini', loadChildren: './pages/basdiagEnfant-mini/occlusal-mini/occlusal-enfant-mini/occlusal-enfant-mini.module#OcclusalEnfantMiniPageModule',
        canActivate: [AuthGuardService] },
    { path: 'radio-enfant-mini', loadChildren: './pages/basdiagEnfant-mini/radio-mini/radio-enfant-mini/radio-enfant-mini.module#RadioEnfantMiniPageModule',
        canActivate: [AuthGuardService] },
    { path: 'resultat-enfant-mini', loadChildren: './pages/basdiagEnfant-mini/resultat-enfant-mini/resultat-enfant-mini.module#ResultatEnfantMiniPageModule',
        canActivate: [AuthGuardService] },

    { path: 'resultat-enfant', loadChildren: './pages/basdiagEnfant-mini/resultat-enfant/resultat-enfant.module#ResultatEnfantPageModule' },

    { path: 'liste-patients', loadChildren: './pages/liste-patients/liste-patients.module#ListePatientsPageModule' },

    { path: 'cephalometrie', loadChildren: './pages/cephalometrie/cephalometrie.module#CephalometriePageModule' },

    { path: 'recherche-patient', loadChildren: './pages/recherche-patient/recherche-patient.module#RecherchePatientPageModule' },

    { path: 'choix-bas-diag-mini', loadChildren: './pages/choix-bas-diag-mini/choix-bas-diag-mini.module#ChoixBasDiagMiniPageModule', canActivate: [AuthGuardService] },
    { path: 'choix-bas-diag', loadChildren: './pages/choix-bas-diag/choix-bas-diag.module#ChoixBasDiagPageModule' },
    { path: 'parametrage-basdiag', loadChildren: './pages/basdiag/parametrage-basdiag/parametrage-basdiag.module#ParametrageBasdiagPageModule' },

    { path: 'basdiag-enfant', loadChildren: './pages/basdiag-enfant/basdiag-enfant.module#BasdiagEnfantPageModule' },
    { path: 'masquefacial-enfant', loadChildren: './pages/basdiag-enfant/masquefacial-enfant/masquefacial-enfant.module#MasquefacialEnfantPageModule' },
    { path: 'facesourire-enfant', loadChildren: './pages/basdiag-enfant/facesourire-enfant/facesourire-enfant.module#FacesourireEnfantPageModule' },
    { path: 'occlusal-enfant', loadChildren: './pages/basdiag-enfant/occlusal-enfant/occlusal-enfant.module#OcclusalEnfantPageModule' },
    { path: 'fonctionnel-enfant', loadChildren: './pages/basdiag-enfant/fonctionnel-enfant/fonctionnel-enfant.module#FonctionnelEnfantPageModule' },
    { path: 'arcades-enfant', loadChildren: './pages/basdiag-enfant/arcades-enfant/arcades-enfant.module#ArcadesEnfantPageModule' },
    { path: 'sourire-enfant', loadChildren: './pages/basdiag-enfant/sourire-enfant/sourire-enfant.module#SourireEnfantPageModule' },
    { path: 'profil-enfant', loadChildren: './pages/basdiag-enfant/profil-enfant/profil-enfant.module#ProfilEnfantPageModule' },
    { path: 'radio-enfant', loadChildren: './pages/basdiag-enfant/radio-enfant/radio-enfant.module#RadioEnfantPageModule' },
    { path: 'resultat-enfant-full', loadChildren: './pages/basdiag-enfant/resultat-enfant/resultat-enfant.module#ResultatEnfantPageModule' },
//   { path: 'lettre', loadChildren: './pages/lettre/lettre.module#LettrePageModule' },
//   { path: 'basediapo', loadChildren: './pages/basediapo/basediapo.module#BasediapoPageModule' },
  
//   { path: 'base-rdv', loadChildren: './pages/base-rdv/base-rdv.module#BaseRdvPageModule',
//   canActivate: [AuthGuardService],
//   data: {
//       name: ['ROLE_ADMIN','ROLE_RH','ROLE_PRATICIEN','ROLE_ASSISTANTES','ROLE_FINANCIERS','ROLE_USER','ROLE_SECRETAIRES'],

//   } },






  {
    path: '**',
    loadChildren: './pages/not-found/not-found.module#NotFoundPageModule',
    canActivate: [AuthGuardService]
    },


    /*   { path: 'baselettre', loadChildren: './baselettre/baselettre.module#BaselettrePageModule' },
     */


];

// branch dev test
@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
