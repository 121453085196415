import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import { Context } from '../shared/services/context.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SmilersService {
  constructor(
    private http: HttpClient,
    public platform: Platform,
    private context: Context
  ) { }

  private apiUrl = 'https://smilers.biotech-dental.com/api/external/treatment/new';

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
      'Access-Control-Allow-Origin': '*',
    }),
    withCredentials: true,
  };

  SendNewPatient(payload: any): Observable<any> {
    // return this.http.post(this.apiUrl, payload, this.httpOptions);
    return this.http.post(Context.apiUrl + `smilers/newTraitement`, payload);
  }

  addPatientToSmilers(id, smilersPics) {
    return this.http.post(Context.apiUrl + 'smilers/addNewPatient/', smilersPics, this.httpOptions);
  }

  insertSmilerSuivi(smiler) {
    return this.http.post(Context.apiUrl + 'SmilerSuivi/insert', smiler, this.httpOptions);
  }

  getInfoSmilerbypat(idPatient) {
    return this.http.get(Context.apiUrl + 'InfoSmilersSuivilistbypat/' + idPatient, this.httpOptions);
  }

  getAccelerateur(id) {
    return this.http.get(Context.apiUrl + 'InfoSmilersSuivi/' + id, this.httpOptions);
  }
}
