import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => {}//console.log(err)
  );
var hours = 10; // to clear the localStorage after 10 hour
var now : any = new Date().getTime();
console.log("now", now);

var setupTime : any = localStorage.getItem('setupTime');
if (setupTime == null) {
localStorage.setItem('setupTime', now)
} else {
if(now-setupTime > hours*60*60*1000) {
  localStorage.clear();
  window.location.reload();
  localStorage.setItem('setupTime', now);
}
}