import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-popoverdestinataire',
  templateUrl: './popoverdestinataire.component.html',
  styleUrls: ['./popoverdestinataire.component.scss'],
})
export class PopoverdestinataireComponent implements OnInit {
  destinataire : any;

  constructor(public popoverController: PopoverController) { }

  ngOnInit() {}

  // CREATE A JSON ARRAY.
  employees: any [] = [
    { 'id': '001', 'name': 'Alpha', 'joinDate': '05/17/2015', 'age': 37 },
    { 'id': '002', 'name': 'Bravo', 'joinDate': '03/25/2016', 'age': 27 },
    { 'id': '003', 'name': 'Charlie', 'joinDate': '09/11/2015', 'age': 29 },
    { 'id': '004', 'name': 'Delta', 'joinDate': '01/07/2016', 'age': 19 },
    { 'id': '005', 'name': 'Echo', 'joinDate': '03/09/2014', 'age': 32 }
  ];
 public selectedName:any;


  close(){
    this.popoverController.dismiss();
  }
  public highlightRow(emp) {
    this.selectedName = emp.name;
  }

}
