
export class SmilersData {
	prenom:string;
	nom:string;
	titre: string;
	dateNaissance:string;
	PIC1: Array<string>;
	PIC2: Array<string>;
	PIC3: Array<string>;
	PIC4: Array<string>;
	PIC5: Array<string>;
	PIC6: Array<string>;
	PIC7: Array<string>;
	PIC8: Array<string>;
	PIC9: Array<string>;
	PIC10: Array<string>;
}
export enum SmilersPicturesId {
    frontalViewSmile = 58,
    profile = 38,
	occlusionViewFront = 45,
	occlusionViewLeft = 49,
    occlusionViewRight = 50,
	occlusionViewMaxilla = 40,
    occlusionViewMandibula = 41,
	panoramic = 36,
	surplomb = 57,
	innocclusion = 67,
	teleRadio = 34,
	facerepos = 37,
	sourireface = 51,
	Profilsourire = 42,
  }