import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { FauteuilDefault } from '../model/fauteuilDefault.model';
import { Context } from '../shared/services/context.service';
import { Fauteuil } from '../model/Fauteuil.model';
import { FauteuilAffectation } from '../model/FauteuilAffectation.model';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
    providedIn: 'root'
  })
export class FauteuilDefaultService {
    gabarit: any;
    constructor(
        private http: HttpClient,
        public platform: Platform,
        private context: Context,
    ) { }

    private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

    geetFauteuilDefault(idPersonne) {
        return this.http.get<FauteuilDefault[]>(Context.apiUrl + 'FauteuilDefault' + idPersonne);
    }
    getFauteuil() {
        return this.http.get<Fauteuil[]>(Context.apiUrl + 'FauteuilsWeb');
    }
    getAffecations(datedebut, datefin) {
        return this.http.get<FauteuilAffectation[]>(Context.apiUrl + 'AffectationBetweenWeb/' + datedebut + '&' + datefin);
    }
    AddAffectations(affectation) {
        console.log("affectation",affectation);
        return this.http.post(Context.apiUrl + 'AddAffectation', affectation);
    }
    DeleteAffectations(id) {
        console.log("id",id);
        return this.http.delete(Context.apiUrl + 'DeleteAffectation/'+ id);
    }
    
    
}