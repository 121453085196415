import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptionService implements HttpInterceptor {

  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if ( req.url.includes('auth')|| req.url.includes('forgottenPasswordMail') || 
         req.url.includes('resetPassword')) {
      return next.handle(req);

    }
    if(req.url.includes('smilers.biotech-dental')) {
      const clonedRequest = req.clone({
        headers: new HttpHeaders({
           'apiKey':  "o4gob7KhTX1IG07P-tQrU6KxLupDo6GsGHU1yAs5wUogwhMoAQs_P4l_bNAef7iaWBhlWQ1k0FnmA5au5UIyRRa5hydioGXGm9nW8XcODUWopfI0fOYsSfFxSy59vJsyR9xkkYFMtdsRwCyn7-SFPvdsBwq8x58QNUSr3dWo4QE",
           'Content-Type': 'application/json',
           'Access-Control-Allow-Origin': '*',
           'Access-Control-Allow-Credentials': 'true',
           'Access-Control-Allow-Headers': 'Content-Type',
           'Access-Control-Expose-Headers': '',
           'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',

        })
      })
      return next.handle(clonedRequest);
    }
    const clonedRequest = req.clone({
      headers: new HttpHeaders({
        Authorization: localStorage.getItem("ACCESS_TOKEN")
      })

    })
    return next.handle(clonedRequest);
  }

}
