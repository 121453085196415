import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { FauteuilDefaultService } from 'src/app/service/fauteilDefault.service';

@Component({
  selector: 'app-affect-fauteuil-delete',
  templateUrl: './affect-fauteuil-delete.page.html',
  styleUrls: ['./affect-fauteuil-delete.page.scss'],
})
export class AffectFauteuilDeletePage implements OnInit {
  SelectedDate: any;
  MapOfFauteuil :any;
  MapOfAffectation: any;
  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    private FauteuilDefault: FauteuilDefaultService,
  ){
   
   }

  ngOnInit() {
    this.SelectedDate = this.navParams.data.SelectedDate;
    this.MapOfFauteuil = this.navParams.data.MapOfFauteuil;
    this.MapOfAffectation = this.navParams.data.MapOfAffectation;
    console.log(this.MapOfFauteuil);
    

    console.log(this.MapOfAffectation);
  }
  getFauteuilName(id){
    for (let index = 0; index < this.MapOfFauteuil.length; index++) {
      if (this.MapOfFauteuil[index].Id == id){
        return this.MapOfFauteuil[index].Text
      }
      
    }
  }

  private async DeleteAffectation(id) {
    console.log(id);
    this.FauteuilDefault.DeleteAffectations(id).subscribe(async (res) => {
      this.closeModal();
    }, error => console.error(error));
  }

  
  async closeModal() {
    const onClosedData: string = "Wrapped Up!";
    await this.modalController.dismiss(onClosedData);
  }
}
