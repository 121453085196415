import { Directive, Renderer2, OnInit, Inject, HostListener } from "@angular/core";
import { DOCUMENT } from "@angular/common";

@Directive({
  selector: "[appZoom]",
})
export class ZoomDirective implements OnInit {
  constructor(
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {
    this.applyZoom();
    this.manageOverflowX(); // Call method to manage overflow-x on initialization
  }

  @HostListener("window:resize", ["$event"])
  onResize() {
    this.applyZoom();
    this.manageOverflowX(); // Call method to manage overflow-x on resize
  }

  private applyZoom() {
    const mainContent = this.document.querySelector(
      "#maintext.main-content"
    ) as HTMLElement;

    if (window.innerWidth === 1280 || window.innerWidth ===1360 || window.innerWidth === 1366 || window.innerWidth === 1400 || window.innerWidth === 1440) {
      const zoomLevel = 0.67; // Adjust this value as per your requirement
      const adjustedHeight = `${window.innerHeight * (1 / zoomLevel)}px`; // Adjusted height based on zoom level

      this.renderer.setStyle(this.document.body, "zoom", `${zoomLevel}`);
      // Uncomment if necessary:
      // this.renderer.setStyle(this.document.documentElement, "width", "200%");
      // this.renderer.setStyle(this.document.body, "width", "200%");

      if (mainContent) {
        this.renderer.setStyle(mainContent, "height", adjustedHeight);
      }
    } else {
      this.renderer.removeStyle(this.document.body, "zoom");
      this.renderer.removeStyle(this.document.documentElement, "width");
      this.renderer.removeStyle(this.document.body, "width");

      if (mainContent) {
        this.renderer.setStyle(mainContent, "height", "100vh");
      }
    }
  }

  private manageOverflowX() {
    try {
      if (window.innerWidth === 1280) {
        this.renderer.setStyle(this.document.body, "overflow-x", "hidden");
      } else {
        this.renderer.removeStyle(this.document.body, "overflow-x");
      }
    } catch (error) {
      console.error("Error managing overflow-x:", error);
    }
  }
}
