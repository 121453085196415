import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-menu-flash',
  templateUrl: './menu-flash.page.html',
  styleUrls: ['./menu-flash.page.scss'],
})
export class MenuFlashPage implements OnInit {

  constructor(
    private popOver :PopoverController
  ) { }

  ngOnInit() {
  }
  setModeFlash(mode){
    localStorage.setItem("flashMode",mode);
    this.popOver.dismiss();
  }
}
