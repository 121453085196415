import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ZoneRestreinte, zoneRestreinte } from '../model/zoneRestreinte.model';
import { Context } from '../shared/services/context.service';

@Injectable({
  providedIn: 'root'
})
export class ZoneRestreinteService {

  constructor(private http: HttpClient, private context: Context) { }

  saveZoneRestriente(zoneRestreinte: any){    
    return this.http.post<ZoneRestreinte>(Context.apiUrl+'addZoneRestreinte', zoneRestreinte);
  }
  removeZoneRestriente(id){    
    return this.http.get(Context.apiUrl+'removeZoneRestreinte/'+id);
  }
  getNextIdZoneRestreinte(){
    return this.http.get<number>(Context.apiUrl+'getNextIdZoneRestreinte');
  }
  getAllZoneRestraintes(){
    return this.http.get<zoneRestreinte[]>(Context.apiUrl+'AllZoneRestraintes');
  }
}
