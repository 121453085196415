import { Component, OnInit } from '@angular/core';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { InjectedContext, Context } from 'src/app/shared/services/context.service';
import { ModalController, LoadingController, ActionSheetController, NavParams } from '@ionic/angular';
import { objet } from 'src/app/model/objet.model';
import { ImageViewerNewComponent } from '../ImageViewerNew/Image-ViewerNew.component';
import * as sha1 from 'sha1';
import { ObjetService } from 'src/app/service/objet.service';
import { LnkAttributsObjetsService } from 'src/app/service/lnkAttributsObjets.service';
import { AttributService } from 'src/app/service/attribut.Service';
import { duration } from 'moment';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { toastTypeColor, toastTime } from 'src/app/model/types';
import { CustomToastService } from 'src/app/service/custom-toast.service';
import { CustomLoadingService } from 'src/app/service/custom-loading.service';
@Component({
  selector: 'app-new-pictures',
  templateUrl: './new-pictures.component.html',
  styleUrls: ['./new-pictures.component.scss'],
})
export class NewPicturesComponent implements OnInit {
  constructor(private camera :Camera,
    private objetService: ObjetService,
    private lnkAttributObjetService: LnkAttributsObjetsService,
    private attributService : AttributService,
    private loadingController: LoadingController,
    private modalCtrl : ModalController,
    private customLoading: CustomLoadingService,
    // private navParam : NavParams,
    private customToastService: CustomToastService,
    private route: ActivatedRoute,
    private router:Router,
    public actionSheetController: ActionSheetController,
    private location: Location) {
    
   }
idPersonne ;
loading;
pictures = [];
newobjet = {
  pkObjet: 0, nom: "", extension: "", id_patient: 0, vignette: "", width: 0,
  height: 0, taille: 0, estidentite: 0, dateCreation: "", echelle: 0, fichier: "", lastModif: "",
  repStockage: "", syncPath: "", dateInsertion: "", auteur: "", idGabarit: 0, id_patient_orthalis: 0
};
lnkAttributesObjets = { id_attribut: 0, id_objet: 0, valeur: false, valeur_bool: 0 };
public gabaritOUtils;
public arrayOfPic = [];
gabrit = new Map();
public _path ;
loadedPictures = [];
userImage = [];
public showAllPictures : number;
  ngOnInit() {
  // if(!InjectedContext.GabaritOutils) this.router.navigateByUrl('/home');
  this.idPersonne =  localStorage.getItem("idPatient");
  this._path = Context.root_folder + Context.folder + this.idPersonne + "/"
  this.pictures = InjectedContext.picturePatient;
  this.route.queryParams.subscribe(params => {
    if (this.router.getCurrentNavigation().extras.state) {
      this.showAllPictures = this.router.getCurrentNavigation().extras.state.showAllPictures;
    }
  });
  this.gabaritOUtils = InjectedContext.GabaritOutils ?InjectedContext.GabaritOutils.filter(p=>p.imageCamera) : "";
  }
  loadImageByidGabrit(idGabarit)
  {
    var userImageById;
    var srcs;
 
    userImageById = this.pictures.find(p=>p.idGabarit == idGabarit ) ;
    if(userImageById)
    srcs = userImageById;
    else {
      srcs = this.gabaritOUtils.find(p=>p.id_gabarit_outil == idGabarit);
    }
   return srcs;
  }
  count(idGabarit){
   var nbImages :number = this.pictures.filter(p=>p.idGabarit == idGabarit ).length ;
   this.picturesNew.forEach((key: any,value: any) => {
     if(key === idGabarit)
     nbImages ++;
   });
    return nbImages;
  }
  async close(){
    this.location.back();
  }
  async loadUserMobile(idPersonne) {
      this.modalCtrl.dismiss();
  }
 
    getObjetfromOldPicture(idGabarit){
      var picture :objet = this.loadedPictures.find(p=>p.idGabarit == idGabarit);
      return picture;
      }
  

    async ouvrirImage(arrayOfPictures,idGabarit){
    
      for (let i = 0; i < this.arrayOfPic.length; i++) {
        if(this.arrayOfPic[i].idGabarit == idGabarit) {
          arrayOfPictures.push(this.arrayOfPic[i]);
        }
      }

      const modal = await this.modalCtrl.create({
        component: ImageViewerNewComponent,
        componentProps: {
          'picture': arrayOfPictures,
          'path': this._path,
        }
      });
      modal.present();
      modal.onDidDismiss().then(data=>{
        // this.setNewPicture(idGabarit,data.data.newPicture);
      })
    }
    picturesNew =  new Map();
    openMenu(idGabarit){
      var arrayOfPictures = [];
      arrayOfPictures = this.pictures.filter(p=>p.idGabarit == idGabarit );
    if(arrayOfPictures.length > 0 || this.ifExist(idGabarit))
    {
      this.presentActionSheet(idGabarit,arrayOfPictures);
    return;
    }
    else
    this.openCamera(idGabarit, arrayOfPictures);

}
ifExist(idGabarit) {
  var exist:boolean = false;
  this.picturesNew.forEach((key: any,value: any) => {
   
    if(idGabarit === key)
    {
      exist = true;
    }
})
return exist;
}
  async openCamera(Gabarit, arrayOfPictures)
  {
    const options: CameraOptions = {
      quality: 100,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      sourceType: this.camera.PictureSourceType.CAMERA,
      allowEdit: false,
    }
    let base64Image;
    this.camera.getPicture(options).then((imageData) => {
      
    base64Image = 'data:image/jpeg;base64,' + imageData;
 
    var ele :Element = document.getElementById(Gabarit);
    var eleBadge :HTMLInputElement= <HTMLInputElement>document.getElementById('badge_'+Gabarit);
    eleBadge.innerText =(this.count(Gabarit) + 1).toString();
    ele.removeChild(ele.children[1]);
    var DOM_img = document.createElement("img");
    DOM_img.src = base64Image;
    ele.appendChild(DOM_img); 
  this.picturesNew.set(base64Image,Gabarit);
  this.creationImg(base64Image, Gabarit);

         }, (err) => {
    });
 
  }
  public takePhotoMsg : string = 'Veuillez prendre des photos';

  async uplaodImage(){
    let takenPicturesNumber = this.picturesNew.size;
    if(takenPicturesNumber === 0)          
    await this.customToastService.presentToast(this.takePhotoMsg, toastTypeColor.red, toastTime.medium);
    else 
    {
        for(let [pathImg,idGabarit] of this.picturesNew.entries()) {
          await this.prepareImage(pathImg.replace('data:image/jpeg;base64,',''),idGabarit);
        }
        this.customLoading.presentLoading().then(async value => {
            this.location.back();
        setTimeout(()=>{ 
          let msg = takenPicturesNumber > 1 ? "Vos "+ takenPicturesNumber 
          + " photos sont en cours de téléchargement .." : "votre photo est en cours téléchargement ..";
          this.customToastService.presentToast(msg, toastTypeColor.green, toastTime.medium);
          this.customLoading.dismissLoading();
        }, 2000);
        });
    }


}

creationImg(base64textString, idGabarit) {
 var tmpObjet = {
    pkObjet: 0, nom: "", extension: "", id_patient: 0, vignette: "", width: 0,
    height: 0, taille: 0, estidentite: 0, dateCreation: "", echelle: 0, fichier: "", lastModif: "",
    repStockage: "", syncPath: "", dateInsertion: "", auteur: "", idGabarit: 0, id_patient_orthalis: 0
  };
  tmpObjet.fichier = base64textString;
  tmpObjet.idGabarit = idGabarit;
  this.arrayOfPic.push(tmpObjet);
}

  prepareImage(value,gabarit){

  var obj = this.getObjetfromOldPicture(gabarit);
  
    var base64textString;
    var idUt = this.idPersonne;
    var that = this;
    idUt = localStorage.getItem("idPatient");
     var raw = window.atob(value.toString())

     var n = raw.length;
     var a = new Uint8Array(new ArrayBuffer(n));

     for (var i = 0; i < n; i++) {
       a[i] = raw.charCodeAt(i);
     }

     base64textString = window.btoa(raw);
     const contentType = 'image/jpg';
     const blob = new Blob([a], { type: contentType });
     var form = new FormData();
     form.append("file", blob);
     var destName = sha1(value);
    var cObjet = that.newobjet;
      cObjet.nom = destName;
      cObjet. extension = "JPG";
      cObjet.id_patient = +idUt;
      cObjet.vignette = base64textString;
      cObjet.width = 0;
      cObjet.height = 0;
      cObjet.taille = 0;
      cObjet.estidentite = 0;
      cObjet.dateCreation = null;
      cObjet.lastModif =  null;
      cObjet.echelle = 1;
      cObjet.fichier = destName + ".JPG"
      cObjet.repStockage =  Context.folder + idUt;
      cObjet.syncPath = "";
      cObjet.dateInsertion = null
      cObjet.auteur= null;
      cObjet.id_patient_orthalis = +idUt;
      cObjet.idGabarit = gabarit ;
    
     const result =  Promise.all([
       
       that.objetService.saveImage(destName + ".JPG",Context.folder + idUt, form).toPromise(),
       that.insertObjet(cObjet).toPromise()
     ]);
     result.then(data=>{ 
      var newObjet :any = data[1];
      if(obj !== undefined)
      this.deleteObjet(obj);
     
      if(gabarit !== 0)
       this.affectGabaritToObjet_GabaritFound(gabarit,newObjet)                   
     },
     function(error) {
       alert("catch error" + error.message)
     
      })
  }
  deleteObjet(picture) {
    this.lnkAttributObjetService.deleteByObjet(picture.pkObjet).subscribe(result => {
    });
    this.objetService.deleteObjet(picture.pkObjet).subscribe(result => {
    });
    var imageUrlPrefix =  "/" +Context.folder + this.idPersonne + "/" + picture.fichier;
    this.objetService.findAndRmove(imageUrlPrefix).subscribe(res => {
    });
  }
  affectGabaritToObjet_GabaritFound(gabaritOutilsSelected,objetSelected) {
    var that = this;
    this.selectAttributByGabaritJson(gabaritOutilsSelected).forEach(function(elements) {
      elements.forEach(element => {
        that.insertInLnkAttributObjet(element.pk_attribut, element.valeur, objetSelected.pkObjet);

       });

     });
    
  }
  selectAttributByGabaritJson(idGabaritSelected) {
    return this.attributService.selectAttributByGabaritJson(idGabaritSelected)
  }
  insertInLnkAttributObjet(pkAttribut, valeur, pkObjet) {
    var lnkAttributesObjets = this.lnkAttributesObjets;
    lnkAttributesObjets.id_attribut = pkAttribut;
    lnkAttributesObjets.id_objet = pkObjet;
    lnkAttributesObjets.valeur = valeur;
    this.lnkAttributObjetService.insertInLnkAttributObjet(lnkAttributesObjets).subscribe(result => {
    }, error => console.error(error));
  }
  insertObjet(objet) {
    return this.objetService.addObjet(objet)
    }
    async presentActionSheet(idGabarit,arrayOfPictures) {
      const actionSheet = await this.actionSheetController.create({
        //   header: '',
        buttons: [
      {
            text: 'Ouvrir les photos',
  
            handler: () => {
              this.ouvrirImage(arrayOfPictures,idGabarit);
              
            }
          }, {
            text: 'Ajouter une photo',
           
            handler: () => {              
              this.openCamera(idGabarit, arrayOfPictures)
            }
          }]
      });
      await actionSheet.present();
    }
    
}
