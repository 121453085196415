import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import { objet } from '../model/objet.model';
import { Context } from '../shared/services/context.service';
import { Body } from '@angular/http/src/body';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class ObjetService {
  gabarit: any;
  constructor(
    private http: HttpClient,
    public platform: Platform,
    private context: Context) { }

  private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

  getPhotoNumber(idPatient) {
    return this.http.get<number>(Context.apiUrl + 'NbObjectByPatient?id=' + idPatient)
  }

  listphoto(id: number) {
    return this.http.get<objet[]>(Context.apiUrl + 'getPhotosListById/' + id);
  }

  listphotoByPage(id: number, page, size) {
    return this.http.get<objet[]>(Context.apiUrl + 'objet_by_patient_by_page/' + id + '&' + page + '&' + size);
  }
   createDossierPatient(path)
  {
    

     return  this.http.get<string>(Context.apiUrl + 'CreatePatientDossier?rep=' + path);
    
  }
  listphotoCamera(id: number) {
    return this.http.get<objet[]>(Context.apiUrl + 'objet_by_patient_by_page/' + id);
  }


  getObjetFaceSourire(idPatient) {
    return this.http.get<objet>(Context.apiUrl + 'objet_patient/' + idPatient);
  }

  updateIdGabarit(obj) {
    return this.http.put(Context.apiUrl + 'objet/update/' + obj.pkObjet, obj);
  }

  saveImage(name, path, form) {
    return this.http.post(Context.apiUrl + 'SaveImageNew/image/' + name + "?path=" + path, form);
  }

 // /updateImage/image/{name}/{ancienNom}
    updateImage(name, path, form ,ancienNom ){
      // ancienNom = ancienNom+".JPG";
      console.log(name, path, form ,ancienNom);

      return this.http.post(Context.apiUrl + 'updateImage/image/' + name +'/'+ancienNom+ "?path=" + path, form);

    }

  deleteObjet(idObjet) {
    return this.http.delete(Context.apiUrl + 'objet/deleteObjet/' + idObjet);
  }
  findAndRmove(url: string) {
    return this.http.get<string>(Context.apiUrl + 'findAndRmove?rep=' + url);
  }


  FindImagetest(url) {
    return this.http.get(Context.apiUrl + "NameNewImage?rep=" + Context.folder + url, { responseType: 'text' });
  }

  // saveImage(name,form){
  //   return this.http.post(environment.apiUrl + 'SaveImageNew/image/'+name+"?path=",form);
  // }

  addObjet(obj) {
    return this.http.post<objet>(Context.apiUrl + 'objet/add/', obj )
  }
  async existFile (path , name ){
    //return
    const data=  await this.http.get(Context.apiUrl + "downloadFile/"+name+"?uri="+path , { responseType: 'text' })
     return data.toPromise()
  }
}
