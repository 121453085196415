import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import { Attributs } from '../model/attributes.model';
import { Context } from '../shared/services/context.service';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
    providedIn: 'root'
  })
export class AttributService {
    gabarit: any;
    constructor(
        private http: HttpClient,
        private context : Context,
        public platform: Platform) { }

    private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

    selectAttributByGabaritJson(idGabarit) {
        return this.http.get<Attributs[]>(Context.apiUrl + 'AttributsGabarit/' + idGabarit);
    }

    AttributsByObjet(idObjet) {
        return this.http.get<Attributs[]>(Context.apiUrl + 'attributs_objet/' + idObjet);
    }
}