import { Injectable } from '@angular/core';


import { HttpClient, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import { Context } from '../shared/services/context.service';
import { TplTauxAffectationPraticien } from '../model/TplTauxAffectationPraticien';
import { TplNbFauteuilParPraticien } from '../model/TplNbFauteuilParPraticien';
import { TplHoraireReelle } from '../model/TplHoraireReelle';
import { Utilisateur } from '../model/utilisateur.model';
import { NbPatientPhaseTrait } from '../model/NbPatientPhaseTrait';
import { NbPatientStatutCl } from '../model/NbPatientStatutCl';
import { TplPointage, AllEcheance } from '../model/TplEcheanceCAMoisPrevisionnel';
import { NbAllDevis, countAllDevis, NbDevisAccepte } from '../model/TplDevisAccepteNonAccepte';



@Injectable({
  providedIn: 'root'
})
export class StaffStatsService {

  constructor(private http: HttpClient,
    private context: Context,
    public platform: Platform) { }

  getPresencePersonnelByUserPeriod(start, end) {
    return this.http.get<TplPointage[]>(Context.apiUrl + 'getPresencePersonnelPeriod/' + start + '&' + end);
  }
  getNbDevisAll(start, end) {
    return this.http.get<NbAllDevis[]>(Context.apiUrl + 'getNbDevisAll/' + start + '&' + end);
  }
  getTotalDevisAllUsers(start, end) {
    return this.http.get<countAllDevis[]>(Context.apiUrl + 'getTotalDevisAllUsers/' + start + '&' + end);
  }
  getNbDevisAcceptes(year) {
    return this.http.get<NbDevisAccepte[]>(Context.apiUrl + 'getNbDevisAccepter/' + year );
  }
  getNbDevisNonAcceptes(year) {
    return this.http.get<NbDevisAccepte[]>(Context.apiUrl + 'getNbDevisNonAccepter/' + year );
  }
  getAllEcheance(start, end) {
    return this.http.get< AllEcheance[]>(Context.apiUrl + 'getAllEcheance/' + start + '&' + end);
  }
  getUtilisateurs() {
    return this.http.get<Utilisateur[]>(Context.apiUrl + 'Utilisateurs/');
  }
  getUtilisateurById(idUser) {
    return this.http.get(Context.apiUrl + 'personne/'+idUser);
  }
  TauxAffectationsPraticien(annee: number) {
    return this.http.get<TplTauxAffectationPraticien[]>(Context.apiUrl + 'getTauxAffectationsPraticienMobile/' + annee)
  }
  getNbrFauteuilParPraticien(annee: number) {
    return this.http.get<TplNbFauteuilParPraticien[]>(Context.apiUrl + 'getNbrFauteuilParPraticienMobile/' + annee)
  }
  getNombreHeuresParPraticien(annee: number) {
    return this.http.get<TplHoraireReelle[]>(Context.apiUrl + 'getNombreHeuresParPraticienMobile/' + annee)

  }
  PhaseTraitementForGraph() {
    return this.http.get<NbPatientPhaseTrait[]>(Context.apiUrl + 'PhaseTraitementForGraph/')
  }
  NbPatientStatutCl() {
    return this.http.get<NbPatientStatutCl[]>(Context.apiUrl + 'NbPatientStatutCl')
  }



}
