import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { Context } from '../shared/services/context.service';
import { Acte, ActeGroupement } from '../model/Acte.model';
import { GlFiActeGraphStat } from '../model/GlFiActeGraphStat';
import { FamilleActe } from './FamilleActe';
import { FamilleActeGroupement } from './FamilleActeGroupement';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};



@Injectable({
    providedIn: 'root'
})
export class ActeService {
    constructor(
        private http: HttpClient,
        private context: Context,
    ) {

    }

    private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

    getActe() {
        return this.http.get<Acte[]>(Context.apiUrl + 'Actes');
    }

    getActeById(id) {
        return this.http.get<Acte[]>(Context.apiUrl + 'Acte/'+ id);
    }

    getActeFamille(id) {
        return this.http.get<Acte[]>(Context.apiUrl + 'ordredActesAndByFamille/'+ id);
    }
    CATop10Mois(annee: number, mois: number) {
        return this.http.get<any[]>(Context.apiUrl + 'CATop20Mois/' + mois + '&' + annee);
    }
    CATop10Annee(annee: number) {
        return this.http.get<GlFiActeGraphStat[]>(Context.apiUrl + 'CATop20Annee/' + annee);
    }

    familleActes() {
        return this.http.get<FamilleActe[]>(Context.apiUrl + 'familleActes');
    }// familleActes
    getNextIdFamilyAct() {
        return this.http.get<number>(Context.apiUrl + 'familyAct/getNextId');
    }
    insertfamilyAct(familyAct) {
        return this.http.post(Context.apiUrl + 'familyAct/insert', familyAct)
    }
    updateFamilyActName(id: number, name: string) {
        return this.http.put(Context.apiUrl + 'familyAct/updateName/' + id, name)
    }
    deleteFamilyAct(id) {
        return this.http.delete(Context.apiUrl + 'familyAct/delete/' + id);
    }

    getEvolutionActeMois(idActe: number, year: number, month: number) {
        return this.http.get<GlFiActeGraphStat[]>(Context.apiUrl + 'EvolutionActeMois/' + month + '&' + year + '&' + idActe);
    }
    EvolutionActeAnnee(idActe: number, year: number) {
        return this.http.get<GlFiActeGraphStat[]>(Context.apiUrl + 'EvolutionActeAnnee/' + year + '&' + idActe);
    }
    getActesGroupement() {
        return this.http.get<ActeGroupement[]>(Context.apiUrl + 'AllActeGrp');
    }
    familleActesGroupement() {
        return this.http.get<FamilleActeGroupement[]>(Context.apiUrl + 'allFamillesActeGrp');
    }
    deleteActe(idActe) {
        return this.http.get<Boolean>(Context.apiUrl + 'deleteActe/' + idActe);
    }
    saveActe(acte) {
        return this.http.post(Context.apiUrl + 'saveActe', acte);
    }
    /////////////////////////////////////// Act grp ///////////////////////////////////

    updateActGrp(actGrp) {
        return this.http.put(Context.apiUrl + 'actGrp/update', actGrp)
    }
    deleteActGrp(id) {
        return this.http.delete(Context.apiUrl + 'actGrp/delete/' + id);
    }
    deleteAllActsFromactGrp(id) {
        return this.http.delete(Context.apiUrl + 'allActsFromactGrp/delete/' + id);
    }

    /////////////////////////////////////// start fm Act grp ///////////////////////////////////
    updateFamilyActGrpName(id: number, name: string) {
        return this.http.put(Context.apiUrl + 'familyActGrp/updateName/' + id, name)
    }
    deleteFamilyActGrp(id) {
        return this.http.delete(Context.apiUrl + 'familyActGrp/delete/' + id);
    }
    insertFamilyActGrp(actGrp) {
        return this.http.post(Context.apiUrl + 'familyActGrp/insert', actGrp)
    }
    getNextIdFamilyActGrp() {
        return this.http.get<number>(Context.apiUrl + 'familyActGrp/getNextId');
    }
    /////////////////////////////////////// end fm Act grp ///////////////////////////////////

} 