import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular';
import { CustomToastService } from 'src/app/service/custom-toast.service';
import { toastTypeColor, toastTime } from 'src/app/model/types';

@Component({
  selector: 'app-smilers-credentials',
  templateUrl: './smilers-credentials.component.html',
  styleUrls: ['./smilers-credentials.component.scss'],
})
export class SmilersCredentialsComponent implements OnInit {
  smilersForm: FormGroup;
  public submitClicked:boolean=false;
  public photos = [];
  public photosToDisplay = [];
  public photoHide = true;
  public userDetails;
  public namesPictures = ["FRONTAL_VIEW_SMILE", "PROFILE",
  "OCCLUSAL_VIEW_FRONT", "OCCLUSAL_VIEW_LEFT", "OCCLUSAL_VIEW_RIGHT", "OCCLUSAL_VIEW_MAXILLA",
  "OCCLUSAL_VIEW_MANDIBULA", "PANORAMIC_X_RAY", "INOCCLUSION", "TELERADIO"];
  public selectItem = [
    { text: "M.", value: 'mr' },
    { text: "Mme", value: 'mrs' },
    { text: "Mlle", value: 'ms' },
  ];
  public toastMsg : string = "Veuillez renseigner tous les champs";
  constructor(
    private formBuilder: FormBuilder,
    private modalCtrl: ModalController,
    private customToastService: CustomToastService,
    private navParams: NavParams,

  ) {
    this.createForm();
    this.photos = navParams.data.photos;
    this.userDetails = navParams.data.userDetails;
    this.createPhotosToDisplay();
   }
   createForm() {
    this.smilersForm = this.formBuilder.group({
      nom: ['', Validators.required],
      prenom: ['', Validators.required],
      titre: ['', Validators.required],
      naissance: ['', Validators.required],
    });
  }
  updateDataEntry() {
    this.smilersForm.controls['nom'].setValue(this.userDetails.per_nom);
    this.smilersForm.controls['prenom'].setValue(this.userDetails.per_prenom);
    this.smilersForm.controls['naissance'].setValue(this.userDetails.per_datnaiss);
    let item = this.selectItem.find( p => (p.text.toLocaleLowerCase() == this.userDetails.pers_titre.toLocaleLowerCase())
    || (p.value.toLocaleLowerCase() == this.userDetails.pers_titre.toLocaleLowerCase()));
    if(item) this.smilersForm.controls['titre'].setValue(item.value);
  }
  createPhotosToDisplay() {
    for(let i = 0 ; i < this.photos.length ; i++) {
      if(this.photos[i])
      this.photosToDisplay.push({vignette: this.photos[i].vignette, name: this.namesPictures[i] });
      else
      this.photosToDisplay.push({vignette: undefined, name: this.namesPictures[i] });
    }
  }
  ngOnInit() { 
    this.updateDataEntry();
  }
  dismissModal() {
    this.modalCtrl.dismiss();
  }
  sendData(form) {
    this.submitClicked=true;
    if (this.smilersForm.invalid) {
      this.customToastService.presentToast(this.toastMsg, toastTypeColor.red, toastTime.small);
      return;
  }
    this.modalCtrl.dismiss(form.value);
  }
  hidePhotos() {
    this.photoHide = ! this.photoHide;
  }
}
