import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
} from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { AppComponent } from "./app.component";
import { IonicGestureConfig } from "./IonicGestureConfig";
import { AppRoutingModule } from "./app-routing.module";
import {
  SidebarModule,
  MenuAllModule,
} from "@syncfusion/ej2-angular-navigations";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { StatisticsPageModule } from "./pages/statistics/statistics.module";
import { ListViewModule } from "@syncfusion/ej2-angular-lists";
import { AuthModule } from "./auth/services/auth.module";
import { PatientsListComponent } from "./components/patients-list/patients-list.component";
import { AppointmentsPageModule } from "./pages/appointments/appointments.module";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { PhotosListPageModule } from "./pages/photos-list/photos-list.module";
import { GridModule } from "@syncfusion/ej2-angular-grids";
import { RedirectComponent } from "./components/redirect/redirect.component";
import { PopoverComponent } from "./pages/finance/popover/popover.component";
import { NgxPaginationModule } from "ngx-pagination";
import { PopoverdestinataireComponent } from "./pages/baselettre/popoverdestinataire/popoverdestinataire.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HeaderComponent } from "./pages/bascontact/menu/header/header.component";
import { DepensesComponent } from "./components/depenses/depenses.component";
import { AjoutdepensesPage } from "./pages/ajoutdepenses/ajoutdepenses.page";
import { FacturedepensesComponent } from "./components/facturedepenses/facturedepenses.component";
import { ModalajoutcontactComponent } from "./pages/bascontact/menu/modalajoutcontact/modalajoutcontact.component";
import { AddgroupementacteComponent } from "./components/fiche-patient/addgroupementacte/addgroupementacte.component";
import { GrpActListComponent } from "./components/grp-act-list/grp-act-list.component";
import { NgxIonicImageViewerModule } from "ngx-ionic-image-viewer";

import { CalculatriceComponent } from './components/fiche-patient/casclinique/calculatrice/calculatrice.component';
import { ZoomDirective } from './zoom.directive';
@NgModule({
  declarations: [
    AppComponent,
    PatientsListComponent,
    RedirectComponent,
    PopoverComponent,
    PopoverdestinataireComponent,
    HeaderComponent,
    ModalajoutcontactComponent,
    ZoomDirective,
  ],
  entryComponents: [
    PatientsListComponent,
    PopoverComponent,
    PopoverdestinataireComponent,
    ModalajoutcontactComponent,
  ],
  imports: [
    BrowserModule, // ion-app
    // IonicStorageModule.forRoot(),
    IonicModule.forRoot(),
    AppRoutingModule,
    SidebarModule,
    MenuAllModule, // ejs-menu
    AppointmentsPageModule,
    ListViewModule,
    AuthModule,
    PhotosListPageModule,
    GridModule,
    ReactiveFormsModule,
    FormsModule,
    NgxIonicImageViewerModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  exports: [
    PatientsListComponent,
    PopoverComponent,
    PopoverdestinataireComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
