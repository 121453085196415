import { DatePipe, formatDate } from '@angular/common';
import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController, ModalController } from '@ionic/angular';
import { ChangeEventArgs, DatePickerComponent, TimePickerComponent } from '@syncfusion/ej2-angular-calendars';
import { AutoCompleteModule, DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { ContextMenuComponent, ContextMenuModel, TabComponent, TreeViewComponent } from '@syncfusion/ej2-angular-navigations';
import { ToastComponent, ToastPositionModel } from '@syncfusion/ej2-angular-notifications';
import { Dialog, DialogComponent } from '@syncfusion/ej2-angular-popups';
import { ActionEventArgs, CellClickEventArgs, EventRenderedArgs, EventSettingsModel, GroupModel, PopupCloseEventArgs, PopupOpenEventArgs, RecurrenceEditor, RenderCellEventArgs, ResourceDetails, ScheduleComponent, TimeScaleModel, ToolbarActionArgs, View, WorkHoursModel } from  "@syncfusion/ej2-angular-schedule";
import { addClass, closest, compile, Internationalization, isNullOrUndefined, L10n, remove, removeClass } from '@syncfusion/ej2-base';
import { DragAndDropEventArgs, ItemModel, MenuItemModel } from '@syncfusion/ej2-navigations';
import { ButtonPropsModel, PositionDataModel } from '@syncfusion/ej2-popups';
import { Subscription, ArgumentOutOfRangeError } from 'rxjs';
import { AppointementData, Localisation, StatusRdv } from 'src/app/model/appointementData.model';
import { ZoneRestreinte } from 'src/app/model/zoneRestreinte.model';
import { ActeService } from 'src/app/service/acte.service';
import { AppointementService } from 'src/app/service/appointement.service';
import { AppointementTraceService } from 'src/app/service/appointementTrace.Service';
import { CommCliniqueService } from 'src/app/service/commClinique.Service';
import { DateDataService } from 'src/app/service/convertisseurDate';
import { FauteuilDefaultService } from 'src/app/service/fauteilDefault.service';
import { HoraireTravailService } from 'src/app/service/horaire-travail.service';
import { noteRdvService } from 'src/app/service/noteRdv.service';
import { PointageService } from 'src/app/service/pointage.service';
import { ZoneRestreinteService } from 'src/app/service/zone-restreinte.service';
import { Context, InjectedContext } from 'src/app/shared/services/context.service';
import { FinanceDetailCA } from 'src/app/model/TplEcheanceCAMoisPrevisionnel';
import { StatutCabinet } from 'src/app/model/StatutCabinet.model';
import { UserStaffService } from 'src/app/service/user-staff.service';
import { AffectFauteuilModalPage } from 'src/app/pages/affect-fauteuil-modal/affect-fauteuil-modal.page';
import { now } from 'moment';
import { AffectFauteuilDeletePage } from '../affect-fauteuil-delete/affect-fauteuil-delete.page';
import { DialogUtility } from '@syncfusion/ej2-popups';




L10n.load({

  "fr-CH": {
    'schedule': {
      'day': 'journée',
      'week': 'La semaine',
      'workWeek': 'Semaine de travail',
      'month': 'Mois',
      "today": "Aujourd`hui",
      'cancelButton': 'Annuler',
      'saveButton': 'Valider',
      'deleteButton': 'Supprimer',
      'editButton': 'edit',
      'editEvent': 'Modification',
      'deleteEvent': 'Suppression',
      'deleteContent': 'Êtes-vous sûr de vouloir supprimer ce Rendez-vous?',
      'delete': 'valider',
      'cancel': 'annuler',
      "newEvent": "Ajout",
      "previous": "Précédant",
      "next": "Suivant",
      "alert": "Alerte",
    }
  },
});
@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.page.html',
  styleUrls: ['./appointments.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppointmentsPage implements OnInit {
  public isDesktop: boolean;
  public dateValue: Date = new Date();
  // this.dateValue.setDate(this.dateValue.getDate() + 2)

  
  // public dateValue: string = "Sat Dec 04 2021 12:43:58 GMT+0100 (heure normale d’Afrique de l’Ouest)"

  private updateSubscription: Subscription;
  StatutCabinet: any;
  mapofStatutCabinet = [];
  calendarStatus = [];
  lastId: any;
  UserColor: boolean;
  reload=()=>window.location.reload();
  role: any;
  teststatutcabinet :any;
  calendarArgs: any;



  async ngOnInit(){
    
    this.isDesktop = this.context.detectPlatformDesktop();
    this.role=this.currentUser.authorities[0].name;
    console.log(this.role);
    let dateDebut = formatDate(new Date(Date.now()), "yyyy-MM-dd HH:mm:ss", 'en-US');
    let dateDebutDuJour = dateDebut.substring(0, 10) + " 00:00:00";
    let dateFinDuJour = dateDebut.substring(0, 10) + " 23:59:00";
    
  }
  // onNavigatingToday(event) {

  //   console.log("onNavigating",event);
  //   // this.ArrayPointage =[];
    
  //   // this.dayHeaders = [];
  //   this.toastObj.hide('All');
  //   this.selectedDate = event.value;
  //   let dateDebut = formatDate(event.value, "yyyy-MM-dd HH:mm:ss", 'en-US');
  //   let dateDebutDuJour = dateDebut.substring(0, 10) + " 00:00:00";
  //   let dateFinDuJour = dateDebut.substring(0, 10) + " 23:59:00";

  //   this.getAppointements(dateDebutDuJour, dateFinDuJour);
  //   this.getParticienAffectation(dateDebutDuJour, dateFinDuJour);
  //   this.getNoteRdv(dateDebutDuJour, dateFinDuJour);

  // }

  today(){
    window.location.assign('/appointments');
  
  }


  ngOnDestroy() {
    if (this.updateSubscription) {
      this.updateSubscription.unsubscribe();
    }
  }

  @ViewChild('CabinetMenu', { static: true }) public CabinetMenu: ContextMenuComponent;
  @ViewChild('defaulttoast', { static: false }) public toastObj: ToastComponent;
  @ViewChild('scheduleObj', { static: true }) public scheduleObj: ScheduleComponent;
  @ViewChild('treeObj', { static: false }) public treeObj: TreeViewComponent;
  @ViewChild('subject', { static: true }) subjectObj: ElementRef;
  @ViewChild('location', { static: true }) locationObj: ElementRef;
  @ViewChild('startTime', { static: true }) startTimeObj: DatePickerComponent;
  @ViewChild('endTime', { static: true }) endTimeObj: DatePickerComponent;
  @ViewChild('menuObj', { static: true }) public menuObj: ContextMenuComponent;
  @ViewChild('fauteils', { static: false }) public fauteils: DropDownListComponent;
  @ViewChild('actes', { static: true }) public actes: DropDownListComponent;
  @ViewChild('editorTemplate', { static: false }) public editorTemplate: RecurrenceEditor;
  @ViewChild('acteText', { static: false }) public acteText: ElementRef;

  @ViewChild('fauteilsText', { static: false }) public fauteilsText: ElementRef;
  @ViewChild('autoComplete', { static: false }) public autoComplete: AutoCompleteModule;
  @ViewChild('adaptiveTab', { static: false }) public tabObj: TabComponent;
  @ViewChild('dateTimePicker', { static: false }) public dateTimePicker: DatePickerComponent;
  @ViewChild('StarttimepickerNote', { static: false }) public StarttimepickerNote: TimePickerComponent;
  @ViewChild('EndtimepickerNote', { static: false }) public EndtimepickerNote: TimePickerComponent;

  @ViewChild('editorNote', { static: false }) public editorNote: RecurrenceEditor;
  @ViewChild('confirmDialog', { static: false }) public confirmDialog: DialogComponent;
  @ViewChild('promptDialog', { static: false }) public promptDialog: DialogComponent;


  public isTreeItemDropped: boolean = false;
  public draggedItemId: string = '';

  public allowDragAndDrop: boolean = true;
  public allowResizing: Boolean = false;
  
  // public  currentUserValue() {
    // var value = localStorage.getItem("currentUser");
    currentUser = JSON.parse(localStorage.getItem("currentUser"));
    
    // console.log("userrrr",currentUser.id);
    
  // }
  nextApp = {
    id_rdv: 0,
    id_personne: 0,
    perIdPersonne: this.currentUser.id,
    id_acte: 0,
    id_fauteuil: 0,
    rdvDate: "",
    rdvDuree: 0,
    rdvStatut: 0,
    rdvComm: "",
    isExported: "N",
    aAvancer: false,
    idCommClinique: 0,
    ActeLibelle: "",
    CategoryColor: "",
  };

  traceApp = {
    id_rdv: 0,
    id_utilisateur: 1,
    trace_date: "",
    trace_comment: "",
    id_acte: 0,
    id_personne: 0,
    id_fauteuil: 0,
    rdv_date: "",
    rdv_duree: 0,
    per_id_personne: 2,
    ActeLibelle: "",
  };

  rdvNoteApp = {
    Subject: "",
    EndTime: "",
    StartTime: "",
  };

  ArrayPointage = [];

  public menuItems: MenuItemModel[] = [
    {
      text: 'Restreindre la zone',
      iconCss: 'e-icons new',
      id: 'Add'
    }, {
      text: 'Ajouter une note du jour',
      iconCss: 'e-icons recurrence',
      id: 'AddNote'
    }, 
    // {
    //   text: "Supprimer l'affectation",
    //   iconCss: 'e-icons recurrence',
    //   id: 'DeleteAffectation'
    // }, 
    {
      text: 'Supprimer la zone',
      iconCss: 'e-icons delete',
      id: 'DeleteZone'

    }, {
      text: 'Status',
      iconCss: 'e-icons new',
      id: 'status',
      items: [
        {
          text: 'Absent',
          iconCss: 'e-icons new',
          id: 'absent',
        },
        {
          text: 'Attendu',
          iconCss: 'e-icons new',
          id: 'attendu'
        },
        {
          text: 'Arrivé',
          iconCss: 'e-icons new',
          id: 'arrive'
        },
        {
          text: 'Sortie',
          iconCss: 'e-icons new',
          id: 'sortie'
        },
        {
          text: 'Accueil',
          iconCss: 'e-icons new',
          id: 'accueil'
        },

      ]
    }, {
      text: "Fauteils",
      iconCss: 'e-icons new',
      id: "fauteils",


    },
    {
      text: 'Modifier',
      id: 'EditRecurrenceEvent',
      iconCss: 'e-icons e-edit2',


    },
    {
      text: 'Supprimer',
      iconCss: 'e-icons e-delete2',
      id: 'Delete'
    },

  ];
  public CliniqueItems: MenuItemModel[] = [
  {
    text: 'Cabinet Ouvert',
    // iconCss: 'e-icons e-delete2',
    id: 'CabinetOuvert'
  },
  {
    text: 'Cabinet Fermé',
    // iconCss: 'e-icons e-delete2',
    id: 'CabinetFerme'
  },
  {
    text: 'Praticien Absent',
    // iconCss: 'e-icons e-delete2',
    id: 'PraticienAbsent'
  },
  {
    text: 'Supprimer',
    // iconCss: 'e-icons e-delete2',
    id: 'SupprimerStatus'
  }];
  comm = {
    id: 0,
    id_RDV: 0,
  };

  public fauteuilFields: Object = { text: 'Id', value: 'text' };
  field: Object;
  data = [];
  zoneRestreinte: ZoneRestreinte[] = [];
  public selectedDate: Date = new Date(Date.now());
  public dateFormat: string = "dd/MM/yyyy";
  public currentView: View = 'Day';
  public startHour: string = '08:00';
  public endHour: string = '20:00';
  public workHours: WorkHoursModel = { highlight: false, start: '08:00', end: '20:00' };
  fauteuilDataSource;
  public instance: Internationalization = new Internationalization();
  public group: GroupModel = { enableCompactView: false, resources: ['Fauteuil'], byGroupID: false };
  public allowMultiple: Boolean = false;
  public virtualscroll: Boolean = false;
  public showWeekend: Boolean = false;
  public workWeekDays: number[] = [1, 2, 3, 4, 5, 6];
  affectation = [];
  desktop = false;
  public eventSettings: EventSettingsModel;
  public NewId;
  filteredData: { [key: string]: Object }[];
  //public date: Object = new Date('26/03/2013');
  constructor(private appointementService: AppointementService,
    private userStaffService : UserStaffService,
    private loadingController: LoadingController,
    private fauteuil: FauteuilDefaultService,
    private commCliniqueService: CommCliniqueService,
    private appointementTraceService: AppointementTraceService,
    private zoneRestrienteService: ZoneRestreinteService,
    private acteService: ActeService,
    private datePipe: DatePipe,
    private context: Context,
    private noteRdvService: noteRdvService,
    private elementRef: ElementRef,
    private horaireTravailService: HoraireTravailService,
    private dateDataService: DateDataService,
    private pointageService: PointageService,
    public modalController: ModalController,
    private router: Router) {
      
    // InjectedContext.Fromlogout =  false; // for display menu we don't need homePage (permanently)

    if (this.context.detectPlatformDesktop())
      this.desktop = true;
    this.getFauteils();
    this.getActes();
    // this.getallpatienwithNextcc();


  }
  // onLoad(args: any) {
  //   /*Date need to be customized*/
  //   for (var i = 0; i < this.mapofStatutCabinet.length; i++) {
  //     if (this.mapofStatutCabinet[i].libelle === "Cabinet Fermé") {
  //       let span: HTMLElement;
  //       span = document.createElement('span');
  //       span.setAttribute('class', 'e-icons highlight');
  //       addClass([args.element], ['special', 'e-day', 'birthday']);
  //       args.element.firstElementChild.setAttribute('title', 'Cabinet Fermé !');
  //       args.element.setAttribute('title', ' Cabinet Fermé !');
  //       args.element.appendChild(span);
  //   }
  //     // this.mapofStatutCabinet.push({ "ID": result[i].id, "date": result[i].date,"libelle": result[i].libelle,"statut": result[i].statut });
  //   }

  // }
  async openAffectationFauteuilModal() {
    const modal = await this.modalController.create({
      component: AffectFauteuilModalPage,
      componentProps: {
        "SelectedDate": this.selectedDate,
        "MapOfFauteuil":this.mapOfFauteuil
      }
    });
    modal.onDidDismiss().then( (res) =>{
      this.refreshAllTemplate();
  })
    return await modal.present();
  }

  async openDeleteAffectationFauteuil() {
    const modal = await this.modalController.create({
      component: AffectFauteuilDeletePage,
      componentProps: {
        "SelectedDate": this.selectedDate,
        "MapOfFauteuil":this.mapOfFauteuil,
        "MapOfAffectation":this.affectation
      }
    });
    modal.onDidDismiss().then( (res) =>{
      // window.location.reload();
      this.refreshAllTemplate();
    })
    return await modal.present();
  }
  refreshAllTemplate(){
    var date = {
      "value" : this.selectedDate,
      "isInteracted": true,
      "name": "change",
      "values": null
    }
    console.log(date);
    
    this.onNavigating(date);
    this.scheduleObj.refresh();
    this.scheduleObj.refreshEvents();
  }
  getStatusCabinet(args){
    // this.appointementService.getStatutCabinet().subscribe(data => this.StatutCabinet = data);
    // console.log("args",args);
    this.calendarArgs=args;
    this.appointementService.getStatutCabinet().toPromise().then(res => {
      this.StatutCabinet = res;
      // console.log("OnGetStatusCabinet",this.StatutCabinet);
      this.onLoad(args);
      // return res;
    })
    // await this.appointementService.getStatutCabinet().subscribe(result => {
      
    // // this.StatutCabinet = result;
    // // // console.log("getstatutcabinet");
    // //  // this.StatutCabinet = { value: "ID_ACTE", text: "ACTE_LIBELLE" };
    // //   // for (var i = 0; i < result.length; i++) {
    // //   //   this.mapofStatutCabinet.push({ "ID": result[i].id, "date": result[i].date,"libelle": result[i].libelle,"statut": result[i].statut });
    // //   // }
    // // //  console.log("hello", this.StatutCabinet);
    // // // return this.StatutCabinet;
    // }, error => console.error(error));
    // return ;
  }


  async onLoad(args: any) {
    // console.log("args",args)
    // enableRipple(true);
    // console.log("helllooo");
      var ele =  args.element;
      ele.addEventListener('contextmenu',(e)=>{
        ele.setAttribute("id", "date");
        console.log("clicked",ele);
        // this.CabinetMenu.items = this.menuItems;
        // this.CabinetMenu.items = this.menuClinique;
        // console.log(this.CabinetMenu.items);

        // this.CabinetMenu.hideItems(['Add', 'AddNote', 'DeleteZone', 'status', 'fauteils', 'EditRecurrenceEvent', 'EditRecurrenceEvent', 'Delete' ], true);
        // this.CabinetMenu.showItems(['Cabinet Fermé', 'Cabinet Ouvert', 'Praticien Absent', 'Supprimer'], true);
        // this.CabinetMenu.isRendered === true;
      });

    //   ele.addEventListener("dblclick", function (e: Event) {
    //     let target = closest((<HTMLElement>e.target), '.e-header-cells');
    //     DialogUtility.alert({
    //         title: 'Header',
    //         content: "Double clicked on " + (<HTMLElement>target.querySelector('.e-header-text')).innerText + " header",
    //         showCloseIcon: true,
    //         closeOnEscape: true,
    //         animationSettings: { effect: 'Zoom' }
    //     });
    // });


      

    /*Date need to be customized*/
    
    // console.log("OnLoadgetStatusCabinet",this.StatutCabinet)
    var l =this.StatutCabinet.length;
    this.lastId = this.StatutCabinet[l-1].id
    // console.log("hh",this.lastId);
    // console.log("args",args);
   for (var i = 0; i < this.StatutCabinet.length; i++) {
      var cal = this.StatutCabinet[i].date;
      var month =args.date.getMonth() +1;
      var year =args.date.getFullYear();
      var jour =args.date.getDate().toString();
      month=month.toString();
      //console.log(cal);
      // console.log("jour1",jour)

      if (month.length === 1){
        month = 0 + month;
        //ar dateTest = (year+"-"+"0"+month+"-"+jour);
        // console.log("month"+month)
      }
      if (jour.length === 1){
         jour = 0 + jour ;
        // dateTest = (year+"-"+month+"-"+"0"+jour);
        //var dateTest = (year+"-"+month+"-"+"0"+jour);
        // console.log("jourrrr"+jour)
      }
      var dateTest = (year+"-"+month+"-"+jour);
      // var dateTest = (year+"-"+month+"-"+jour);
      // console.log(dateTest);
      // console.log(this.StatutCabinet[i].libelle);
      // if(dateTest.length===8){

      //   var serviceDate=cal.substr(0, 8);
      //   //console.log(dateTest,"8",serviceDate,);
      // }
      // if(dateTest.length===9){
      //   var serviceDate=cal.substr(0, 9);
      //   //console.log(dateTest,"9",serviceDate);
      // }
      // else {
        var serviceDate=cal.substr(0, 10);
        //console.log(dateTest,"10",serviceDate);
      // }
      // if (dateTest === serviceDate ) {
      //   console.log("-----------------")
      // }
      //serviceDate=serviceDate.substr(0, 10)
      //console.log((args.date.getFullYear()+"-"+args.date.getMonth()+"-"+args.date.getDate()));
      // console.log(dateTest);
      // var spanContent= document.getElementsByClassName("e-day");
       if (dateTest === serviceDate  ) {
        //  console.log("---------------");
        //  console.log("in", this.StatutCabinet[i].date);

         if (this.StatutCabinet[i].libelle ==="Cabinet Fermé"){
          //  console.log("Cabinet Fermé");
          let span: HTMLElement;
          span = document.createElement('span');
          span.setAttribute('class', 'e-icons cabibnetfermer');
          addClass([args.element], ['special', 'e-day', 'birthday']);
          args.element.firstElementChild.setAttribute('title', 'Cabinet Fermé !');
          args.element.setAttribute('title', ' Cabinet Fermé !');
          args.element.appendChild(span);
         }
         else if (this.StatutCabinet[i].libelle ==="Praticien absent"){
          // console.log("Praticien absent");
          let span: HTMLElement;
          span = document.createElement('span');
          span.setAttribute('class', 'e-icons highlight-day');
          addClass([args.element], ['special', 'e-day', 'birthday']);
          args.element.firstElementChild.setAttribute('title', ' Praticien absent !');
          args.element.setAttribute('title', '  Praticien absent !');
          args.element.appendChild(span);
         }
         else if (this.StatutCabinet[i].libelle ==="Cabinet Ouvert"){
          // console.log("Cabinet Ouvert");
          let span: HTMLElement;
          span = document.createElement('span');
          span.setAttribute('class', 'e-icons cabibnetouvert');
          // console.log("span",span.textContent);
          addClass([args.element], ['special', 'e-day', 'birthday']);
          args.element.firstElementChild.setAttribute('title', ' Cabinet Ouvert !');
          args.element.setAttribute('title', ' Cabinet Ouvert !');
          args.element.appendChild(span);
          
         }

     }
     else{
      //  console.log("dateTest"+dateTest+"--------"+"serviceDate"+serviceDate)
     }
    }





}


  buildMenuContextFauteuil() {

    this.menuItems[4].items = [];

    let objFauteuilMenu: MenuItemModel = {
      text: "",
      iconCss: "",
      id: "",

    }
    this.fauteuilDataSource.forEach(element => {
      element.startHour = "08:00";
      element.endHour = "20:00";
      objFauteuilMenu.id = element.Id.toString();
      objFauteuilMenu.text = element.Text;
      objFauteuilMenu.iconCss = "e-icons new";
      this.menuItems[4].items.push(objFauteuilMenu);
      objFauteuilMenu = {
        text: "",
        iconCss: "",
        id: "",
      }
    });
    var objFauteuilMenus: MenuItemModel = {
      text: "",
      iconCss: "e-icons new",
      id: "fRdv"
    }
    // this.menuItems[4].items.push(objFauteuilMenus);

    // this.menuObj.items[4].items.push(objFauteuilMenus)
    this.menuObj.items = this.menuItems;
    // this.menuObj.hideItems(['CabinetFermé', 'CabinetOuvert', 'PraticienAbsent'], true);

    this.CabinetMenu.items = this.CliniqueItems;
    // this.CabinetMenu.hideItems(['Add', 'AddNote', 'DeleteZone', 'status', 'fauteils', 'EditRecurrenceEvent', 'Delete' ], true);
    // this.CabinetMenu.hideItems(['Cabinet Fermé', 'Cabinet Ouvert', 'Praticien Absent','Supprimer'], true);

  }
  listAllPatientWithNextCC;
  async getallpatienwithNextcc() {

    await this.appointementService.getAllPatientWithNextCC().toPromise().then(res => {
      this.listAllPatientWithNextCC = res;
      // console.log("entreeeeeeeeeeeeeeeeeer");
    })
    this.fieldsGlobalSearch = { value: 'nomPatient' };

  }
  noteRdvDataSource: AppointementData[] = [];;
  async getNoteRdv(dateDebut, dateFin) {
    
    await this.noteRdvService.getNoteRdv(dateDebut, dateFin).toPromise().then(result => {
      
      // if (this.noteRdvDataSource == []){
        this.noteRdvDataSource = result;
        // console.log("this.noteRdvDataSource",this.noteRdvDataSource);
      // }
      this.onCreate();
    })

  }
  mapOfFauteuil = [];
  menuStatus;
  menuFauteuils;
  private getFauteils() {

    this.fauteuil.getFauteuil().subscribe(result => {

      // let fauteuilForMobile = result.filter(p => (p.Id != 3 && p.Id != 4));
      // this.fauteuilDataSource = this.isDesktop ? result : fauteuilForMobile;
      this.fauteuilDataSource = result;
      this.fauteuilFields = { value: "Id", text: "Text" };

      for (var i = 0; i < this.fauteuilDataSource.length; i++) {
        this.mapOfFauteuil.push({ "Id": this.fauteuilDataSource[i].Id, "Text": this.fauteuilDataSource[i].Text });

      }
      this.buildMenuContextFauteuil();
      this.menuStatus = this.menuItems[3].items;
      this.menuFauteuils = this.menuItems[4].items;

    }, error => console.error(error));

  }
  actesFields
  mapofActes = [];
  acteDataSource;

  private getActes() {
    this.acteService.getActe().subscribe(result => {
      this.acteDataSource = result;
      this.actesFields = { value: "ID_ACTE", text: "ACTE_LIBELLE" };
      for (var i = 0; i < result.length; i++) {
        this.mapofActes.push({ "ID_ACTE": result[i].id_ACTE, "ACTE_LIBELLE": result[i].acte_LIBELLE });
      }
    }, error => console.error(error));
  }
  closeModal() {
    this.router.navigateByUrl("/home");
  }
  public cssClass: string = "custom";

 

  private getZoneRestreinte(dateDebut: string) {
    let debutMatin = new Date(dateDebut);
    let FinMatin = new Date(dateDebut);
    let debutSoir = new Date(dateDebut);
    let finSoir = new Date(dateDebut);
    debutMatin.setHours(8);
    debutMatin.setMinutes(0);
    debutMatin.setSeconds(0);
    ///////////////////////////
    FinMatin.setHours(9);
    FinMatin.setMinutes(0);
    FinMatin.setSeconds(0);
    ///////////////////////////
    debutSoir.setHours(18);
    debutSoir.setMinutes(0);
    debutSoir.setSeconds(0);
    ///////////////////////////
    finSoir.setHours(20);
    finSoir.setMinutes(0);
    finSoir.setSeconds(0);
    ///////////////////////////
    for (var _i = 1; _i <= 5; _i++) {
      this.zoneRestreinte.push({ "Id": 1, "Subject": " ", "EndTime": formatDate(FinMatin, "yyyy-MM-dd HH:mm:ss", 'en-US'), "StartTime": formatDate(debutMatin, "yyyy-MM-dd HH:mm:ss", 'en-US'), "IsBlock": true, "FauteuilId": _i, color: -16777216 });
      this.zoneRestreinte.push({ "Id": 1, "Subject": " ", "EndTime": formatDate(finSoir, "yyyy-MM-dd HH:mm:ss", 'en-US'), "StartTime": formatDate(debutSoir, "yyyy-MM-dd HH:mm:ss", 'en-US'), "IsBlock": true, "FauteuilId": _i, color: -16777216 });
    }

  }
  loading;
  firstLoad: boolean = true;
  async ionViewWillEnter() {
    this.loading = await this.loadingController.create({
      message: 'Chargement...',
      spinner: 'crescent',
    });
    // await this.loading.present();
    if (this.firstLoad) {
      let tmpDateDebut: Date = new Date();

      let dateDebut = formatDate(tmpDateDebut, "yyyy-MM-dd HH:mm:ss", 'en-US');
      let dateDebutDuJour = dateDebut.substring(0, 10) + " 00:00:00";
      let dateFinDuJour = dateDebut.substring(0, 10) + " 23:59:00";

      await this.getAppointements(dateDebutDuJour, dateFinDuJour);
      await this.getAppointementsNow();
      await this.getParticienAffectation(dateDebutDuJour, dateFinDuJour);
      await this.getNoteRdv(dateDebutDuJour, dateFinDuJour);
      this.firstLoad = false;
    }
    this.loading.dismiss();
  }
  rdvBy;
  rdvtoday = 0;
  //  getPersonneById(id){

  //   this.userStaffService.GetPersonneById(id).toPromise().then(res => {
      
  //     var personne = res;
  //     // console.log("personne",personne);
  //     this.rdvBy = "par :" + personne.per_prenom + " " + personne.per_nom;
  //     console.log("rdvBy",this.rdvBy);
  //     this.rdvtoday= this.rdvtoday + 1;
  //     },error => console.error(error));
  //     return this.rdvBy

  // }
 
  GetPersoneName (array) {
    this.rdvtoday=0;
    for (let index = 0; index < array.length; index++) {
      if (array[index].perIdPersonne != null) {
        // console.log("Index",array[index].perIdPersonne);
        // console.log("return",this.getPersonneById(array[index].perIdPersonne));
        // var rdvby = this.getPersonneById(array[index].perIdPersonne);
        this.rdvtoday= this.rdvtoday + 1;
        this.userStaffService.GetPersonneById(array[index].perIdPersonne).toPromise().then(res => {
      
          var personne = res;
          // console.log("personne",personne);
        //   this.rdvBy = "par :" + personne.per_prenom + " " + personne.per_nom;
        //   console.log("rdvBy",this.rdvBy);
        //   this.rdvtoday= this.rdvtoday + 1;
        //   // console.log("name",name);
           this.rdvBy = "par :" + personne.per_prenom + " " + personne.per_nom;
        var name = {"RdvBy" : this.rdvBy};
        let newList = Object.assign(array[index],name);
       
        // console.log("newList",newList);
          },error => console.error(error));
        
        // array[index].push({"rdvBy": name})
        // console.log("newList",newList);
      }
      else{
        array[index].CategoryColor = "#9b9b9b";
      }
      
    }
  }


  async getAppointements(datedebut, datefin) {
    console.log("date",datedebut, datefin)
    await this.appointementService.getAppointementByDate(datedebut, datefin).toPromise().then(result => {
      this.data = result;
      console.log("this.data getAppointements",this.data);
      this.GetPersoneName(result);
      // this.getPersonneById(2);
      // this.data = this.data.concat(this.noteRdvDataSource);
      this.renderCell = true;
      this.eventSettings = {
        
        dataSource: this.data
      };
      this.eventSettings.enableTooltip = true;
      this.scheduleObj.rowAutoHeight = true;
      // console.log("dataAppointment",this.data);
      if (this.scheduleObj.activeView)
        this.scheduleObj.refreshEvents();

    }, error => console.error(error));
  }
  currentAppointements;
  fauteuilsChoisis = [];
  async getAppointementsNow() {
    this.fauteuilsChoisis = [];
    await this.appointementService.getCurrentAppointements().toPromise().then(result => {
      this.currentAppointements = result
      // console.log("currentAppointements",result);
      for (let i: number = 0; i < this.fauteuilDataSource.length; i++) {
        if (this.fauteuilDataSource[i].Id != 3 && this.fauteuilDataSource[i].Id != 4) {
          let patientAuFauteuil = this.currentAppointements.filter(p => (p.FauteuilId == this.fauteuilDataSource[i].Id))
          this.fauteuilsChoisis.push({ "Fauteuil": this.fauteuilDataSource[i].Text, "Patient": patientAuFauteuil });
        }
      }
    }, error => console.error(error));
  }
  public minutePerMillisecond: number = 60000;

  getWaitingTimeOnFauteuil(data): number {
    let arrivedDate = new Date(data.rdvArrivee);
    if (data.dateFauteuil == null) {
      let waitingTime = Math.floor((Date.now() - arrivedDate.getTime()) / this.minutePerMillisecond);
      return waitingTime;
    } else {
      let waitingTimeOnFauteuil = new Date(data.dateFauteuil);
      let waitingTime = Math.floor((Date.now() - waitingTimeOnFauteuil.getTime()) / this.minutePerMillisecond);
      return waitingTime;
    }
  }
  getDelayTime(data): number {
    let obj = this.currentAppointements.filter(patient => patient.Id == data.Id)
    let arrivedDate = new Date(data.rdvArrivee);
    let rdvDate = new Date(obj[0].StartTime);
    let delayTime = Math.floor((rdvDate.getTime() - arrivedDate.getTime()) / this.minutePerMillisecond);
    return delayTime;
  }

  getWaitingTime(data): number {
    let arrivedDate = new Date(data.rdvArrivee);
    if (data.dateFauteuil == null) {
      let waitingTime = Math.floor((Date.now() - arrivedDate.getTime()) / this.minutePerMillisecond);
      return waitingTime;
    } else {
      let waitingTimeOnFauteuil = new Date(data.dateFauteuil);
      let waitingTime = Math.floor((waitingTimeOnFauteuil.getTime() - arrivedDate.getTime()) / this.minutePerMillisecond);
      return waitingTime;
    }

  }

  //slit houre in 12
  public timeScale: TimeScaleModel = {
    enable: true,
    interval: 60,
    slotCount: 6,
    majorSlotTemplate: '#majorSlotTemplate',
    minorSlotTemplate: '#minorSlotTemplate'
  };
  dragStop() {
  }

  onItemDrag(event: any): void {

    if (event.name === 'drag' && event.event.target.dataset.groupIndex) {
      if (this.scheduleObj.getResourcesByIndex(event.event.target.dataset.groupIndex)) {
        this.fauteilIdFromDrag = this.scheduleObj.getResourcesByIndex(event.event.target.dataset.groupIndex).resourceData.Id;
        // this.scheduleObj.refreshEvents();
      }
    }
    if (this.scheduleObj.isAdaptive) {
      let classElement: HTMLElement = this.scheduleObj.element.querySelector('.e-device-hover');
      if (classElement) {
        classElement.classList.remove('e-device-hover');
        // this.scheduleObj.refreshEvents();
      }
      if (event.target.classList.contains('e-work-cells')) {
        addClass([event.target], 'e-device-hover');
        // this.scheduleObj.refreshEvents();
      }
    }
    if (document.body.style.cursor === 'not-allowed') {
      document.body.style.cursor = '';
      this.scheduleObj.refreshEvents();
    }
    if (event.name === 'nodeDragging') {
      let dragElementIcon: NodeListOf<HTMLElement> =
        document.querySelectorAll('.e-drag-item.treeview-external-drag .e-icon-expandable');
      for (let i: number = 0; i < dragElementIcon.length; i++) {
        dragElementIcon[i].style.display = 'none';
      }
      
    }
    
    
  }


  resourceDetails: ResourceDetails;
  cellData: CellClickEventArgs;
  acteColor;
  acteLibelleDraged;
  onTreeDragStop(event: DragAndDropEventArgs): void {
    this.fauteilIdFromDrag = undefined;

    let treeElement: Element = <Element>closest(event.target, '.e-treeview');
    let classElement: HTMLElement = this.scheduleObj.element.querySelector('.e-device-hover');


    if (classElement) {
      classElement.classList.remove('e-device-hover');
    }
    if (!treeElement) {
      event.cancel = true;
      let scheduleElement: Element = <Element>closest(event.target, '.e-content-wrap');
      if (scheduleElement) {
        let treeviewData: { [key: string]: Object }[] =
          this.treeObj.fields.dataSource as { [key: string]: Object }[];
        if (event.target.classList.contains('e-work-cells')) {

          this.filteredData = treeviewData.filter((item: any) => item.idPrevisionnelle === parseInt(event.draggedNodeData.id as string, 10));
          this.cellData = this.scheduleObj.getCellDetails(event.target);
          let duree = this.filteredData[0].acte_durestd;
          this.cellData.endTime.setMinutes(this.cellData.startTime.getMinutes() + +duree);
          this.dateFin = this.cellData.endTime;
          this.resourceDetails = this.scheduleObj.getResourcesByIndex(this.cellData.groupIndex);
          this.selectedFauteuil = this.resourceDetails.resourceData.Id;
          this.nextApp["id_fauteuil"] = +this.resourceDetails.resourceData.Id;
          this.traceApp["id_fauteuil"] = +this.resourceDetails.resourceData.Id;
          this.traceApp["rdv_date"] = formatDate(this.cellData.startTime, "yyyy-MM-dd HH:mm:ss", 'en-US');
          this.acteColor = this.acteDataSource.find(f => f.id_ACTE === this.filteredData[0].idActe).categoryColor;
          this.acteLibelleDraged = this.acteDataSource.find(f => f.id_ACTE == this.filteredData[0].idActe).acte_LIBELLE;


          let eventData: { [key: string]: Object } = {
            Subject: this.filteredData[0].nomPatient,
            StartTime: this.cellData.startTime,
            EndTime: this.dateFin,
            IsAllDay: this.cellData.isAllDay,
            Acte: this.filteredData[0].ActeLibelle,
            FauteuilId: this.resourceDetails.resourceData.Id,
            FauteilText: this.resourceDetails.resourceData.Text,
            FauteuilData: this.mapOfFauteuil,
            CategoryColor: this.acteColor,
            ActeLibelle: this.acteLibelleDraged,

            //  ConsultantID: resourceDetails.resourceData.Id
          };
          this.isTreeItemDropped = true;
          this.draggedItemId = event.draggedNodeData.id as string;
          this.scheduleObj.openEditor(eventData, 'Add', true);

        } else {
          // this.scheduleObj.quickPopup.openRecurrenceValidationAlert('Add');

        }
      }
    }
  }
  public minDate: Date = new Date('5/5/2020 8:00 AM');
  public maxDate: Date = new Date('5/31/2020 7:00 AM');

  async onActionBegin(args: ActionEventArgs & ToolbarActionArgs) {
    // console.log("onActionBegin",args);
    if (args.requestType === 'toolbarItemRendering') {
      let refreshButton: ItemModel = {
        align: 'Right', text: 'Rafraîchir', cssClass: 'e-schedule-user-icon',
        click: () => { this.show(); }
      };
      args.items.push(refreshButton);
    }
    if (args.requestType === 'eventCreate' && this.isNote) {
      var notes = args.data[0].Subject;
      // var startDateTime = args.data[0].StartTime
      // var endDate = args.data[0].EndTime;
      this.insertRdvNote(notes);
      // console.log("note",notes);
    }
    if (args.requestType === 'eventCreate' && this.isZone) {
      const selectedCells: Element[] = this.scheduleObj.getSelectedElements();
      const activeCellsData: CellClickEventArgs =
        this.scheduleObj.getCellDetails(selectedCells.length > 0 ? selectedCells : this.selectedTarget);
      let resourceDetails: ResourceDetails = this.scheduleObj.getResourcesByIndex(activeCellsData.groupIndex);
      +resourceDetails.resourceData.Id
      let eventData: { [key: string]: Object } = {
        startTime: args.data[0].StartTime,
        endTime: args.data[0].EndTime,
        FauteuilId: +resourceDetails.resourceData.Id,
        isZone: true
      };
      this.onAddClick(eventData);
    }

    if (args.requestType === 'eventCreate' || args.requestType === 'eventChange') {

      let data = <any>args.data;

      if (args.requestType === 'eventCreate' && this.isTreeItemDropped === false && !this.isNote) {
        data = <any>args.data[0];
        this.InsertNextRDVFromForm();
      } else if (args.requestType === 'eventChange' && !data.isAllDay && !this.isTreeItemDropped) {
        data = <any>args.data;
        await this.updateRdv(data);
      } else if (args.requestType === 'eventCreate') {
        data = <any>args.data[0];
      }
      // if (data.isAllDay || !this.scheduleObj.isSlotAvailable(data.StartTime as Date, data.EndTime as Date, data.FauteuilId)) {
      //   args.cancel = true;
      // }
    }
    ///////////////////////////////////    drop next appointement dragged   //////////////////////////////////////////////////////////
    if (args.requestType === 'eventCreate' && this.isTreeItemDropped) {
      let data = <any>args.data;
      this.InsertNextRDV(data);
      let treeViewdata: { [key: string]: Object }[] = this.treeObj.fields.dataSource as { [key: string]: Object }[];
      const filteredPeople: { [key: string]: Object }[] =
        treeViewdata.filter((item: any) => item.idPrevisionnelle !== parseInt(this.draggedItemId, 10));
      this.treeObj.fields.dataSource = filteredPeople;
      let elements: NodeListOf<HTMLElement> = document.querySelectorAll('.e-drag-item.treeview-external-drag');

      for (let i: number = 0; i < elements.length; i++) {
        remove(elements[i]);
      }

    } else if (args.requestType === 'eventChange' && this.isTreeItemDropped) {
      let data = <any>args.data;
      var idRDV = args.data['Id'] ? args.data['Id'] : args.data[0].Id;
      var objToDelete = this.data.find(d => d.Id == idRDV);
      var index = this.data.indexOf(objToDelete);
      this.data.splice(index, 1);
      this.eventSettings = {
        dataSource: this.data
      };
      this.scheduleObj.refreshEvents();
      await this.updateRdv(data);
      this.isTreeItemDropped = false;
    }

    if (args.requestType === 'eventRemove' && !args.data['IsAllDay']) {
      var idRDV = args.data['Id'] ? args.data['Id'] : args.data[0].Id;
      this.appointementService.removeRDV(idRDV).subscribe(() => {
      });

    }
    else if (args.requestType === 'eventRemove' && args.data[0].IsAllDay) {
      var idRDVNote = args.data[0].Id;
      this.noteRdvService.removeRDVNote(idRDVNote).toPromise();

    }
    this.selectedFauteuil = -1;
    this.isNote = false;
  }
  InsertNextRDV(app) {

    this.appointementService.getNextIdRdv().subscribe(id => {
      this.nextApp["id_rdv"] = id;
      this.nextApp["id_personne"] = +this.filteredData[0].idPersonne;
      this.nextApp["id_acte"] = +this.filteredData[0].idActe;
      this.nextApp["rdvDuree"] = +this.filteredData[0].acte_durestd;
      this.nextApp["rdvDate"] = formatDate(this.daterdv, "yyyy-MM-dd HH:mm:ss", 'en-US');
      this.nextApp["rdvComm"] = app[0].Commentaire;

      var idFauteuil;
      var TextFauteuil;
      if (this.fauteilIdFromDrag === undefined) {
        this.nextApp["id_fauteuil"] = +this.resourceDetails.resourceData.Id;
        idFauteuil = this.resourceDetails.resourceData.Id;
        this.selectedFauteuil = this.resourceDetails.resourceData.Id;
        TextFauteuil = this.resourceDetails.resourceData.Text;

      } else {
        this.nextApp["id_fauteuil"] = this.fauteilIdFromDrag;
        idFauteuil = this.fauteilIdFromDrag;
        this.selectedFauteuil = this.fauteilIdFromDrag;
        TextFauteuil = this.fauteuilDataSource.find(f => f.Id === this.fauteilIdFromDrag).Text;

      }
      this.nextApp["idCommClinique"] = +this.filteredData[0].idComm;
      // console.log(this.nextApp);
      this.appointementService.InsertNextRDV(this.nextApp).toPromise();

      this.InsertRDVTrace(this.nextApp["id_rdv"])
      this.updateCommCliniqueIdRDV(this.nextApp["id_rdv"]);

      let eventData: { [key: string]: Object } = {
        Id: id,
        Subject: this.filteredData[0].nomPatient,
        StartTime: this.nextApp["rdvDate"],
        EndTime: this.onPopOpenFieldInformation.EndTime, //this.dateFin
        IsAllDay: this.cellData.isAllDay,
        CategoryColor: this.acteColor,
        FauteuilId: idFauteuil,
        FauteilText: TextFauteuil,
        FauteuilData: this.mapOfFauteuil,
        ActeLibelle: this.acteLibelleDraged,
        Commentaire: this.nextApp["rdvComm"]
      };

      this.data.push(eventData);

      this.eventSettings = {
        dataSource: this.data
      };
      this.scheduleObj.refreshEvents();
    });
  }


  InsertRDVTrace(id_rdv) {
    this.traceApp["id_rdv"] = id_rdv;
    this.traceApp["trace_date"] = formatDate(this.dateDebut, "yyyy-MM-dd HH:mm:ss", 'en-US');
    this.traceApp["trace_comment"] = "Insert";
    this.traceApp["id_acte"] = +this.filteredData[0].idActe;
    this.traceApp["id_personne"] = +this.filteredData[0].idPersonne;
    this.traceApp["rdvDuree"] = +this.filteredData[0].acte_durestd;

    if (this.fauteilIdFromDrag === undefined) {
      this.traceApp["id_fauteuil"] = +this.resourceDetails.resourceData.Id;

    } else {
      this.traceApp["id_fauteuil"] = this.fauteilIdFromDrag;
    }
    this.appointementTraceService.InsertRDVTrace(this.traceApp).toPromise();
  }

  updateCommCliniqueIdRDV(id_RDV) {
    this.comm["id"] = +this.filteredData[0].idComm;
    this.comm["id_RDV"] = id_RDV;

    this.commCliniqueService.updateCommCliniqueIdRDV(this.comm).toPromise();
  }
  private eResourceRendered = false
  getFauteuilName(value: ResourceDetails): string {
    var _value = (value as ResourceDetails).resourceData[(value as ResourceDetails).resource.textField] as string;
    if (!this.eResourceRendered) {
      let tmpDateDebut: Date = new Date();
      tmpDateDebut.setHours(0);
      tmpDateDebut.setMinutes(0);
      this.dateDebut = formatDate(tmpDateDebut, "yyyy-MM-dd HH:mm:ss", 'en-US');
      let tmpDateFin: Date = new Date();
      tmpDateFin.setHours(23);
      tmpDateFin.setMinutes(59);
      this.dateFin = formatDate(tmpDateFin, "yyyy-MM-dd HH:mm:ss", 'en-US');

      // this.getNoteRdv(this.dateDebut, this.dateFin);
      this.eResourceRendered = true;
    }
    return _value
  }

  getFauteuilImageName(value: ResourceDetails): string {
    return this.getFauteuilName(value).toLowerCase();
  }

  getFauteuilDesignation(value: ResourceDetails): string {
    var header = "";
    if (this.affectation.length > 0)
      header = this.buildAffectation(value);
    return header;
  }
  buildAffectation(value: ResourceDetails) {
    var affectations = "";
    if (this.affectation)
      this.affectation.forEach(item => {
        if (item.id_FAUTEUIL === (value as ResourceDetails).resourceData.Id) {
          affectations += "\n" + item.prenom.charAt(0) + "." + item.nom;
        }
      });
      
    // console.log("affectation",affectations);
    return affectations;
  }

  GetUserPointage(id,array){
    // console.log("id one",id);
    // console.log("array",array);
  for (let index = 0; index < array.length ; index++) {
    // console.log("dUtilisateur",array[index][0].idUtilisateur);
    // console.log("id",id);
    if (array[index][0].idUtilisateur === id) {
      // console.log("return",array[index]);
      return array[index];
    }
  }

  }
  verifUserPointage(){


  }

  buildAffectationTooltip(value: ResourceDetails, tab, tabPointage) {
    var affectations = "";
    let x =0
    this.UserColor = false;
    if (this.affectation)
      this.affectation.forEach(item => {
        // console.log("x",x++);
        // console.log("item",item);
        
        if (item.id_FAUTEUIL === (value as ResourceDetails).resourceData.Id) {
          affectations += "\n" + item.prenom.charAt(0) + "." + item.nom;
          if (tab.length > 0) affectations += "\n Horaire de travail :";
          for (let m: number = 0; m < tab.length; m++) {
            affectations += "\n de " + tab[m].debut.substring(11, 16) + " à " +
              tab[m].fin.substring(11, 16);
          }
          var PointageUser = this.pointagePraticien(item.id_USER,item.affecte_FROM);
        //  console.log("this.ArrayPointage",this.ArrayPointage)
          // tabPointage = this.pointageParticien;
          // console.log("tabPointage",tabPointage);
          tabPointage = [];
          if (this.ArrayPointage.length != 0) {
            // console.log(this.GetUserPointage(item.id_USER,this.ArrayPointage));
            tabPointage = this.GetUserPointage(item.id_USER,this.ArrayPointage);
          }
          // console.log(tabPointage);
          if (tabPointage != null) {
            if (tabPointage.length > 0) affectations += "\n Pointage :";
              for (let n: number = 0; n < tabPointage.length; n++) {
                this.UserColor = true;
                let actePointage = tabPointage[n].entreeSortie == 0 ? "l'entrée" : "la sortie";
                affectations += "\n \t à " + actePointage + " " + tabPointage[n].datePointage.substring(11, 16)
            }
          }
        }
        // console.log("affectations",affectations);
      });
      // console.log("affectations",affectations);
    return affectations;
  }


  // buildAffectationTooltip(value: ResourceDetails, tab, tabPointage) {
  //   var affectations = "";
  //   if (this.affectation[0])
  //     this.affectation[0].forEach(item => {
  //       if (item.id_FAUTEUIL === (value as ResourceDetails).resourceData.Id) {
  //         affectations += "\n" + item.prenom.charAt(0) + "." + item.nom;
  //         if (tab.length > 0) affectations += "\n Horaire de travail :";
  //         for (let m: number = 0; m < tab.length; m++) {
  //           affectations += "\n de " + tab[m].debut.substring(11, 16) + " à " +
  //             tab[m].fin.substring(11, 16);
  //         }
  //         if (tabPointage.length > 0) affectations += "\n Pointage :";
  //         for (let n: number = 0; n < tabPointage.length; n++) {
  //           let actePointage = tabPointage[n].entreeSortie == 0 ? "l'entrée" : "la sortie";
  //           affectations += "\n \t à " + actePointage + " " + tabPointage[n].datePointage.substring(11, 16)
  //         }
  //       }
  //     });
  //   return affectations;
  // }

  getMajorTime(date: Date): string {
    return this.instance.formatDate(date, { skeleton: 'Hm' });
  }
  getMinorTime(date: Date): string {
    return this.instance.formatDate(date, { skeleton: 'ms' }).replace(':00', '');
  }
  onClear(): void {
  }
  renderCell: boolean = false;
  onActionComplete(args: ToolbarActionArgs): void {
    // console.log("onActionComplete",args);
  }
  renderDayCell(args: any) {
    // console.log("onActionComplete",args);
  }
  onActionFailure(event): void {
    // console.log("onActionFailure",event);
  }
  onCellDoubleClick(event): void {
    // console.log("onCellDoubleClick",event);
  }
  onCellClick(event): void {
    // console.log("onCellClick",event);
  // console.log(event);
  }
  onNavigating(event) {

    console.log("onNavigating",event);
    // console.log("this.data on",this.data)
    this.data=[];
    this.ArrayPointage =[];
    // console.log("onNavigating",event.value);
    // this.selectedDate =event.currentDate;
    this.dayHeaders = [];
    this.toastObj.hide('All');
    if (event.currentDate != undefined) {
      let dateDebut = formatDate(event.currentDate, "yyyy-MM-dd HH:mm:ss", 'en-US');
    // }
    
    let dateDebutDuJour = dateDebut.substring(0, 10) + " 00:00:00";
    let dateFinDuJour = dateDebut.substring(0, 10) + " 23:59:00";
    // this.dateValue = new Date(event.value);
    // this.selectedDate = this.dateValue;
    // this.selectedDate.setDate(this.dateValue);

    // this.selectedDate = (new Date(this.dateValue));

    // console.log("selectedDate",this.selectedDate);
    // console.log("DateValue",this.dateValue);
    // console.log("dateDebutDuJour",dateDebutDuJour);
    // console.log("dateFinDuJour",dateFinDuJour);
    // this.onLoad(event);
    console.log(dateDebutDuJour, dateFinDuJour);

    this.getNoteRdv(dateDebutDuJour, dateFinDuJour);
    this.getParticienAffectation(dateDebutDuJour, dateFinDuJour);
    this.getAppointements(dateDebutDuJour, dateFinDuJour);
    }

  }
  creationZoneRestreinte(particienAuFauteuil, dateDebutDuJour, tabHour) {
// console.log("particienAuFauteuil",particienAuFauteuil);
// console.log("dateDebutDuJour",dateDebutDuJour);

    let resourceDetails: ResourceDetails;
    let idfauteuil;
    let startReel = dateDebutDuJour.replace("00:00:00", "08:00:00");// global
    let endReel = dateDebutDuJour.replace("00:00:00", "20:00:00");// global
    let id = -1;
    // console.log("tabHour",tabHour);
    if (tabHour) {
      resourceDetails = this.scheduleObj.getResourcesByIndex(particienAuFauteuil.id_FAUTEUIL -1 );
      idfauteuil = +resourceDetails && +resourceDetails.resourceData.Id == particienAuFauteuil.id_FAUTEUIL ?
        +resourceDetails.resourceData.Id : particienAuFauteuil.id_FAUTEUIL;
      for (let i: number = 0; i < tabHour.length; i++) {
        // console.log("tabHour",tabHour[i]);
        tabHour[i].debut = dateDebutDuJour.replace("00:00:00", tabHour[i].debut + ':00');
        tabHour[i].fin = dateDebutDuJour.replace("00:00:00", tabHour[i].fin + ':00');
      }
      let lengthTab = tabHour.length;
      let startOfWork;
      let endOfWork;
      if (lengthTab > 0) {
        startOfWork = tabHour[0].debut;
        endOfWork = tabHour[lengthTab - 1].fin;
      }

      

      if (startReel && startOfWork && startReel != startOfWork) {
       
        let zr: any = {
          Id: id,
          Subject: " ",
          StartTime: startReel,
          EndTime: startOfWork,
          IsBlock: true,
          FauteuilId: idfauteuil,
          color: "#696969",
        }
        this.data.push(zr);
      }
      if (endReel && endOfWork && endReel != endOfWork) {
        let zr: any = {
          Id: id,
          Subject: " ",
          StartTime: endOfWork,
          EndTime: endReel,
          IsBlock: true,
          FauteuilId: idfauteuil,
          color: "#696969",
        }
        this.data.push(zr);
      }
      for (let k: number = 0; k < lengthTab - 1; k++) {
        let startZR = tabHour[k].fin;
        let endZR = tabHour[k + 1].debut;
        let zr: any = {
          Id: id,
          Subject: " ",
          StartTime: startZR,
          EndTime: endZR,
          IsBlock: true,
          FauteuilId: idfauteuil,
          color: "#696969",
        }
        this.data.push(zr);
      }
      // console.log("lengthTab",lengthTab)
      var startHour =particienAuFauteuil.affecte_FROM.substr(11,2)
      var EndHour =particienAuFauteuil.affecte_TO.substr(11,2)
      // console.log("startHour",startHour)
      // console.log("EndHour",EndHour)

         if(EndHour == 14){
          let zr: any = {
            Id: id,
            Subject: " ",
            StartTime: particienAuFauteuil.affecte_TO,
            EndTime: endOfWork,
            IsBlock: true,
            FauteuilId: idfauteuil,
            color: "#696969",
          }
          // console.log("zr",zr)
          this.data.push(zr);
         }
         else{
          // console.log("affecte_FROM",particienAuFauteuil.affecte_FROM)
          // console.log("affecte_TO",particienAuFauteuil.affecte_TO)
          let zr: any = {
            Id: id,
            Subject: " ",
            StartTime: startOfWork,
            EndTime: particienAuFauteuil.affecte_FROM,
            IsBlock: true,
            FauteuilId: idfauteuil,
            color: "#696969",
          }
          // console.log("zr",zr)
          this.data.push(zr);
         }
        // console.log("startOfWork",startOfWork)
        // console.log("endOfWork",endOfWork);
      
    } else {
      // console.log("particienAuFauteuil",particienAuFauteuil)
      // console.log("startReel",startReel);
      // console.log("endReel",endReel);
      // console.log("this.data avant",this.data)
      idfauteuil = particienAuFauteuil;
      let zr: any = {
        Id: id,
        Subject: " ",
        StartTime: startReel,
        EndTime: endReel,
        IsBlock: true,
        FauteuilId: idfauteuil,
        color: "#696969",
      }
      this.data.push(zr);
      
    }
    
    // console.log("this.data",this.data)
    this.eventSettings = {
      dataSource: this.data
    };
    if (this.scheduleObj.activeView)
      this.scheduleObj.refreshEvents();
      // console.log("shedule refreshed");
  }

  // searchForArray(haystack, needle){
  //   var i, j, current;
  //   for(i = 0; i < haystack.length; i++){
  //     if(needle.length === haystack[i].length){
  //       current = haystack[i];
  //       for(j = 0; j < needle.length && needle[j] === current[j]; j++);
  //       if(j === needle.length)
  //       // console.log(i)
  //         return i;
  //     }
  //   }
  //   return -1;
  // }
  


  public pointageParticien;
  async pointagePraticien(id, today) {
    let start = today.substring(0, 10) + " 00:00:00";
    let end = today.substring(0, 10) + " 23:00:00";
    // let start = "2019-06-12 00:00:00";
    // let end  = "2019-06-12 23:00:00";
    await this.pointageService.getPointageByUserByID(id, start, end).toPromise().then(res => {
      this.pointageParticien = res;
      if (res.length != 0) {
        this.ArrayPointage.push(this.pointageParticien);
      }
    });
    
  }

  async pointagePersonne(id, today) {
    let start = today.substring(0, 10) + " 00:00:00";
    let end = today.substring(0, 10) + " 23:00:00";
    // let start = "2019-06-12 00:00:00";
    // let end  = "2019-06-12 23:00:00";
    await this.pointageService.getPointageByUserByID(id, start, end).toPromise().then(res => {
      this.ArrayPointage = res;

     
    });
    
    
    
  }
  async getHoraireTravail(id, year, dayNumber, weekNumber, particienAuFauteuil, dateDebutDuJour) {
    this.tabHoraires = [];
    let workInDay;
    await this.horaireTravailService.getListeHorraireReelByIdUser(id, year, weekNumber).toPromise().then(
      async res => {
        // console.log("ressss",res)
        workInDay = res.filter(p => (p.dayNum == dayNumber));
        let k = 0;
        while (k + 1 <= workInDay.length) {
          let startHour = workInDay[k].startTime.substring(0, 5);
          let endHour = workInDay[k].endTime.substring(0, 5);
          this.tabHoraires.push({ debut: startHour, fin: endHour });
          k++;
        }
        // console.log("this.tabHoraires",this.tabHoraires)
        this.creationZoneRestreinte(particienAuFauteuil, dateDebutDuJour, this.tabHoraires);
        let tabForTooltip = this.tabHoraires;
        let tabPointage = this.pointageParticien;

        await this.dayHeaders.forEach(element => {
          var ele: HTMLInputElement = element;
          // console.log("element",element);
          var fauteuil: ResourceDetails = this.scheduleObj.getResourcesByIndex(element.cellIndex);
          ele.innerText = this.buildAffectation(fauteuil);
          ele.title = this.buildAffectationTooltip(fauteuil, tabForTooltip, tabPointage);
          ele.style.color = this.UserColor === true ? "green" : "red";
          this.tabHoraires = [];
          this.pointageParticien = [];
        });
      });
  }
  private getAffecations(dateDebutDuJour, dateFinDuJour) {
    this.affectation = [];
    console.log(dateDebutDuJour, dateFinDuJour);
    this.fauteuil.getAffecations(dateDebutDuJour, dateFinDuJour).subscribe(result => {
      this.affectation = result;
       console.log(" this.affectation",this.affectation);
      this.dayHeaders.forEach(element => {
        var ele: HTMLInputElement = element;
        var fauteuil: ResourceDetails = this.scheduleObj.getResourcesByIndex(element.cellIndex);
        ele.innerText = this.buildAffectation(fauteuil);
      });
      
    }, error => console.error(error));
  }

  public tabHoraires = [];
  async getParticienAffectation(dateDebutDuJour, dateFinDuJour) {
    this.affectation = [];
    console.log(dateDebutDuJour, dateFinDuJour);
    
    await this.fauteuil.getAffecations(dateDebutDuJour, dateFinDuJour).subscribe(async result => {
      this.affectation.push(result);
      // console.log("affectation",this.affectation);
      if (this.renderCell) {
        // console.log("this.fauteuilDataSource",this.fauteuilDataSource);
        for (let i: number = 0; i < this.fauteuilDataSource.length; i++) {
          if (this.fauteuilDataSource[i].Text === "SECRETARIAT" || this.fauteuilDataSource[i].Text === "RADIO"
          ) return;
          let particienAuFauteuil = result.find(p => (p.id_FAUTEUIL == this.fauteuilDataSource[i].Id));
          if (particienAuFauteuil) {
            let id = particienAuFauteuil.id_USER;
            let year = parseInt(particienAuFauteuil.affecte_FROM.toString().substring(0, 4));
            let month = parseInt(particienAuFauteuil.affecte_FROM.toString().substring(5, 7)) - 1;
            let day = parseInt(particienAuFauteuil.affecte_FROM.toString().substring(8, 10));
            let weekNumber;
            let dayNumber;
            await this.dateDataService.getWeekNumber(day, month, year).then(res => { weekNumber = res; });
            await this.dateDataService.getdayNumInWeek(day, month, year).then(res => { dayNumber = res; });
            await this.pointagePraticien(id, particienAuFauteuil.affecte_FROM.toString());
            // console.log("particienAuFauteuil",particienAuFauteuil);
            await this.getHoraireTravail(id, year, dayNumber, weekNumber, particienAuFauteuil, dateDebutDuJour);
          }
          else {
            this.creationZoneRestreinte(this.fauteuilDataSource[i].Id, dateDebutDuJour, null);// pas de praticien
          }

        }
      }
    }, error => console.error(error));
  }
  onDestroyed(event): void {
    console.log("onDestroyed",event);
  }
  onEventClick(event): void {
    console.log("onEventClick",event);

  }
  popUpOpenStatus;
  onPopOpenFieldInformation;
  EditEventInfoMobile;
  dataPatient;
  enableDisableScrollbar(enable) {

    const scheudler: HTMLCollectionOf<Element> = document.getElementsByClassName('e-content-wrap') as HTMLCollectionOf<Element>;
    const table = scheudler[0] as HTMLElement;
    if (enable) table.style.overflow = "";
    else table.style.overflow = "hidden";
  }
  public onPopupClose(args: PopupCloseEventArgs): void {
    // console.log("onPopupClose",args);
  }
  selectedRdv;
  public onPopupOpen(args: PopupOpenEventArgs): void {
    // console.log("onPopupOpen",args);
    this.enableDisableScrollbar(true);
    if (args.type === 'EditEventInfo' && !this.desktop) {
      args.cancel = true;
    }
    let dataNote: any = args.data;

    if (args.type === 'QuickInfo') {
      args.cancel = true;
      var data: any = args.data;
      this.dataPatient = data;
      if (!data.Id) {
        args.cancel = true;
      }

    }
    if (args.type === 'ViewEventInfo') {
      var data: any = args.data;
      this.dataPatient = data;
      if (!data.Id) {
        args.cancel = true;
      }
    }
    this.onPopOpenFieldInformation = args.data;
    this.selectedFauteuil = this.onPopOpenFieldInformation.FauteuilId;
    this.dateFin = this.onPopOpenFieldInformation.EndTime;
    if (!this.onPopOpenFieldInformation.Id && !this.isDesktop)
      this.dateFin = undefined
    this.popUpOpenStatus = args.type;
    if (args.type === 'Editor' && dataNote.isAllDay) {
      this.isNote = true;
    }
    if (args.type === 'Editor' && !dataNote.isAllDay) {
      this.selectedRdv = dataNote;
    }
    if (args.type == 'DeleteAlert') {
      (args.element as HTMLElement).style.width = '70vw';
    } if (args.type == 'ValidationAlert') {
      // alert msg
      document.getElementById('scheduleObjQuickDialog_dialog-content').innerHTML =
        "Les RDVs ne peuvent pas être planifiés à l'indisponibilité des praticiens";
      (args.element as HTMLElement).style.width = '70vw';
    }
  }

  public dateParser(data: string) {
    return new Date(data);
  }
  public onEventRendered(args: EventRenderedArgs): void {
    // console.log("onEventRendered",args.data);
    let categoryColor: string = args.data.CategoryColor as string;
    if (!args.element || !categoryColor) {
      return;
    }
    if (args.type === "event") {
      var status: number = +args.data.rdvStatut;
      var localisation: number = +args.data.localisation;
      switch (status) {
        case 4: args.element.style.textDecoration = "line-through"; break;
        case 0: args.element.style.backgroundColor = categoryColor; break;
        case 1: args.element.style.opacity = '0.5'; break
      }
    }
    if (this.currentView !== 'Day' && args.type === "event") {
      (args.element.firstChild as HTMLElement).style.borderLeftColor = categoryColor;
    } else {
      args.element.style.backgroundColor = categoryColor;
    }
    switch (args.data.EventType) {
      case 'Requested':
        (args.element as HTMLElement).style.backgroundColor = '#F57F17';
        break;
      case 'Confirmed':
        (args.element as HTMLElement).style.backgroundColor = '#7fa900';
        break;
      case 'New':
        (args.element as HTMLElement).style.backgroundColor = '#8e24aa';
        break;
    }
  }
  startTimeNote;
  endTimeNote;
  onChangeStart(args: ChangeEventArgs): void {
    this.startTimeNote = args.value;

  }
  onChangeEnd(args: ChangeEventArgs): void {
    this.endTimeNote = args.value;

  }
  daterdv;
  rdvEnd;
  onChangedaterdv(args: ChangeEventArgs): void {
    this.daterdv = args.value;
    let diff = this.daterdv.getTime() - this.selectedRdv.StartTime.getTime();
    let minutesLater = new Date(this.selectedRdv.EndTime.getTime() + diff);
    // if(this.daterdv != this.selectedRdv.StartTime)
    this.rdvEnd = minutesLater
  }
  //////////////////////////////  search event   ///////////////////////
  globalSearchList;
  fieldsGlobalSearch: Object = { value: 'nomPatient' };
  public text: string = "chercher un patient";
  public sorting: string = 'Ascending';
  public filterType: string = 'StartsWith';

  globalSearch(args: KeyboardEvent): void {
    let searchString: string = (args.target as HTMLInputElement).value;

    this.globalSearchList = null;
    if (searchString !== '') {
      this.appointementService.getPatientWithNextCCWeb(searchString)
        .subscribe(results => {
          this.globalSearchList = results;
          this.fieldsGlobalSearch = { value: 'nomPatient' };
          this.field = { dataSource: results, id: 'idPrevisionnelle', text: 'nomPatient', couleur: 'acteCouleur' };
        });
    }
  }
  private selectionTarget: Element;
  private onAddClick(eventData) {
    if (eventData.isZone) {
      this.nextApp["id_fauteuil"] = +eventData.FauteuilId;
      this.zoneRestrienteService.getNextIdZoneRestreinte().subscribe(next => {
        let nextId = next;
        let z: any = {
          Id: nextId,
          Subject: " ",
          StartTime: this.datePipe.transform(eventData.startTime, 'yyyy-MM-dd HH:mm:ss'),
          EndTime: this.datePipe.transform(eventData.endTime, 'yyyy-MM-dd HH:mm:ss'),
          IsBlock: true,
          FauteuilId: eventData.FauteuilId,
          color: -16777216
        };
        this.zoneRestrienteService.saveZoneRestriente(z).subscribe(zr => {
          this.data.push(zr);
          this.eventSettings = {
            dataSource: this.data
          };
          this.scheduleObj.refreshEvents();
        });
      });


    } else {
      let resourceDetails: ResourceDetails = this.scheduleObj.getResourcesByIndex(eventData.groupIndex);
      this.nextApp["id_fauteuil"] = +resourceDetails.resourceData.Id;
      this.zoneRestrienteService.getNextIdZoneRestreinte().subscribe(next => {
        let nextId = next;
        let z: any = {
          Id: nextId,
          Subject: "",
          StartTime: this.datePipe.transform(eventData.startTime, 'yyyy-MM-dd HH:mm:ss'),
          EndTime: this.datePipe.transform(eventData.endTime, 'yyyy-MM-dd HH:mm:ss'),
          IsBlock: true,
          FauteuilId: +resourceDetails.resourceData.Id,
          color: -16119285
        };

        this.zoneRestrienteService.saveZoneRestriente(z).subscribe(zr => {

          this.data.push(zr);
          this.eventSettings = {
            dataSource: this.data
          };
          this.scheduleObj.refreshEvents();
        });
      });
    }
  }
  public onCloseClick(): void {
    this.scheduleObj.quickPopup.quickPopupHide();
  }

  fauteilIdFromDrag;
  public onChangeDropDown(args: any): void {
    if (args.value !== null) {
      this.fauteilIdFromDrag = args.value;
    }
    if (this.fauteilIdFromDrag !== null) {
      this.fauteilsIdFromAutoComplete = this.fauteilIdFromDrag
    } else {
      this.fauteilsIdFromAutoComplete = this.onPopOpenFieldInformation.FauteuilId;
    }
    args.itemData = null;
    // update the text and value property values in property panel based on selected item in DropDownList
  }
  public selectedTarget: Element;
  selectedZoneRestreinte;
  eventObj: { [key: string]: Object };
  fauteuilActuel: string;

  closeMenu(args) {
    this.enableDisableScrollbar(true)
  }
  onContextMenuBeforeOpen(args: any): void {
    const newEventElement: HTMLElement = document.querySelector('.e-new-event') as HTMLElement;
    this.enableDisableScrollbar(false);
    if (newEventElement) {
      remove(newEventElement);
      removeClass([document.querySelector('.e-selected-cell')], 'e-selected-cell');
    }
    const targetElement: HTMLElement = <HTMLElement>args.event.target;
    if (closest(targetElement, '.e-contextmenu')) {
      return;
    }
    this.selectedTarget = closest(targetElement, '.e-appointment,.e-block-appointment,.e-work-cells,' +
      '.e-vertical-view .e-date-header-wrap .e-all-day-cells,.e-vertical-view .e-date-header-wrap .e-header-cells');
    if (isNullOrUndefined(this.selectedTarget)) {

      args.cancel = true;
      return;
    }
    if (this.selectedTarget.classList.contains('e-block-appointment')) {
      var idZone: string = args.event.target.dataset.id ? args.event.target.dataset.id :
        args.event.target.parentNode.parentNode.dataset.id;
      this.selectedZoneRestreinte = idZone.replace('Appointment_', '');
    }
    if (this.selectedTarget.classList.contains('e-block-appointment') && this.selectedZoneRestreinte != -1) {
      this.menuObj.showItems(['DeleteZone'], true);
      this.menuObj.hideItems(['Add', 'AddNote', 'status', 'fauteils', 'EditRecurrenceEvent', 'Delete'], true);
      return;
    }
    if (this.selectedTarget.classList.contains('e-block-appointment') && this.selectedZoneRestreinte == -1) {
      this.enableDisableScrollbar(true);
      args.cancel = true;
      return;
    }
    else
      if (this.selectedTarget.classList.contains('e-appointment')) {

        this.eventObj = <{ [key: string]: Object }>this.scheduleObj.getEventDetails(this.selectedTarget);
        // this.menuObj.items.forEach(element => {


        //   if (element.id === "fauteils") {
        //     element.items.find(x => x.id === "fRdv").text = "Fauteuil du RDV :"

        //     this.fauteuilActuel = element.items.find(x => x.id === "fRdv").text.concat(this.fauteuilDataSource.find(z => z.Id ===
        //       this.eventObj.FauteuilId).Text);
        //     this.menuObj.items.find(x => x.id === "fauteils").items.find(x => x.id === "fRdv").text = this.fauteuilActuel;

        //   }
        // });
        this.menuObj.showItems(['EditRecurrenceEvent', 'Delete'], true);
        this.menuObj.hideItems(['Add', 'AddNote', 'DeleteZone', 'status', 'fauteils'], true);
        return;
      } 
      // else if (this.selectedTarget.classList.contains('e-header-cells')) {
      //   this.menuObj.showItems(['DeleteAffectation'], true);
      //   this.menuObj.hideItems(['DeleteZone', 'Add', 'status', 'fauteils', 'EditRecurrenceEvent', 'Delete','AddNote'], true);


      // } 
      else if (this.selectedTarget.classList.contains('e-all-day-cells')) {
        this.menuObj.showItems(['AddNote'], true);
        this.menuObj.hideItems(['status', 'DeleteZone', 'Add', 'fauteils', 'EditRecurrenceEvent', 'Delete'], true);

      }
      // else if (this.selectedTarget.classList.contains('e-current-day')) {
      //   this.menuObj.showItems(['DeleteAffectation'], true);
      //   this.menuObj.hideItems(['status', 'DeleteZone', 'Add', 'fauteils', 'EditRecurrenceEvent', 'Delete, AddNote'], true);

      // }
      else if (this.selectedTarget.classList.contains('e-work-cells')) {
        if (!this.desktop) {
          this.menuObj.showItems(['AddNote'], true);
          this.menuObj.hideItems(['Add'], true);
        } else
          this.menuObj.showItems(['Add', 'AddNote'], true);
        this.menuObj.hideItems(['status', 'DeleteZone', 'fauteils', 'EditRecurrenceEvent', 'Delete'], true);
        return;
      }
      else {
        this.menuObj.showItems(['DeleteZone'], true);
      }

  }
  isNote = false;
  isZone = false;
  isStatus = false;
  selectFauteuil(args: any) {
    this.eventObj = this.dataPatient;
    if (!this.eventObj) return;
    if (!this.eventObj.Id) return;
    this.eventObj["rdvStatut"] = +StatusRdv.Arrive;
    this.eventObj["localisation"] = Localisation.Aucune;
    this.eventObj["heureFauteuil"] = formatDate(new Date(), "yyyy-MM-dd HH:mm:ss", 'en-US');
    this.eventObj["fautUtilise"] = parseInt(args.item.id);
    this.updateHeureFauteilsAppointement(this.eventObj);
  }
  show() {
    // this.scheduleObj.refreshEvents();
    window.location.assign('/appointments');
    // this.scheduleObj.refreshEvents();
    
    // setTimeout(() => {
    //   (document.querySelector('.e-schedule') as any).ej2_instances[0].hideSpinner();
    // }, 5000);
    // this.ionViewWillEnter();
  }

  onClinqueItemSelect(args: any){

    // var dateCabinet = element('date').val();
    var selectedMenuItem: string = "";
    if (args.item) {
      if (args.item.parentObj.id === "fauteils")
        selectedMenuItem = args.item.parentObj.id;
      else
        selectedMenuItem = args.item.id;
    } else selectedMenuItem = args;
    let eventObj: { [key: string]: Object };
    if (this.selectedTarget) {
      if (this.selectedTarget.classList.contains('e-appointment') || this.selectedTarget.classList.contains('e-block-appointment')) {
        eventObj = <{ [key: string]: Object }>this.scheduleObj.getEventDetails(this.selectedTarget);
      }
    }
    let dialog = new Dialog();
    function onOverlayClick() {
      dialog.content="";
      dialog.hide();
    }

    if (this.dateValue== undefined){
       dialog = new Dialog({
        // content: 'Veuillez choisir une date valide ',
        // Enables modal dialog
        isModal:true,
        // overlayClick event handler
        overlayClick: onOverlayClick,
        // Dialog content
        content: 'Veuillez choisir une date valide ',
        // The Dialog shows within the target element
        target: document.getElementById("container"),
        // Dialog width
        width: '250px'
    }
    );
    // Render initialized Dialog
    dialog.appendTo('#dialog');


    // Sample level code to hide the Dialog when click the Dialog overlay


      // console.log("date undefined",this.dateValue)

    }

    else{
      // var month = this.dateValue.getMonth() +1;
      // var monthh =month.toString();
      // var year = this.dateValue.getFullYear().toString();
      // var jour = this.dateValue.getDate();
      // var jourr = this.dateValue.getDate().toString();
      var month =this.selectedDate.getMonth() +1;
      var year =this.selectedDate.getFullYear();
      var jour =this.selectedDate.getDate().toString();
      var jour =this.selectedDate.getDate().toString();
      var jour2 =this.selectedDate.getDate() -1 ;
      var jourr = jour2.toString();
      var monthh=month.toString();

      if (monthh.length === 1){
         monthh = 0 + monthh;
      }
      if (jour.length === 1){
        jour =0 + jour;
     }
     if (jourr.length === 1){
      jourr =0 + jourr;
   }
      var dateTest = (year+"-"+monthh+"-"+jour+" 00:00:00");
      var dateTest2 = (year+"-"+monthh+"-"+jour+" 01:00:00");
      // console.log("hhh",dateTest);

       let StatutCabinets: StatutCabinet = new StatutCabinet();
        // return this.userService.AddContact(contacts);
        console.log('dateTest',dateTest)
        var StatusId = this.getIdByDate(dateTest,dateTest2)
        console.log('StatusId',StatusId)
        StatutCabinets.id= this.ReturnStatusId(StatusId);
    switch (selectedMenuItem) {
      case 'CabinetOuvert':
        StatutCabinets.libelle = 'Cabinet Ouvert';
        StatutCabinets.date = dateTest;

        StatutCabinets.statut =2;

        this. SetstatutCabinet(StatutCabinets);
        // console.log("Cabinet Ouvert",StatutCabinets)
        // this.scheduleObj.refreshEvents();
        break;
      case 'CabinetFerme':
        StatutCabinets.libelle = 'Cabinet Fermé';
        StatutCabinets.date = dateTest;
        StatutCabinets.statut =0;
        this. SetstatutCabinet(StatutCabinets);
        // console.log("Cabinet Fermé",StatutCabinets)
      break;
      case 'PraticienAbsent':
        StatutCabinets.libelle = 'Praticien absent';
        StatutCabinets.date = dateTest;

        StatutCabinets.statut =1;

        this.SetstatutCabinet(StatutCabinets);
        // console.log("Praticien Absent",StatutCabinets)
      break;
      case 'SupprimerStatus':
          console.log("Supprimer",StatutCabinets)
          if (StatusId === 0){
            console.log("NO")
          }
          else{
            return this.appointementService.DeleteStatutCabinet(StatusId).subscribe(
              async result => {
                // await this.getStatusCabinet();                // this.calendarStatus = this.getStatusCabinet(this.calendarArgs);
                // this.getStatusCabinet(this.calendarArgs);
                // this.calendarStatus = this.StatutCabinet;
                this.getStatusCabinet(this.calendarArgs);
                // this.scheduleObj.refreshEvents();
               });;
            // console.log("YES")
          }
      break;
      }

      // if (StatusId === 0){
      //   StatutCabinets.id=this.lastId+1;
      //   return this.appointementService.setStatutCabinet(StatutCabinets).subscribe(
      //     async result => {
      //       this.calendarStatus = this.getStatusCabinet();
      //      });;
      // }
      // else{
      //   StatutCabinets.id=StatusId;
      //   return this.appointementService.setStatutCabinet(StatutCabinets).subscribe(
      //     async result => {
      //       this.calendarStatus = this.getStatusCabinet();
      //      });;
      // }
      // console.log(this.getIdByDate(dateTest))


    }



  }

  ReturnStatusId(StatusId){
    if (StatusId === 0){
        return this.lastId+1;
      }
      else{
        return StatusId;
      }
  }

  SetstatutCabinet(StatutCabinets){
    console.log(StatutCabinets)
    return this.appointementService.setStatutCabinet(StatutCabinets).subscribe(
          async result => {
            console.log("result",result)
            console.log("this.StatutCabinet",this.StatutCabinet)
            // this.getStatusCabinet(this.calendarArgs);
            // this.calendarStatus = this.StatutCabinet;
            this.calendarArgs.renderDayCell();

           });;
  }



  getIdByDate(date,date2){
    var id=0;
    for (var i = 2300; i < this.StatutCabinet.length; i++) {
      var cal = this.StatutCabinet[i].date;
      console.log('cal',cal);
      if (date === cal || date2 === cal){
        console.log(this.StatutCabinet[i])
         id = this.StatutCabinet[i].id;
         console.log('yes')
      }
    }
    console.log("date",date);
    console.log("date2",date2);
    console.log("id",id);
    return id;
  }


  cabinemodal(){

  }

  onMenuItemSelect(args: any) {

    var selectedMenuItem: string = "";
    if (args.item) {
      if (args.item.parentObj.id === "fauteils")
        selectedMenuItem = args.item.parentObj.id;
      else
        selectedMenuItem = args.item.id;
    } else selectedMenuItem = args;
    let eventObj: { [key: string]: Object };
    if (this.selectedTarget) {
      if (this.selectedTarget.classList.contains('e-appointment') || this.selectedTarget.classList.contains('e-block-appointment')) {
        eventObj = <{ [key: string]: Object }>this.scheduleObj.getEventDetails(this.selectedTarget);
      }
    }
    switch (selectedMenuItem) {
      case 'EditRecurrenceEvent':
        this.scheduleObj.openEditor(this.eventObj, 'EditFollowingEvents');
        break;
      case 'Delete':
        this.scheduleObj.activeEventData.event = eventObj;
        this.scheduleObj.quickPopup.openDeleteAlert();
        break;
      case 'Add':
        if (this.isDesktop) {
          const selectedCells: Element[] = this.scheduleObj.getSelectedElements();
          const activeCellsData: CellClickEventArgs =
            this.scheduleObj.getCellDetails(selectedCells.length > 0 ? selectedCells : this.selectedTarget);
          this.onAddClick(activeCellsData);
        }

        break;
      case 'absent':
        this.eventObj = this.dataPatient;
        if (!this.eventObj) return;
        if (!this.eventObj.Id) return;
        this.isStatus = true;
        this.eventObj["rdvStatut"] = +StatusRdv.Absent;
        this.eventObj["localisation"] = Localisation.Fauteuil;
        this.updaterdvStatut(this.eventObj);

        this.data.find(d => d.Id == this.eventObj.Id).rdvStatut = StatusRdv.Absent;
        this.eventSettings = {
          dataSource: this.data
        };
        this.scheduleObj.refreshEvents();

        break;
      case 'attendu':
        this.eventObj = this.dataPatient;
        if (!this.eventObj) return;
        if (!this.eventObj.Id) return;
        this.isStatus = true;
        this.eventObj["rdvStatut"] = +StatusRdv.Attendu;
        this.eventObj["localisation"] = Localisation.Aucune;
        this.updaterdvStatut(this.eventObj);
        this.data.find(d => d.Id == this.eventObj.Id).rdvStatut = StatusRdv.Attendu;
        this.eventSettings = {
          dataSource: this.data
        };
        this.scheduleObj.refreshEvents();

        break;
      case 'sortie':
        this.eventObj = this.dataPatient;
        if (!this.eventObj) return;
        if (!this.eventObj.Id) return;
        this.isStatus = true;
        this.eventObj["rdvStatut"] = +StatusRdv.Arrive;
        this.eventObj["localisation"] = Localisation.Sortie;
        this.eventObj["heureSorti"] = formatDate(new Date(), "yyyy-MM-dd HH:mm:ss", 'en-US');

        this.updaterdvStatut(this.eventObj);
        this.data.find(d => d.Id == this.eventObj.Id).rdvStatut = StatusRdv.Arrive;
        this.eventSettings = {
          dataSource: this.data
        };
        this.scheduleObj.refreshEvents();
        break;
      case 'arrive':
        this.eventObj = this.dataPatient;
        if (!this.eventObj) return;
        if (!this.eventObj.Id) return;
        this.isStatus = true;
        this.eventObj["rdvStatut"] = +StatusRdv.Arrive;
        this.eventObj["localisation"] = Localisation.EnSalle;
        this.eventObj["rdvArrivee"] = formatDate(new Date(), "yyyy-MM-dd HH:mm:ss", 'en-US');
        this.updaterdvStatut(this.eventObj);

        this.data.find(d => d.Id == this.eventObj.Id).rdvStatut = StatusRdv.Arrive;
        this.eventSettings = {
          dataSource: this.data
        };
        this.scheduleObj.refreshEvents();
        break;
      case 'accueil':
        this.eventObj = this.dataPatient;
        if (!this.eventObj) return;
        if (!this.eventObj.Id) return;
        this.isStatus = true;
        this.eventObj["rdvStatut"] = +StatusRdv.Arrive;
        this.eventObj["localisation"] = Localisation.Secretariat;
        this.eventObj["heureSecretariat"] = formatDate(new Date(), "yyyy-MM-dd HH:mm:ss", 'en-US');
        this.updaterdvStatut(this.eventObj);
        this.data.find(d => d.Id == this.eventObj.Id).rdvStatut = StatusRdv.Arrive;
        this.eventSettings = {
          dataSource: this.data
        };
        this.scheduleObj.refreshEvents();
        break;
      case 'AddNote':
        this.isNote = true;
        let dateDeb = new Date();
        dateDeb.setDate(this.scheduleObj.selectedDate.getDate());
        dateDeb.setHours(8);
        dateDeb.setMinutes(0);
        let dateFin = new Date();
        dateFin.setDate(this.scheduleObj.selectedDate.getDate());
        dateFin.setHours(20);
        dateFin.setMinutes(0);

        dateDeb.setDate(this.scheduleObj.selectedDate.getDate());
        dateFin.setDate(this.scheduleObj.selectedDate.getDate());
        this.startTimeNote = formatDate(dateDeb, "yyyy-MM-dd HH:mm:ss", 'en-US');
        this.endTimeNote = formatDate(dateFin, "yyyy-MM-dd HH:mm:ss", 'en-US');
        let cellData: Object = {
          startTime: this.startTimeNote,
          endTime: this.endTimeNote,
          IsAllDay: true,
          IsBlock: true,

        };
        this.scheduleObj.openEditor(cellData, 'Add');
        break;
        case 'DeleteAffectation':
        this.openDeleteAffectationFauteuil();
          break;
      case 'DeleteZone':
        this.deleteZoneRestreinte();
        var objToDelete = this.data.find(d => d.Id == this.selectedZoneRestreinte);
        var index = this.data.indexOf(objToDelete);
        this.data.splice(index, 1)
        this.eventSettings = {
          dataSource: this.data
        };

    }
  }
  openTimerStart() {
    let tmpDateDebut: Date = new Date();
    tmpDateDebut.setHours(8);
    tmpDateDebut.setMinutes(0);
    let tmpDateFin: Date = new Date();
    tmpDateFin.setHours(20);
    tmpDateFin.setMinutes(0);
    this.StarttimepickerNote.min = tmpDateDebut;
    this.StarttimepickerNote.max = tmpDateFin;

  }
  openTimerEnd() {
    let tmpDateDebut: Date = new Date();
    tmpDateDebut.setHours(7);
    tmpDateDebut.setMinutes(0);
    let tmpDateFin: Date = new Date();
    tmpDateFin.setHours(22);
    tmpDateFin.setMinutes(0);

    this.EndtimepickerNote.min = tmpDateDebut;
    this.EndtimepickerNote.max = tmpDateFin;
    this.EndtimepickerNote.dataBind();

  }
  deleteZoneRestreinte() {
    this.zoneRestrienteService.removeZoneRestriente(this.selectedZoneRestreinte).subscribe(() => {
      this.scheduleObj.refreshEvents(); 
    });
  }
  acteFromAutoComplete;
  fauteuilTextFromAutoComplete;
  idPersonneFromAutoComplete;
  idActeFromAutoComplete;
  rdvDureeFromAutoComplete;
  fauteilsIdFromAutoComplete;
  idCommCliniqueFromAutoComplete;
  SubjectFromAutoComplete;

  acteColorAutoComplete;
  dateDebut;

  dateFin;

  selectedFauteuil;

  onChangeAutoComplete(event) {
    this.dateFin = undefined;
    if (event.itemData !== null) {
      if (event.value !== null) {
        this.acteFromAutoComplete = this.listAllPatientWithNextCC.find(x => x.nomPatient === event.value).ActeLibelle;
        this.acteColorAutoComplete = this.acteDataSource.find(f => f.id_ACTE === event.itemData.idActe).CategoryColor;
      }
      let duree = event.itemData.acte_durestd;
      this.dateDebut = this.onPopOpenFieldInformation.StartTime;
      this.dateFin = this.onPopOpenFieldInformation.EndTime;
      this.dateFin.setMinutes(this.dateDebut.getMinutes() + +duree)
      this.fauteuilTextFromAutoComplete = this.fauteuilDataSource.find(f => f.Id == this.fauteilsIdFromAutoComplete).Text;

      this.SubjectFromAutoComplete = event.itemData.nomPatient;
      this.fauteils.text = this.fauteuilTextFromAutoComplete;
      this.idPersonneFromAutoComplete = event.itemData.idPersonne;
      this.idActeFromAutoComplete = event.itemData.idActe;
      this.rdvDureeFromAutoComplete = event.itemData.acte_durestd;

      this.idCommCliniqueFromAutoComplete = event.itemData.idComm;

      var element: any = document.getElementById('Acte');
      element.value = event.itemData.ActeLibelle;

      var element: any = document.getElementById('Acte');
      element.value = event.itemData.ActeLibelle;
      this.selectedFauteuil = this.fauteilsIdFromAutoComplete;
      this.fauteils.value = this.fauteilsIdFromAutoComplete;
    }
  }

  InsertNextRDVFromForm() {

    if (this.isZone) return;
    const _dateFin = this.dateFin;
    this.appointementService.getNextIdRdv().subscribe(id => {
      this.nextApp["rdvDate"] = formatDate(this.dateDebut, "yyyy-MM-dd HH:mm:ss", 'en-US');
      this.nextApp["id_rdv"] = id;
      this.nextApp["id_personne"] = +this.idPersonneFromAutoComplete;
      this.nextApp["id_acte"] = +this.idActeFromAutoComplete;
      this.nextApp["rdvDuree"] = +this.rdvDureeFromAutoComplete;
      this.nextApp["id_fauteuil"] = +this.fauteilsIdFromAutoComplete;
      this.nextApp["idCommClinique"] = +this.idCommCliniqueFromAutoComplete;

      this.appointementService.InsertNextRDV(this.nextApp).toPromise();
      this.InsertRDVTraceFromDorm(this.nextApp["id_rdv"])
      this.updateCommCliniqueIdRDVFromForm(this.nextApp["id_rdv"]);

      let eventData: { [key: string]: Object } = {
        Id: id,
        Subject: this.SubjectFromAutoComplete,
        StartTime: this.dateDebut,
        EndTime: _dateFin,
        IsAllDay: this.onPopOpenFieldInformation.IsAllDay,
        // Acte: this.filteredData[0].acteLibelle,
        CategoryColor: this.acteColorAutoComplete,
        FauteuilId: this.fauteilsIdFromAutoComplete,
        FauteilText: this.fauteuilTextFromAutoComplete,
        FauteuilData: this.mapOfFauteuil,
        ActeLibelle: this.acteFromAutoComplete,
        //  ConsultantID: resourceDetails.resourceData.Id
      };

      this.data.push(eventData);
      this.eventSettings = {
        dataSource: this.data
      };
      this.scheduleObj.refreshEvents();
    });
    this.fauteils = null;
  }

  updateCommCliniqueIdRDVFromForm(id_RDV) {
    this.comm["id"] = +this.idCommCliniqueFromAutoComplete;
    this.comm["id_RDV"] = id_RDV;

    this.commCliniqueService.updateCommCliniqueIdRDV(this.comm).toPromise();
  }
  InsertRDVTraceFromDorm(id_rdv) {
    this.traceApp["rdv_date"] = formatDate(this.dateDebut, "yyyy-MM-dd HH:mm:ss", 'en-US');
    this.traceApp["id_rdv"] = id_rdv;
    this.traceApp["trace_date"] = formatDate(Date.now(), "yyyy-MM-dd HH:mm:ss", 'en-US');
    this.traceApp["trace_comment"] = "";
    this.traceApp["id_acte"] = +this.idActeFromAutoComplete;
    this.traceApp["id_personne"] = +this.idPersonneFromAutoComplete;
    this.traceApp["rdvDuree"] = +this.rdvDureeFromAutoComplete;
    this.traceApp["id_fauteuil"] = +this.fauteilsIdFromAutoComplete;

    this.appointementTraceService.InsertRDVTrace(this.traceApp).toPromise();

  }
  public headerText: Object = [{ text: "Rechercher" },
  { text: "Pas de prochain RDV" }, { text: "A avancer NP" }, { text: "Au Secretariat" }
    , { text: "Patients ps venu" }];

  async insertRdvNote(notes) {
    var idNoteRdv;
    this.rdvNoteApp["Subject"] = notes;
    this.rdvNoteApp["EndTime"] = this.endTimeNote;
    this.rdvNoteApp["StartTime"] = this.startTimeNote;
    await this.noteRdvService.insertNoteRdv(this.rdvNoteApp).toPromise().then(result => {
      idNoteRdv = result;

      let eventData: { [key: string]: Object } = {
        Id: +idNoteRdv,
        Subject: notes,
        StartTime: formatDate(this.startTimeNote, 'yyyy-MM-dd HH:mm:ss', 'en-US'),
        EndTime: formatDate(this.endTimeNote, 'yyyy-MM-dd HH:mm:ss', 'en-US'),
        IsAllDay: true,
        FauteuilId: 6,
        // ActeLibelle: null,
        IsBlock: true,
      };
      this.noteRdvDataSource.push(<any>eventData);

      this.data.push(eventData);

      this.eventSettings = {
        dataSource: this.data
      };
      this.renderCell = true;
      this.createNote(eventData);
    });
  }
  updateRdv(app) {

    app.FauteuilId = this.fauteilIdFromDrag;
    app.StartTime = formatDate(app.StartTime, "yyyy-MM-dd HH:mm:ss", 'en-US');
    app.EndTime = formatDate(app.EndTime, "yyyy-MM-dd HH:mm:ss", 'en-US');
    app.rdvComm = app.Commentaire;
    this.appointementService.updateRdv(app).toPromise().then(result => {
    })
  }

  updaterdvStatut(app) {
    app.StartTime = formatDate(app.StartTime, "yyyy-MM-dd HH:mm:ss", 'en-US');
    app.EndTime = formatDate(app.EndTime, "yyyy-MM-dd HH:mm:ss", 'en-US');
    this.appointementService.updateRdvStatus(app).toPromise().then(result => {
      this.getAppointementsNow()
    })
  }
  updateHeureFauteilsAppointement(app) {
    app.StartTime = formatDate(app.StartTime, "yyyy-MM-dd HH:mm:ss", 'en-US');
    app.EndTime = formatDate(app.EndTime, "yyyy-MM-dd HH:mm:ss", 'en-US');
    this.appointementService.updateHeureFauteilAppointement(app).toPromise().then(result => {
    })

  }


  onchangeDateFrom(event, data) {
    if (data || data.idActe === null || data.id_acte === null || this.acteDataSource === undefined || this.dateFin === undefined
    )
      return;
    let acte_dure;
    if (this.rdvDureeFromAutoComplete !== undefined) {
      acte_dure = this.rdvDureeFromAutoComplete;
    } else {
      acte_dure = this.acteDataSource.find(f => f.acte_LIBELLE === data.ActeLibelle).acte_DURESTD;
    }
    var tmpStart = event.value;
    this.nextApp["rdvDate"] = formatDate(tmpStart, "yyyy-MM-dd HH:mm:ss", 'en-US');
    if (!(this.dateFin instanceof Date)) {
      this.dateFin = new Date(this.dateFin);
    }
    this.dateFin.setDate(tmpStart.getDate());
    this.dateFin.setHours(tmpStart.getHours());
    this.dateFin.setMinutes(tmpStart.getMinutes() + acte_dure);
    this.dateFin = formatDate(this.dateFin, "yyyy-MM-dd HH:mm:ss", 'en-US');
    // this.scheduleObj.eventWindow.refreshDateTimePicker();
  }
  public position: ToastPositionModel = { X: "right" };
  private toastFlag: number = 0;
  public onCreate = (): void => {
    var ele: Element = document.getElementsByClassName('e-resource-cells')[2];
    
    this.noteRdvDataSource.forEach(element => {
      if (ele.clientWidth > 0) {
        this.toastObj.width = !this.isDesktop ? ele.clientWidth * 2 : ele.clientWidth;
        this.toastObj.target = "#scheduleObj";
        this.promptDialog.target = "#scheduleObj"
        this.createNote(element);
      }
    });

  }
  public timeOut: Number = 0;
  public showToast = (): void => {
    this.toastObj.show();
  }
  private template: string = '<div class="e-toast-template"> '
    + '<div class="e-toast-content" id="${Id}"  ><p>${Subject} </p></div> <div class="e-toast-custom-close-icon e-icons"></div></div>';

  public cardTemplateFn(data: Object): NodeList {
    return compile(this.template.trim(), data)(data) as NodeList;
  }

  public deleteNote() {
    // this.confirmDialog.element.style.transform = 'translateY(35vh) translateX(40vw)'
    this.confirmDialog.element.style.top = '166.8px';
    this.confirmDialog.element.style.left = this.isDesktop ? '325.5px' : '55px';
    this.confirmDialog.show();
    this.confirmDialog.dataBind();
  }
  idRDVNote;
  public onOpenToast(event): void {
    var that = this;
    event.element.childNodes[0].childNodes[3].addEventListener("click", function () {
      that.idRDVNote = event.element.childNodes[0].childNodes[1].id;
      that.deleteNote();
    });
    event.element.childNodes[0].childNodes[1].addEventListener("click", function () {
      var idRDVNotetoUpdate = event.element.childNodes[0].childNodes[1].id;
      that.editToast(+idRDVNotetoUpdate);
    });
  }
  public confirmHeader: string = 'Supprimer note';
  public confirmHeadernote: string = 'Modifier Note';
  public showCloseIcon: Boolean = false;
  public visible: Boolean = true;
  public hidden: Boolean = false;
  public confirmCloseIcon: Boolean = true;
  public target: string = '.control-section';
  public confirmWidth: string = '200px';
  public confirmWidthNote: string = '500px';
  public noteHeight: string = '400px'
  public animationSettings: Object = { effect: 'None' };
  public hide: false;
  //public dialogResize: Boolean = true;
  public dialogdragging: Boolean = true;
  public positionDlg: PositionDataModel = { X: 'center', Y: 'top' };

  public confirmDlgBtnClick = (): void => {
    document.getElementById(this.idRDVNote).parentElement.parentElement.hidden = true;
    this.noteRdvService.removeRDVNote(this.idRDVNote).toPromise();
    var objToDelete = this.noteRdvDataSource.find(d => d.Id == this.idRDVNote);
    var index = this.noteRdvDataSource.indexOf(objToDelete);
    this.noteRdvDataSource.splice(index, 1);
    this.confirmDialog.hide();
  }
  public confirmDlgNoteBtnClick = (): void => {
    this.enableDisableScrollbar(true)
    this.ev.StartTime = this.noteStartTime;
    this.ev.EndTime = this.noteEndTime;
    this.ev.Subject = this.noteSubject;
    this.noteRdvService.updateRdvNote(this.ev).toPromise().then(result => {
      (<HTMLInputElement>document.getElementById(this.ev.Id)).innerText = this.ev.Subject;
    })

    this.data.push(this.ev);
    this.eventSettings = {
      dataSource: this.data
    };
    this.scheduleObj.refreshEvents();
    this.promptDialog.hide();
  }

  public confirmDlgBtnClickNoteExit = (): void => {
    this.enableDisableScrollbar(true)
    this.promptDialog.hide();
  }

  public confirmDlgBtnClickExit = (): void => {
    this.confirmDialog.hide();
  }
  public confirmDlgButtons: ButtonPropsModel[] = [{ click: this.confirmDlgBtnClick.bind(this), buttonModel: { content: 'Yes', isPrimary: true } }, { click: this.confirmDlgBtnClickExit.bind(this), buttonModel: { content: 'No' } }];
  public confirmDlgNoteButtons: ButtonPropsModel[] = [{ click: this.confirmDlgNoteBtnClick.bind(this), buttonModel: { content: 'Modfier', isPrimary: true } }, { click: this.confirmDlgBtnClickNoteExit.bind(this), buttonModel: { content: 'Annuler' } }];
  noteSubject;
  noteStartTime;
  noteEndTime;
  ev;
  public editToast(id): void {
    if (id === undefined)
      return;
    this.ev = this.noteRdvDataSource.find(n => n.Id === +id);
    this.noteSubject = this.ev.Subject.toString();
    this.noteStartTime = this.ev.StartTime;
    this.noteEndTime = this.ev.EndTime;
    this.promptDialog.show();
  }
  private createNote(data) {
    var that = this;
    setTimeout(function () {

      that.toastObj.show({ template: this.cardTemplateFn(data)[0].outerHTML });
    }.bind(this), 200);
  }
  dayHeaders = [];
  onRenderCell(args: RenderCellEventArgs) {
    // console.log("onRenderCell",args);
    // console.log("onRenderCell2",args.element);
    if (args.elementType === "dateHeader") {

      this.dayHeaders.push(args.element);
      let tmpDateDebut: Date = new Date();
      let dateDebut = formatDate(this.selectedDate, "yyyy-MM-dd HH:mm:ss", 'en-US');
      let dateDebutDuJour = dateDebut.substring(0, 10) + " 00:00:00";
      let dateFinDuJour = dateDebut.substring(0, 10) + " 23:59:00";
      this.getAffecations(dateDebutDuJour, dateFinDuJour);
      // this.getParticienAffectation(dateDebutDuJour, dateFinDuJour);
    }
    // console.log("this.dayHeadersthis",this.dayHeaders)
  }
  public dialogOpen = (): void => {
    (document.querySelectorAll('.dlgbtn')[0] as HTMLElement).classList.add('e-btn-hide');
    (document.querySelectorAll('.dlgbtn')[1] as HTMLElement).classList.add('e-btn-hide');
    (document.querySelectorAll('.dlgbtn')[2] as HTMLElement).classList.add('e-btn-hide');
  }


}


function refreshAllTemplate() {
  throw new Error('Function not implemented.');
}
// function refreshLayout(scheduleObj: ScheduleComponent) {
//   throw new Error('Function not implemented.');
// }
