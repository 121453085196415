import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import {  } from 'src/environments/environment';
import { LnkAttributsObjets } from '../model/LnkAttributsObjets.model';
import { Context } from '../shared/services/context.service';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
lnkAttributsObjets: LnkAttributsObjets;
@Injectable({
    providedIn: 'root'
  })
export class LnkAttributsObjetsService {
    constructor(
        private http: HttpClient,
        public platform: Platform,
        private context : Context,) { }

    private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

    deleteByObjetAttribut(idObjet, idAttribut) {
        return this.http.delete(Context.apiUrl +
            'lnkAttributsObjets/deleteByIdObjetAndAttribut/' + idObjet + '&' + idAttribut);
    }

    deleteByObjet(idObjet) {
        return this.http.delete(Context.apiUrl + 'lnkAttributsObjets/deleteByIdObjet/' + idObjet);
    }


    insertInLnkAttributObjet(lnkAttributsObjets) {
        return this.http.post<LnkAttributsObjets>(Context.apiUrl + 'LnkAttributsObjets/insert', lnkAttributsObjets);
    }

}