import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ContactService } from 'src/app/service/contact.service';
import { PayerService } from 'src/app/service/payer.service';
import { UserService } from 'src/app/service/user.service';
@Component({
  selector: 'app-modalajoutcontact',
  templateUrl: './modalajoutcontact.component.html',
  styleUrls: ['./modalajoutcontact.component.scss'],
})
export class ModalajoutcontactComponent implements OnInit {

  communication = [
    {val: 'Courrier', isChecked: true},
    {val: 'E-mail', isChecked: true},
    {val: 'Tel', isChecked: true},
  ];

  
  showMeTel: boolean;
  showMeAdresse: boolean;
  showMeMail: boolean;
  idTelephonique: any;
  phoneIdPays:any;
  sms:any;

  Phonecontact= {
    contactPhoneLibelle:{
      id:0,
      libelle: "",
      typecontact: 0
    },
    contactPhoneIdPays:{
      code: "",
      id_pays: null,
      indicatif_telephonique: "",
      nom: "",
      ordre: null
    },
    contactPhonevalue:'',
  }
  
  Emailcontact= {
    contactEmailLibelle:{
      id: null,
      libelle: "",
      typecontact: null,
    },
    contactEmailvalue:'',
  }

  Adressecontact= {
    contactAdresseLibelle:{
      id: null,
      libelle: "",
      typecontact: null
    },
    adresse:'',
    adresse2:'',
    codePostale:'',
    ville:'',
  }



  contactElement={
    
    adr1: "",
    adr2: "",
    codepostal: "",
    contacttype:-1,
    id: null,
    id_CONTACTLIBELLE:0,
    id_PAYS: 0,
    id_PERSONNE: null,
    libelle: "",
    pays: "",
    pref_ORDER: null,
    sms: 0,
    value: "",
    ville: "",
  }
  
  
  phoneLibelle: any;
  adresseLibelle: any[];
  mailLibelle: any[];
  contact= [];

  constructor(private pays: PayerService,private contactService : ContactService, private userService : UserService, private modalController: ModalController) { }
  @Input() IdPatient: any;


  ngOnInit() {
    this.getAllPays();
    this.getAllContactLibelle();
  }
  toogleTag(val) {
    if (val == 'telephone') {
      this.contactElement.value="";
      this.showMeTel = true;
      this.showMeAdresse = false;
      this.showMeMail =  false;    
    } 
    else if (val == 'e-mail') {
      this.contactElement.value="";
      this.showMeTel = false;
      this.showMeAdresse = false;
      this.showMeMail =  true;   
    } 
    else  {
      this.showMeTel = false;
      this.showMeAdresse = true;
      this.showMeMail =  false;   
    }

}

private getAllPays() {
  this.idTelephonique= []
  this.pays.getAllPays().subscribe(result => {
      this.idTelephonique=result;
      // console.log(this.idTelephonique);

  }, error => console.error(error));
}

private getAllContactLibelle() {
  this.phoneLibelle = [];
  this.adresseLibelle = [];
  this.mailLibelle = [] ;
  this.contactService.getContactLib().subscribe(result => {
    // console.log(result)
    for (let index = 0; index < result.length; index++) {
    var element ={id :result[index].id ,libelle: result[index].libelle ,typecontact: result[index].typecontact}
      if (result[index].typecontact == 1) {
        this.phoneLibelle.push(element)
      }
      else if (result[index].typecontact == 4){
        this.adresseLibelle.push(element)
      }
      else if (result[index].typecontact == 2){
        this.mailLibelle.push(element)
      }
      
      
    }
    // console.log("this.phoneLibelle",this.phoneLibelle)
    // console.log("this.adresseLibelle",this.adresseLibelle)
    // console.log("this.mailLibelle",this.mailLibelle)
  }, error => console.error(error));
}
async closeModal() {
  const onClosedData: string = "Wrapped Up!";
  await this.modalController.dismiss(onClosedData);
}

  async submit(){
  //    console.log(this.Phonecontact)
  // console.log(this.Emailcontact)
  // console.log(this.Adressecontact)
  // console.log(this.contactElement)
  this.contactElement.id_PERSONNE=this.IdPatient;
  if (this.showMeTel) {
      this.contactElement.contacttype=1;
      // let x =this.Phonecontact.contactPhoneLibelle.id
      this.contactElement.id_CONTACTLIBELLE=this.Phonecontact.contactPhoneLibelle.id;
      this.contactElement.id_PAYS=this.Phonecontact.contactPhoneIdPays.id_pays;
      this.contactElement.libelle=this.Phonecontact.contactPhoneLibelle.libelle;
      if (this.sms == true) {
        this.contactElement.sms = 1 ;
      }
      else{
        this.contactElement.sms = 0 ;

      }
  }
  if (this.showMeMail) {
    this.contactElement.contacttype=2;
    this.contactElement.id_CONTACTLIBELLE=this.Emailcontact.contactEmailLibelle.id;
    this.contactElement.libelle=this.Emailcontact.contactEmailLibelle.libelle;

    
  }
  if (this.showMeAdresse) {
    this.contactElement.contacttype=4;
    this.contactElement.id_CONTACTLIBELLE=this.Adressecontact.contactAdresseLibelle.id;
    this.contactElement.libelle=this.Adressecontact.contactAdresseLibelle.libelle;
    this.contactElement.value=this.contactElement.adr1 + " " +this.contactElement.codepostal +" " + this.contactElement.ville;
  }
 
  this.contact.push(this.contactElement);

    this.userService.AddContact(this.contact[0]).subscribe(res => {
      // this.closeModal();
   });
      this.closeModal();

  // console.log(this.Phonecontact)
  // console.log(this.Emailcontact)
  // console.log(this.Adressecontact)
  // console.log(this.contactElement)
  
}

}