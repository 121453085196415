import { Component, OnInit, Output, EventEmitter, ViewChild, } from '@angular/core';
import { UserService } from 'src/app/service/user.service';
import { LoadingController, ModalController, Platform } from '@ionic/angular';
import { Context, InjectedContext } from 'src/app/shared/services/context.service';
import { ListView } from '@syncfusion/ej2-lists';
import { Browser } from '@syncfusion/ej2-base';
import { createSpinner } from '@syncfusion/ej2-popups';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Personne } from 'src/app/model/model.personne';
import { CustomLoadingService } from 'src/app/service/custom-loading.service';

@Component({
  selector: 'app-patients-list',
  templateUrl: './patients-list.component.html',
  styleUrls: ['./patients-list.component.scss'],
})
export class PatientsListComponent implements OnInit {
  results = [];
  selectedUser = {};
  idPatient: number;
  isDesktop: boolean;
  isEmpty: boolean;
  userSelected = {};
  @Output('onIdPersonneChanged') idPersonne = new EventEmitter<any>()
  nom: string = "";
  prenom: string = "";
  email: string = "";
  Tel: string = "";
  ville: string = "";
  cdPostal: string = "";
  adresse: string = "";
  listPresonne: Personne[];
  @ViewChild('listviewInstance', { static: false }) public listviewInstance: ListView;
  public startTime: Date;
  public endTime: Date;
  public liElement: HTMLElement;
  public cssClass: string = 'e-list-template';
  public template: string = '<div class="e-list-wrapper e-list-avatar" (click)="clickNew($event)">' +
    '<span class="e-list-content">${per_nom} ${per_prenom}</span></div>';

  public ddlFields: Object = { text: 'text', value: 'value' };
  public fields: Object = { text: 'per_nom' };
  public searchFields = [
    {
      idField: 'lastName',
      placeholder: 'Nom',
    },
    {
      idField: 'firstName',
      placeholder: 'Prénom',
    },
    {
      idField: 'email',
      placeholder: 'Email',
    },
    {
      idField: 'tel',
      placeholder: 'Tel',
    },
    // {
    //   idField: 'city',
    //   placeholder: 'Ville',
    // },
    // {
    //   idField: 'postalCode',
    //   placeholder: 'Code Postal',
    // },
    // {
    //   idField: 'address',
    //   placeholder: 'Adresse',
    // },
  ];
  currentUser: any;
  praticien: any;

  constructor(
    private userService: UserService,
    public modalCtrl: ModalController,
    private context: Context,
    private navctrl: NavController,
    private router: Router,
    private customLoadingService: CustomLoadingService,

  ) {
    this.isDesktop = this.context.detectPlatformDesktop();
  }
  
  ngOnInit() {
    this.liElement = document.getElementById('ui-list');
    if (this.liElement) {
      if (Browser.isDevice) {
        this.liElement.classList.add('ui-mobile');
      }

      createSpinner({
        target: this.liElement
      });
    }
    var value = localStorage.getItem("currentUser");
        this.currentUser = JSON.parse(value);
    //    console.log(this.currentUser);
        this.praticien = this.currentUser.id;
  }
  ionChange(args) {
    switch (args.target.id) {
      case "lastName":
        this.nom = args.detail.value;
        break;
      case "firstName":
        this.prenom = args.detail.value;
        break;
      case "tel":
        this.Tel = args.detail.value;
        break;
      case "city":
        this.ville = args.detail.value;
        break;
      case "postalCode":
        this.cdPostal = args.detail.value;
        break;
      case "address":
        this.adresse = args.detail.value;
        break;
      case "email":
        this.email = args.detail.value;
        break;
    }
    this.loadData();
    localStorage.setItem('DiagCheck', "1");
    localStorage.setItem("CephaloCheck", "1");
    localStorage.removeItem('IdDiagnostic');
    localStorage.removeItem('IdCephalo');
  }
  async loadData() {
    this.isEmpty = false;
    if (!this.nom && !this.prenom && !this.email && !this.Tel && !this.ville && !this.cdPostal && !this.adresse) {
      this.results = [];
      return;
    }
    await this.userService.getAllPatientsV2(this.nom, this.prenom, this.email,
      this.Tel, this.ville, this.cdPostal, this.adresse,this.praticien).subscribe(res => {
        this.results = res;
        if (this.results.length === 0) {
          this.isEmpty = true;
        }
      })
  }

  getIdPersonne(idPersonne) {
    this.idPersonne.emit(idPersonne);
  }
  onIdPersonneChanged($event) {
    this.idPersonne = $event;
    localStorage.setItem("idPatient", this.idPersonne.toString())
  }
  async loadUserMobile(idPersonne) {
    this.onIdPersonneChanged(idPersonne)
    
    this.customLoadingService.presentLoading().then(async value => {
      InjectedContext.picturePatient = []
      
        this.customLoadingService.dismissLoading();
        this.modalCtrl.dismiss(idPersonne);
        location.reload();
    });
  }


  getUserSelected(idPersonne) {
    this.userService.getUserById(idPersonne)
      .subscribe((userObject) => {
        this.userSelected = userObject["per_nom"] + " " + userObject["per_prenom"];
      });
  }
}
