import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpHeaders, HttpParams } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import { Context } from '../shared/services/context.service';
//model
import { PatientWithNextCC, PatientWithNextCCWeb } from '../model/patientWithNextCC.model';
import { RhAppointement } from '../model/rhAppointement.model';
import { Appointement } from '../model/appointement.model';
import { AppointementData } from '../model/appointementData.model';
import { CSParAn } from '../model/CSParAn.model';
import { StatutCabinet } from '../model/StatutCabinet.model';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
    providedIn: 'root'
  })
export class AppointementService {
    gabarit: any;
    constructor(
        private http: HttpClient,
        private context: Context,
        public platform: Platform) { }

    private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

    getAppointementByDate(date1, date2) {
        return this.http.get<AppointementData[]>(Context.apiUrl + 'AppointementByDateData/' + date1 + '&' + date2);
    } 

    getPatientWithNextCC(nomPatient) {
        var req = this.http.get<PatientWithNextCC[]>(Context.apiUrl + 'PatientWithNextCCWeb/search/' + nomPatient);
        return req;
    }

    getPatientWithNextCCWeb(nomPatient) {
        var req = this.http.get<PatientWithNextCCWeb[]>(Context.apiUrl + 'PatientWithNextCCWebs/search/' + nomPatient);
        return req;
    }

    getAllPatientWithNextCC() {
        var req = this.http.get<PatientWithNextCC[]>(Context.apiUrl + 'PatientAllWithNextCC');
        return req;
    }

    getAllNextRDVs() {
        return this.http.get<RhAppointement[]>(Context.apiUrl + 'GetAllNextRDVs');
    }

    InsertNextRDV(app) {
        return this.http.post(Context.apiUrl + 'Appointement/InsertNextRDV/', app)
    }

    getNextIdRdv() {
        return this.http.get<number>(Context.apiUrl + 'getNextIdRdv');
    }
    removeRDV(id) {
        return this.http.get(Context.apiUrl + 'deleteRDV/' + id);
    }

    updateRdv(obj) {
        return this.http.put(Context.apiUrl + 'appointement/updateRdv/' + obj.Id, obj);
    }

    updateRdvStatus(obj) {
        return this.http.put(Context.apiUrl + 'appointement/updateStatusRdv/' + obj.Id, obj);
    }

    updateHeureFauteilAppointement(obj) {
        return this.http.put(Context.apiUrl + 'appointement/updateHeureFauteilAppointntment/' + obj.Id, obj);
    }

    getCurrentAppointements() {
        return this.http.get<AppointementData[]>(Context.apiUrl + 'ActifAppointment/');
    }

    getEvolutionCSParAn(list: Set<number>, annee: number, month: number) {

        return this.http.get<CSParAn[]>(Context.apiUrl + 'getEvolutionCSParAnWeb/' + [30, 35, 11, 39, 41, 42, 43, 44, 45, 46] + '&' + annee + '&' + month);

    }
    getEvolutionCSParAnYear(annee: number) {
        return this.http.get<CSParAn[]>(Context.apiUrl + 'getEvolutionCSParAnYearWeb/' + [30, 35, 11, 39, 41, 42, 43, 44, 45, 46] + '&' + annee);
    }
    getAppointementsPatient(idPatient) {
        return this.http.get<Appointement[]>(Context.apiUrl + 'getAppointments/' + idPatient)
    }
    getNbRD(annee :number, month :number){
        return this.http.get<CSParAn[]>(Context.apiUrl+'getNbRDvs/'+annee+'&'+month);
    }
    checkout(){

        return this.http.post<any>(Context.apiUrl+'checkoutRdv', null);
    }
    getStatutCabinet(){
        return this.http.get<StatutCabinet[]>(Context.apiUrl+'getStatutCabinet/');
    }

    // setStatutCabinet(statutcabinets){
    //     console.log("service",statutcabinets);
    //     return this.http.post(Context.apiUrl + 'setStatutCabinet' , statutcabinets); 
    // }
    
    setStatutCabinet(statutcabinets) {
        console.log("service",statutcabinets);
        return this.http.post(Context.apiUrl + 'setStatutCabinet', statutcabinets);
    }

    
    DeleteStatutCabinet(id) {
        console.log("id",id);
        return this.http.delete(Context.apiUrl + 'DeleteStatutCabinet/' + id);
    }

}
