import { Component, OnInit, Input, ViewChild, AfterViewInit, SecurityContext } from '@angular/core';
import { NavParams, LoadingController, ModalController, ActionSheetController, AlertController, Platform } from '@ionic/angular';
import { ObjetService } from 'src/app/service/objet.service';
import * as sha1 from 'sha1';
import { GalleryViewerComponent } from '../gallery-viewer/gallery-viewer.component';
import { Context, InjectedContext } from 'src/app/shared/services/context.service';
import { LnkAttributsObjetsService } from 'src/app/service/lnkAttributsObjets.service';
import { AttributService } from 'src/app/service/attribut.Service';
import { user } from 'src/app/model/user';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss'],
})
export class ImageViewerComponent implements OnInit {
  @ViewChild('slides', {static: false}) slides;
  newPictures = new Map();
  thumbnails = new Map();

  selectedFile: File = null;
  selectedImages = [];
  idUser = "";
  id: number;
  newobjet = {
    pkObjet: 0, nom: "", extension: "", id_patient: 0, vignette: "", width: 0,
    height: 0, taille: 0, estidentite: 0, dateCreation: "", echelle: 0, fichier: "", lastModif: "",
    repStockage: "", syncPath: "", dateInsertion: "", auteur: "", idGabarit: 0, id_patient_orthalis: 0
  };
  lnkAttributesObjets = { id_attribut: 0, id_objet: 0, valeur: false, valeur_bool: 0 };

  constructor(
    private navParams: NavParams,
    private objetService: ObjetService,
    private loadingController: LoadingController,
    private modalCtrl : ModalController,
    private lnkAttributObjetService: LnkAttributsObjetsService,
    private attributService : AttributService,
    public actionSheetController: ActionSheetController,
    public alertController: AlertController,
    private platform : Platform,
  ) {
  // this.newPictures = navParams.data.newPictures;
   this.newPictures = InjectedContext.pictures;
   this.thumbnails = InjectedContext.localPictures;
    this.id = navParams.data.idUser;
  }
  loading;

  ngOnInit() {
  if (this.platform.is('cordova')) {
    this.platform.ready().then(() => {
  //    alert(this.file.applicationDirectory);

    })
  }

}
  closeModal() {
    this.newPictures = new Map();
  this.thumbnails = new Map();
    InjectedContext.clearPictures();
    this.modalCtrl.dismiss(); 
  }
  result;
  removedIndexs =[];


  setPhotoInList(picture) {
    if(this.selectedImages.indexOf(picture) === -1)
    {       
      this.selectedImages.push(picture)
      var index = this.newPictures.get(picture);
      this.removedIndexs.push(index);
    }else
    {
      var imageIndexInArray = this.selectedImages.indexOf(picture)
      this.selectedImages.splice(imageIndexInArray,1)
      imageIndexInArray = this.removedIndexs.indexOf(imageIndexInArray);
      this.removedIndexs.splice(imageIndexInArray,1);
      
    }
  }
 async prepareImage(value,gabarit){
    var base64textString;
    var idUt = this.idUser;
    var that = this;
    idUt = localStorage.getItem("idPatient");
    // const index = await this.slides.getActiveIndex();          
     var raw = window.atob(value.toString())

     var n = raw.length;
     var a = new Uint8Array(new ArrayBuffer(n));

     for (var i = 0; i < n; i++) {
       a[i] = raw.charCodeAt(i);
     }

     base64textString = window.btoa(raw);
     const contentType = 'image/jpg';
     const blob = new Blob([a], { type: contentType });
     var form = new FormData();
     form.append("file", blob);
     var destFileName: string = "";
     var destName = sha1(raw);
    var cObjet = that.newobjet;
      cObjet.nom = destName;
      cObjet. extension = "JPG";
      cObjet.id_patient = +idUt;
      cObjet.vignette = base64textString;
      cObjet.width = 0;
      cObjet.height = 0;
      cObjet.taille = 0;
      cObjet.estidentite = 0;
      cObjet.dateCreation = null;
      cObjet.lastModif =  null;
      cObjet.echelle = 1;
      cObjet.fichier = destName + ".JPG"
      cObjet.repStockage =  Context.folder + idUt;
      cObjet.syncPath = "";
      cObjet.dateInsertion = null
      cObjet.auteur= null;
      cObjet.id_patient_orthalis = +idUt;
      cObjet.idGabarit = gabarit === undefined ? 0 : gabarit.id_gabarit_outil;
    
     const result =  Promise.all([
       
       that.objetService.saveImage(destName + ".JPG",Context.folder + idUt, form).toPromise(),
       that.insertObjet(cObjet).toPromise()
     ]);
     result.then(result=>{   
    //  if(gabarit != undefined)
    //   this.affectGabaritToObjet_GabaritFound(gabarit,cObjet)                   
     },
     function(data) {
       alert("catch error" + data)
     
      })
  }
  async uplaodImage() {
    this.idUser = localStorage.getItem("idPatient");
    try {
    this.loading = await this.loadingController.create({
      message: 'Chargement...',
      spinner: 'crescent',
      duration: 1000
        });
              await this.loading.present();
  
     let result;
     result =  Promise.all([
        this.newPictures.forEach((key: any,value: any) => {
        this.prepareImage(value,key)    
  })
]); 
  result.then(result=>{            
    this.hideLoading()   
  });
   
    } catch (e) {
      alert("catch error" + e)
    }
  }
 
  async presentActionSheet(picture) {
    const actionSheet = await this.actionSheetController.create({
      //   header: '',
      buttons: [
    {
          text: 'Ouvrir photo',

          handler: () => {
            this.ouvrirImage(picture);
          }
        }, {
          text: 'Supprimer photo',

          handler: () => {
            this.confirmRemoveImage(picture);
          }
        }]
    });
    await actionSheet.present();
  }
  async confirmRemoveImage(picture) {
    const alert = await this.alertController.create({
      header: 'Supprimer Photo',
      message: 'Souhaitez-vous réellement <strong>supprimer</strong> cette photo ?',
      buttons: [
        {
          text: 'Non',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
          }
        }, {
          text: 'Oui',
          handler: () => {
            this.removeImage(picture);
          }
        }
      ]
    });
    await alert.present();
  }
  async ouvrirImage(picture){
    this.loading = await this.loadingController.create({
      message: 'Chargement...',
      spinner: 'crescent',
        });
        this.loading.present();
    const modal = await this.modalCtrl.create({
      component: GalleryViewerComponent,
      componentProps: {
        'pictures': picture,
        'fromUrl': "false",
      }
    });
    return modal.present().then(calue=>{
      this.loading.dismiss();
    });
  }
  removeImage(picture){
    this.newPictures.delete(picture);
    this.thumbnails.delete(picture);
    //const index =  this.slides.getActiveIndex();
    if(this.newPictures.size == 0)
    this.closeModal();
  }
  private hideLoading() {
    this.loading.dismiss();
    this.closeModal();
  }

  
   insertObjet(objet) {
  return this.objetService.addObjet(objet)
  }
  affectGabaritToObjet_GabaritFound(gabaritOutilsSelected,objetSelected) {
    var that = this;
    if (gabaritOutilsSelected["substitue"] === null || gabaritOutilsSelected["substitue"] === 'F') {
    this.selectAttributByGabaritJson(gabaritOutilsSelected.id_gabarit_outil).forEach(function(elements) {
      elements.forEach(element => {
        that.insertInLnkAttributObjet(element.pk_attribut, element.valeur, objetSelected.pkObjet);

       });

     });
    }
  }
  insertInLnkAttributObjet(pkAttribut, valeur, pkObjet) {
   
    var lnkAttributesObjets = this.lnkAttributesObjets;
    lnkAttributesObjets.id_attribut = pkAttribut;
    lnkAttributesObjets.id_objet = pkObjet;
    lnkAttributesObjets.valeur = valeur;
    this.lnkAttributObjetService.insertInLnkAttributObjet(lnkAttributesObjets).subscribe(result => {
    }, error => console.error(error));
  }
  selectAttributByGabaritJson(idGabaritSelected) {
    return this.attributService.selectAttributByGabaritJson(idGabaritSelected)
  }
}
