import { Component, OnInit, ViewChild } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';


@Component({
  selector: 'app-gallery-viewer',
  templateUrl: './gallery-viewer.component.html',
  styleUrls: ['./gallery-viewer.component.scss'],
})
export class GalleryViewerComponent implements OnInit {
  fromUrl ;
  pictures ;

  constructor(
    private navParam : NavParams,
    private modalCtrl : ModalController,
  ) { 
    this.pictures = navParam.data.pictures;
   
    this.fromUrl = navParam.data.fromUrl;

  }
  
  ngOnInit() {}
  closeModal() {
    this.modalCtrl.dismiss();
  }
}
