import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Context } from '../shared/services/context.service';
import { GabaritOutil } from '../model/model.GabaritOutil';


const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  


  @Injectable({
    providedIn: 'root'
  })
export class GabaritService {
  gabarit: any;
  constructor(
    private http: HttpClient,
    private context : Context,
    ) { 

    }

  private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

   getGabaritNameById(idGabarit) {
    return this.http.get<string>(Context.apiUrl +'NomGabarit/'+ idGabarit)
  }
  getAllGabarit() {
    return this.http.get<GabaritOutil>(Context.apiUrl +'selectallgabarit');
  }
}
