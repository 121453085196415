export class AppointementData {

    public Id: number;
    public perIdPersonne: number;
    public Subject: string;
    public StartTime: Date;
    public EndTime: Date;
    public RecurrenceRule: string;
    public IsBlock: boolean;
    public RoomId: number;
    public CategoryColor: String;
    public Commentaire: String;
    public Acte: string;
    public ActeLibelle: string;
    public id_acte: number;
    public rdvStatut: StatusRdv;
    public isAllDay: Boolean;
    // this field added to update rdv status(absent:4,arrive:1,attendu=0)

}
export enum StatusRdv {
    Attendu = 0,
    Arrive = 1,
    Absent = 4,
}

export enum Localisation {
    Aucune = 0,
    EnSalle = 1,
    Fauteuil = 2,
    Secretariat = 4,
    Sortie = 3
}