import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import { Context } from '../shared/services/context.service';
import { Access } from '../model/access.model';
import { Personne } from '../model/model.personne';

@Injectable({
  providedIn: 'root'
})
export class UserStaffService {
  insertCorrespondant(correspondant: { per_type: string; per_nom: string; per_prenom: string; profession: string; sexe: string; civility: string; adresse: string; adresse2: string; per_telprinc: string; fax: string; per_cpostal: string; per_email: string; per_ville: string; per_datnaiss: string; }) {
    throw new Error('Method not implemented.');
  }
  constructor(
    private http: HttpClient,
    public platform: Platform,
    private context: Context) { }

  deletePersonne(id: number) {
    // console.log(id);
    return this.http.delete(Context.apiUrl + 'utilisateur/delete/' + id);
  }
  deleteuser(id: number) {
    return this.http.delete(Context.apiUrl + 'deleteUser/' + id);
  }

  // deletepers(id: number) {
  //   return this.http.delete(Context.apiUrl + 'deletePers/' + id);
  // }

  // deleteUtilisateur(id: number) {
  //   return this.http.delete(Context.apiUrl + 'deleteUtilisateur/' + id);
  // }

  GetPersonneById(id) {
    return this.http.get<Personne>(Context.apiUrl + 'personne/'+ id)
  }

  InsertUtilisateur(utilisateur) {
    return this.http.post(Context.apiUrl + 'utilisateur/insert/', utilisateur)
  }
  InsertPersonne(personne) {
    return this.http.post(Context.apiUrl + 'personne/insert/', personne)
  }
  updateUtilisateur(utilisateur, id_util: number) {
    return this.http.put(Context.apiUrl + 'utilisateur/update/' + id_util, utilisateur);
  }
  updatePersonne(personne, id_personne: number) {
    return this.http.put(Context.apiUrl + 'personne/updateAllInfoPersonne/' + id_personne, personne);
  }
  getAll() {
    return this.http.get(Context.apiUrl + 'getAllUtilisateur/')
  }
  getAuthorityById(idUtilisateur) {
    return this.http.get<Access[]>(Context.apiUrl + 'Access/getAuthorityById/' + idUtilisateur);
  }
  getAllAccessObject() {
    return this.http.get(Context.apiUrl + 'AllAccessObject/');
  }
  InsertPrivilege(privilege) {
    return this.http.post(Context.apiUrl + 'Access/insert/', privilege)
  }
  updatePrivilege(privilege, id_utilisateur: number) {
    return this.http.put(Context.apiUrl + 'Access/update/' + id_utilisateur, privilege);
  }

  getfauteuilsDefaut(idUtilisateur) {
    return this.http.get(Context.apiUrl + 'fauteuilsDefaut/' + idUtilisateur);
  }
  InsertFauteuil(id,idFauteuils) {
    return this.http.post<any>(Context.apiUrl + 'insert/fauteuilsDefaut/' + id , idFauteuils)
  }
}
