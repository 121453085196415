import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { Context } from '../shared/services/context.service';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};



@Injectable({
    providedIn: 'root'
  })
export class AppointementTraceService {
    constructor(
        private http: HttpClient,
        private context: Context,
    ) {

    }

    private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

    InsertRDVTrace(traceApp) {
        return this.http.post<number>(Context.apiUrl + 'AppointementTrace/InsertRDVTrace', traceApp);
    }
    
} 