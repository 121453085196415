export class user{

    id : number;
    userName: string ;
    password: string ;
    firstName: string;
    lastName: string;
    telephone : string;
    email: string;
    role: string;    
    sexe : string;
    titre : string;
    naissance : Date;
    numDossier : number;
}
export enum Role {
    Patient = 'ROLE_PATIENT',
    User = 'ROLE_USER',
    Admin = 'ROLE_ADMIN',
    Questionnaire = 'ROLE_QUESTIONNAIRE',
    Assistante = 'ROLE_ASSISTANTE'
  }
  export class UserAuthentifie{
        LastPassword:string;
        email: string;
        enabled: boolean
        firstname: string
        id: number
        lastLoginDate: Date
        lastPassword: string
        lastPasswordResetDate: Date
        lastname: string
        password: string
        resetpassw: string
        username: string
        authorities:Authoritie[];
  }
  export class Authoritie{
      id:number;
      name:Role;

  }