import {Component, ViewChild, OnInit, AfterViewInit} from '@angular/core';
import {Platform, ModalController} from '@ionic/angular';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {Context, InjectedContext} from './shared/services/context.service';
import {ActivatedRoute, ActivatedRouteSnapshot, Router} from '@angular/router';
import {AuthService} from './auth/services/auth.service';
import {UserAuthentifie, Role} from './model/user';
import {SidebarComponent, MenuComponent} from '@syncfusion/ej2-angular-navigations';
import {Menu, MenuItemModel, MenuEventArgs} from '@syncfusion/ej2-navigations';
import {enableRipple} from '@syncfusion/ej2-base';
import {UserService} from './service/user.service';
import {CustomLoadingService} from './service/custom-loading.service';
// for load other langugage in scheudler 
import {loadCldr} from '@syncfusion/ej2-base';
import {GabaritService} from './service/gabaritOutil.service';
import {PatientsListComponent} from './components/patients-list/patients-list.component';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import { dirname } from 'path';
import { UserStaffService } from './service/user-staff.service';

declare var require: any;
enableRipple(true);
loadCldr(
    require('cldr-data/supplemental/numberingSystems.json'),
    require('cldr-data/main/fr-CH/ca-gregorian.json'),
    require('cldr-data/main/fr-CH/numbers.json'),
    require('cldr-data/main/fr-CH/timeZoneNames.json'));


@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit, AfterViewInit {
    @ViewChild('menu', {static: true}) public menuObj: MenuComponent;
    @ViewChild('sidebarMenuInstance', {static: false}) public sidebarMenuInstance: SidebarComponent;
    public isDesktop;
    public currentUser: UserAuthentifie;

    public loading;
    public results = [];
    public isEmpty: boolean;

    public width: string = '300px';
    public mediaQuery: string = ('(min-width: 600px)');
    public target: string = '.main-content';
    public dockSize: string = '100px';
    public enableDock: boolean = true;
    public closeOnDocumentClick: boolean = true; // close sidebar when clicking content [closeOnDocumentClick]="closeOnDocumentClick"
    userName: string;
    idPatient: string;
    descriptionheader: string;
    ageonYear: number;
    patient: any;


    if(condition) {

    }

    public menuItems: MenuItemModel[] = [];
    public AccountMenuItem: MenuItemModel[] = [
        {
            text: 'Compte',
            items: [
                // { text: 'Profile' },
                {text: 'Se déconnecter'},
            ]
        }
    ];

    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private context: Context,
        private router: Router,
        private modalCtrl: ModalController,
        private authservice: AuthService,
        private userService: UserService,
        private customLoadingService: CustomLoadingService,
        private gabritService: GabaritService,
        private dataRoute: ActivatedRoute,
        private userStaffService : UserStaffService,
    ) {
        this.isDesktop = this.context.detectPlatformDesktop();
        this.menuItems;

        this.loadMenu();
        this.initContextVars();
    }

    loadMenu() {
        let value = localStorage.getItem('currentUser');
        if (!value) {
            InjectedContext.Fromlogout = true;
        } else {
            InjectedContext.Fromlogout = false;
        }
    }

    check() {
        if (InjectedContext.Fromlogout) {
            return true;
        }
        return false;
    }

    async initializeApp() {
        // if(!this.isDesktop) {
        await this.platform.ready().then(() => {
            this.statusBar.styleLightContent();
            this.splashScreen.hide();
        });
        // }
    }

    async initContextVars() {

        if (this.authservice.isLogged()) {
            const result = Promise.all([
                await this.gabritService.getAllGabarit().toPromise(),
                await this.userService.getSmall('').toPromise(),
            ]);
            result.then(async result => {
                InjectedContext.GabaritOutils = result[0];
                InjectedContext.listPraticien = result[1];
                await this.initializeApp();
            });
        } else if (localStorage.getItem('ACCESS_TOKEN') != null) {
            this.router.navigateByUrl('/home');
        } else {
            this.logout();
        }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            let url = window.location.href.split('/');
            let itemSelected = document.getElementById(url[url.length - 1]);
            if (itemSelected) {
                itemSelected.setAttribute('style', 'background: #ededed!important;pointer-events:none;');
            }
        }, 1000);
    }

    async logout() {
        this.customLoadingService.presentLoading().then(async value => {
            this.authservice.logout().then(async x => {
                this.customLoadingService.dismissLoading();
                await this.initializeApp(); // hide splach
            });
        });
    }

    public currentUserValue() {
        var value = localStorage.getItem('currentUser');
        this.currentUser = JSON.parse(value);
        // console.log(this.currentUser);
        var name = this.currentUser.firstname + ' ' + this.currentUser.lastname;
        return name;
    }

    get isQuestionnaire() {
        var value = localStorage.getItem('currentUser');
        this.currentUser = JSON.parse(value);
        if (this.currentUser) {
            return ((this.currentUser.authorities[0].name) == Role.Questionnaire);
        }
        return false;
    }

    get isAdmin() {
        var value = localStorage.getItem('currentUser');
        if (value && value != 'undefined') {
            this.currentUser = JSON.parse(value);
            if (this.currentUser.authorities.length > 0) {
                return ((this.currentUser.authorities[0].name) == Role.Admin);
            }
            return false;
        } else {
            return false;
        }
    }

    // open new tab
    newTabClick(): void {
        let URL = location.href.replace(location.search, '');
        document.getElementById('newTab').setAttribute('href', URL.split('#')[0] + 'sidebar/sidebar-menu');
    }

    onSearchChange(searchValue: string) {
        return this.userService.searchEntries(searchValue)
            .subscribe(results => {
                this.results = results;
                this.isEmpty = false;
                if (results.length === 0) {
                    this.isEmpty = true;
                }
            });
    }

    async select(args: MenuEventArgs): Promise<void> {
        if (args.item.id == 'photos-list' && !localStorage.getItem('idPatient')) {
            this.search(args);
        }else if(args.item.id == 'fiche-patient' && !localStorage.getItem('idPatient')){
            this.search(args);
        }else if(args.item.id == 'basdiag' && !localStorage.getItem('idPatient')){
            this.search(args);
        }else if(args.item.id == 'choix-bas-diag-mini' && !localStorage.getItem('idPatient')){
            this.search(args);
        }else if(args.item.id == 'choix-bas-diag' && !localStorage.getItem('idPatient')){
            this.search(args);
        }else if(args.item.id == 'cephalometrie' && !localStorage.getItem('idPatient') ){
            // localStorage.setItem("idPatient", '0');
            this.search(args)
        }
        else if (args.item.id == 'search' ) {
            // if (localStorage.getItem('idPatient')) {
            this.search(args);
            
            // this.router.navigateByUrl('/fiche-patient');
            // }
            // else{
            //     this.router.navigate(['fiche-patient']);
            // this.search();

            // }
            
        }

         else if (args.item.items.length == 0) {
            this.changeBackgroundSelectedItem(args.item.id);
            if (this.sidebarMenuInstance.isOpen && !this.isDesktop) {
                this.sidebarMenuInstance.hide();
            } // hide menu for mobile
            this.router.navigate(['/' + args.item.id], {replaceUrl: true});
        }

        
        
    }

    selectAccount(args: MenuEventArgs): void {
        if (args.item.text == 'Se déconnecter') {
            this.logout();
        }
    }

    resizeMenu() {
        this.ngAfterViewInit(); // for select first item after login
        if (this.router.url != '/adult-sheet') {
            this.sidebarMenuInstance.toggle();
        }
    }

    created() {
        let removedItems: string[] = ['Contrôle Image'];
        let itemsForAdmin: string[] = ['Statistiques'];
        // let itemsForQues: string[] = ['Q1CS'];

        // if (!this.isDesktop) this.menuObj.removeItems(removedItems, false);
        // if (!this.isAdmin) this.menuObj.removeItems(itemsForAdmin, false);
        // if (!this.isQuestionnaire) this.menuObj.removeItems(itemsForQues, false);

    }

    onCreated(args: any) {
        //  if( this.isDesktop)
        //  this.resizeMenu();
        //  else {
        this.resizeMenu();
        this.resizeMenu();
        //  }
    }

    Admin(data){
        for (let i = 0; i < data.length; i++) {
            if (data[i].name=='ROLE_ADMIN') {
                return true
            }
        }
    }

    Rh(data){
        for (let i = 0; i < data.length; i++) {
            if (data[i].name=='ROLE_RH') {
                return true
            }
        }
    }

    Praticien(data){
        for (let i = 0; i < data.length; i++) {
            if (data[i].name=='ROLE_PRATICIEN') {
                return true
            }
        }
    }

    ASSISTANTE(data){
        for (let i = 0; i < data.length; i++) {
            if (data[i].name=='ROLE_ASSISTANTES') {
                return true
            }
        }
    }

    Secretaire (data){
        for (let i = 0; i < data.length; i++) {
            if (data[i].name=='ROLE_SECRETAIRES') {
                return true
            }
        }
    }

    USER(data){
        for (let i = 0; i < data.length; i++) {
            if (data[i].name=='ROLE_USER') {
                return true
            }
        }
    }

    Financier(data){
        for (let i = 0; i < data.length; i++) {
            if (data[i].name=='ROLE_FINANCIERS') {
                return true
            }
        }
    }

    // PATIENT(data){
    //     for (let i = 0; i < data.length; i++) {
    //         if (data[i].name=='ROLE_PATIENT') {
    //             return true
    //         }
    //     }
    // }

    // QUESTIONAIRE(data){
    //     for (let i = 0; i < data.length; i++) {
    //         if (data[i].name=='ROLE_QUESTIONAIRE') {
    //             return true
    //         }
    //     }
    // }

    

    async search(args) {
        let cssStyle = 'my-custom-modal-isDesktop-css';
        if (!this.isDesktop) {
            cssStyle = 'my-custom-modal-mobile-css';
        }
        const modal = await this.modalCtrl.create({
            component: PatientsListComponent,
            cssClass: cssStyle,
            componentProps: {}
        });
        modal.onDidDismiss()
            .then((data) => {
                
                let id = data['data'];
                if (id) {
                    this.changeBackgroundSelectedItem("/q1cs");
                }
            });
            if (args.item.id == 'search') {
                this.router.navigate(['home']);
            }
            // else if(args.item.id == 'fiche-patient') {
            //     this.router.navigate(['fiche-patient']);
            // }
            else {
                this.router.navigate(['/' + args.item.id], {replaceUrl: true})
            }
        return modal.present();
    }

    calculAge(date): void {
        //convertir date to string to remove T00
        let olddate = date.toString().split("T")[0];
        // console.log(date.toString().split("T")[0]);
        let newDate = new Date(olddate);
        // console.log("new date :", newDate.getFullYear());
        // calcul age
        if (newDate != undefined) {
          var todayDate = new Date();
          var ageyear = todayDate.getFullYear() - newDate.getFullYear();
          var agemonth = todayDate.getMonth() - newDate.getMonth();
          var ageday = todayDate.getDate() - newDate.getDate();
    
          if (agemonth <= 0) {
            ageyear--;
            agemonth = 12 + agemonth;
          }
          if (todayDate.getDate() < newDate.getDate()) {
            agemonth--;
            ageday = 30 + ageday;
          }
          if (agemonth == 12) {
            ageyear = ageyear + 1;
            agemonth = 0;
          }
          this.ageonYear = ageyear;
          this.creatPatientResume()
          
        }
      }

      creatPatientResume(){

        if (this.patient.per_genre == "F") {
            var sexe = "FEMENIN"
            
        }
        else if (this.patient.per_genre == "M"){
            var sexe = "MASCULAIN"
        }

        if (this.ageonYear < 16) {
            var age = "ENFANT"
            
        }
        else {
            var age = "ADULTE"

        }


        this.descriptionheader = this.patient.per_prenom + " " + this.patient.per_nom +  " " +  " | " + this.ageonYear + " ANS"+ " " + "|" + " " + "recommandé par :" ;
        console.log(this.descriptionheader);
      }

    getpersonneById(id){
        this.userStaffService.GetPersonneById(id).toPromise().then(res => {
            this.patient = res; 
            this.calculAge(this.patient.per_datnaiss)
          
            
        })
    }

    ngOnInit(): void {
        // this.test = this.currentUserValue();
        //location.reload()
        
        var value = localStorage.getItem('currentUser');
        this.idPatient = localStorage.getItem('idPatient');
        this.currentUser = JSON.parse(value);
        this.userName = "DR." + this.currentUser.firstname + " "+this.currentUser.lastname
        // console.log(this.currentUser);
        // console.log(this.idPatient);

        if (this.idPatient != null && this.idPatient != "") {
            this.getpersonneById(this.idPatient);
            // console.log(this.patient);

        }
        else{
            localStorage.setItem("idPatient", "");
        // console.log(this.idPatient);

            
        }
        // if (this.currentUser.authorities[0].name) {
        //     var role = this.currentUser.authorities[0].name;
        // } 
        // else {
        //     role == 'ROLE_USER';
        // }
        var data = this.currentUser.authorities;
        this.menuItems = [{
            text: "DR." + " " + this.currentUser.firstname + " " + this.currentUser.lastname,
            id: 'liste-patients',

        },
        ];
        if (this.Admin(data) || this.Rh(data) || this.ASSISTANTE(data) || this.USER(data) || this.Praticien(data) || this.Secretaire(data) ){
            this.menuItems.push(
                {
                    text: "ACCUEIL",
                    iconCss: 'homme',
                    id: 'home',
                }
            )
        }

        if (this.Admin(data) ||  this.ASSISTANTE(data) ||  this.Praticien(data)) {
            this.menuItems.push(
                {
                    text: 'RECHERCHE PATIENTS',
                    iconCss:'search',
                    id: 'recherche-patient',
                },
                   
            )
        }
        
        

        if (this.Admin(data) || this.Rh(data) || this.ASSISTANTE(data) || this.USER(data) || this.Praticien(data) || this.Secretaire(data) ){
            this.menuItems.push(
                {
                    text: 'BAS NOUVEAU PATIENT',
                    iconCss:'nvpatient',
                    id: 'baspremier',
                    },
                   
            )
        }
        

        if (this.Admin(data) || this.Rh(data) || this.ASSISTANTE(data) || this.USER(data) || this.Praticien(data) || this.Secretaire(data) || this.Financier(data) ){
            this.menuItems.push(
                {
                    text: 'BAS PHOTO',
                    iconCss:'basphoto',
                    id: 'photos-list'
                    },
                   
            )
        }

        if (this.Admin(data) ||  this.ASSISTANTE(data) ||  this.Praticien(data) ||  this.Financier(data) ){
            this.menuItems.push(
                {
                    text: 'BASDIAG',
                    iconCss:'diagadult',
                    id: 'choix-bas-diag',
                },
                   
            )
        
        }
        if (this.Admin(data) ||  this.ASSISTANTE(data) ||  this.Praticien(data) ||  this.Financier(data) ){
            this.menuItems.push(
                {
                    text: 'MINI BASDIAG',
                    iconCss:'diagenfant',
                    id: 'choix-bas-diag-mini',
                },
                   
            )
        
        }
        if (this.Admin(data) ||  this.ASSISTANTE(data) ||  this.Praticien(data)) {
            this.menuItems.push(
                {
                    text: 'CÉPHALOMÉTRIE',
                    iconCss:'cephalo',
                    id: 'cephalometrie',
                },
                   
            )
        }
        if (this.Admin(data) ||  this.ASSISTANTE(data) ||  this.Praticien(data)) {
            this.menuItems.push(
                {
                    text: 'PARAMETRAGE',
                    iconCss:'parametrage',
                    id: 'parametrage',
                },
                   
            )
        }
        if (this.Admin(data) ||  this.ASSISTANTE(data) ||  this.Praticien(data)) {
            this.menuItems.push(
                {
                    text: 'LISTE DES PATIENTS',
                    iconCss:'listepatient',
                    id: 'liste-patients',
                },
                   
            )
        }
        

        




    }

    changeBackgroundSelectedItem(selectedItem) {
        let itemSelected = document.getElementById(selectedItem);
        let allItems = document.getElementById("sidebar-menu").getElementsByTagName("li");
        for (let i = 0; i < allItems.length; i++) {
            allItems[i].setAttribute("style", "background:  #e2e2e2!important;");
        }
        itemSelected.setAttribute('style', 'background: #ededed!important;pointer-events:none;');
    }
}

export function isWindows(): boolean {
    if (!window || !navigator) {
      return false;
    }
  
    const a: string = navigator.userAgent || navigator.vendor || (window as any).opera;
  
    return /windows/i.test(a);
  }