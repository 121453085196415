import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class DateDataService {

  constructor() { }
  async  getDateFormat(date) {
    let tmpDate = new Date(date);
    let DateFormat = `${tmpDate.getDate()}/${tmpDate.getMonth() + 1}/${tmpDate.getFullYear()}`;
    let DateFormat2 = `${tmpDate.getMonth() + 1}/${tmpDate.getDate()}/${tmpDate.getFullYear()}`;
    let DateFormat3 = `${tmpDate.getFullYear()}-${tmpDate.getMonth() + 1}-${tmpDate.getDate()}`;
    return DateFormat3;
  }
  async  getCheckedOrNot(string) {
    if (string == 'T' || string == '1')
      return true;
    return false;
  }
  async getdayNumInWeek(day, month, year) {
    var d = new Date(Date.UTC(year, month, day));
    var dayNum = d.getUTCDay() || 7;
    return dayNum;
  }
  async getWeekNumber(day, month, year) {
    var d = new Date(Date.UTC(year, month, day));
    var dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    return Math.ceil((((d.getTime() - yearStart.getTime()) / 86400000) + 1) / 7)
  }

}
