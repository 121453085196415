
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import { Context } from '../shared/services/context.service';
import { noteRdv } from '../model/noteRdv.model';
import { AppointementData } from '../model/appointementData.model';
import { TplPremierAppointement } from '../model/TplPremierAppointement';
import { TplNbPatientByYear } from '../model/TplNbPatientByYear';



@Injectable({
    providedIn: 'root'
  })
export class noteRdvService {
    constructor(
        private http: HttpClient,
        public platform: Platform,
        private context: Context) {

    }


    getNoteRdv(date1, date2) {
        return this.http.get<[AppointementData]>(Context.apiUrl + 'NoteRdv/' + date1 + '&' + date2)
    }

    insertNoteRdv(app) {
        return this.http.post(Context.apiUrl + 'NoteRdv/InsertNoteRdv/', app)
    }

    removeRDVNote(id) {
        return this.http.delete(Context.apiUrl + 'deleteRDVNote/' + id);
    }

    updateRdvNote(obj) {
        return this.http.put(Context.apiUrl + 'NoteRdv/updateRdvNote/' + obj.Id, obj);
    }
    getNbRDvsYear (year : number){
        return this.http.get<any>(Context.apiUrl+'getNbRDvsYear/'+year); 
    }
    getPremiersRendezVous(annee, liste){
        return this.http.get<TplPremierAppointement[]>(Context.apiUrl+'getPremiersRendezVous/'+annee+'&'+liste)
    }
    getNbPatientsByYear(annee){
        return this.http.get<TplNbPatientByYear[]>(Context.apiUrl+'getNbPatientsByYear/'+annee)
    }
    getNbPatientsByMonth(annee){
        return this.http.get<any[]>(Context.apiUrl+'getNbPatientsByMonth/'+annee)
    }
    getTotalRendezVous(annee){
        return this.http.get<any[]>(Context.apiUrl+'getTotalRendezVous/'+annee)
    }

    


}